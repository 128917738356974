import React from 'react'
import 'react-fancybox/lib/fancybox.css'
import PaintingPriceIcon from '../../UI/Icons/PaintingPriceIcon/PaintingPriceIcon'

interface IProps {
    icon: string,
    title: string,
    visible: boolean,
}

const PriceItem: React.FC<IProps> = (props: IProps) => {
    if (!props.visible) {
        return null
    }

    return (
        <div className="picture-price__item">
            <div className="picture-price__pic">
                <PaintingPriceIcon name={props.icon} />
            </div>
            <div className="picture-price__title" dangerouslySetInnerHTML={{
                __html: props.title
            }}/>
        </div>
    )
}

export default PriceItem
