import React from 'react'
import {useTranslation} from 'react-i18next'
import * as cs from 'classnames'
import {connect} from 'react-redux'
import {landingMenuSwitchVisibility} from '../../../store/actions/overlay'
import Menu from '../Menu/Menu'

export enum MenuType {
    DESKTOP = 'desktop',
    MOBILE = 'mobile'
}

interface IProps {
    type: MenuType,
    isActive: boolean,
    hideMenu: () => void,
    isAuthenticated: boolean,
}

const MenuSidebar: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()

    return (
        <>
            <aside className={cs('sidebar', {'is-visible': props.isActive})}>
                <div className="sidebar__inner">
                    <Menu type={MenuType.MOBILE}/>
                </div>
            </aside>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isActive: state.overlayReducer.landingMenuVisible,
        isAuthenticated: state.authReducer.authToken.isFullyAuthenticated(),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hideMenu: () => dispatch(landingMenuSwitchVisibility(false)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuSidebar)

