import React, {useState} from 'react'
import TokenGraphModal from '../../Modals/TokenGraphModal/TokenGraphModal'
import {IPainting, IToken} from '../../../model/Interfaces'
import Chart from '../Chart/Chart'

interface IProps {
    token: IToken,
    painting?: IPainting,
    withModal: boolean
}

const TokenChart: React.FC<IProps> = (props: IProps) => {
    const {token, painting} = props
    const [modalIsVisible, setModalVisible] = useState<boolean>(false)

    const showGraph = () => {
        setModalVisible(true)
    }

    const closeModalHandler = () => {
        setModalVisible(false)
    }

    const hasPredictionGraph = token !== null && Object.keys(token.predictionGraph).length > 0

    if (!hasPredictionGraph) {
        return null
    }

    return (
        <div className="table__chart">
            {
                props.withModal
                    ?
                        <>
                            <a
                                href="#chart"
                                onClick={showGraph}
                            >
                                <Chart token={token}/>
                            </a>
                            <TokenGraphModal
                                token={token}
                                painting={painting}
                                visible={modalIsVisible}
                                close={closeModalHandler}
                            />
                    </>
                :
                    <Chart token={token}/>
            }
        </div>
    )
}

export default TokenChart
