import PropTypes from 'prop-types'
import queryString, {ParsedQuery} from 'query-string'
import React, {useEffect, useState} from 'react'
import GoogleLogin from 'react-google-login'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import ReferralRegistration from '../../../../components/RegistrationReferral/RegistrationReferral'
import Config from '../../../../config/config'
import {AUTH_PATH, REGISTRATION_PATH} from '../../../../config/routing/paths'
import SecurityLayout from '../../../../hoc/SecurityLayout/SecurityLayout'
import {googleAuth} from '../../../../store/actions/Security/Auth/auth'
import history from '../../../../utils/history'

type IProps = {
    googleAuth: (code: string) => void
}

const RegistrationStart: React.FC<IProps> = (props: IProps) => {
    const googleClientId = Config.googleClientId
    const {t} = useTranslation()

    function googleLoginHandler(response) {
        props.googleAuth(response.code)
    }

    function googleLoginErrorHandler(response) {
        console.log('[Google Auth failed]: ', response)
    }

    return (
        <SecurityLayout>
            <>
                <div className="login-page__body">
                    <h1 className="login-page__title">
                        {t('security:titles.registration')}
                    </h1>
                    <ReferralRegistration/>
                    <div className="login-social login-social--column">
                        <ul className="login-social__list">
                            <li className="login-social__item">
                                <Link
                                    to={REGISTRATION_PATH}
                                    className="login-social__link"
                                >
                                    {t('security:buttons.use_email')}
                                </Link>
                            </li>
                            <li className="login-social__item">
                                <GoogleLogin
                                    clientId={googleClientId}
                                    onSuccess={googleLoginHandler}
                                    onFailure={googleLoginErrorHandler}
                                    responseType='code'
                                    render={renderProps => (
                                        <a
                                            href="#continue_with_google"
                                            onClick={renderProps.onClick}
                                            className="login-social__link login-social__link--google"
                                        >
                                            {t('security:buttons.continue_with_google')}
                                        </a>
                                    )}
                                />
                            </li>
                            {/*<li className="login-social__item">*/}
                            {/*    <a*/}
                            {/*        className="login-social__link login-social__link--facebook"*/}
                            {/*        href="#"*/}
                            {/*        onClick={ (event: MouseEvent) =>*/}
                            {/*            setFormType(event, FormTypeEnum.FORM_TYPE_FACEBOOK) }*/}
                            {/*    >*/}
                            {/*        {t('security:buttons.continue_with_facebook')}*/}
                            {/*    </a>*/}
                            {/*</li>*/}
                            {/*<li className="login-social__item">*/}
                            {/*    <a*/}
                            {/*        className="login-social__link login-social__link--twitter"*/}
                            {/*        href="#"*/}
                            {/*        onClick={ (event: MouseEvent) =>*/}
                            {/*            setFormType(event, FormTypeEnum.FORM_TYPE_TWITTER) }*/}
                            {/*    >*/}
                            {/*        {t('security:buttons.continue_with_twitter')}*/}
                            {/*    </a>*/}
                            {/*</li>*/}
                        </ul>
                    </div>
                </div>
                <div className="login-page__bottom login-page__bottom--mt-30">
                    <div className="login-info">
                        <span>
                            {t('security:texts.have_account')}
                        </span> <Link
                            className="login-info__link"
                            to={AUTH_PATH}
                        >
                            {t('security:buttons.enter_it')}
                        </Link>
                    </div>
                </div>
            </>
        </SecurityLayout>
    )
}


function mapDispatchToProps(dispatch) {
    return {
        googleAuth: (code: string) => dispatch(googleAuth(code)),
    }
}

RegistrationStart.propTypes = {
    googleAuth: PropTypes.func.isRequired
}

export default connect(null, mapDispatchToProps)(RegistrationStart)
