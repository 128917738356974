import {Action} from 'redux'
import {
    REQUEST_ACCOUNT_RECOVERY_FAILED,
    REQUEST_ACCOUNT_RECOVERY_SUCCESS
} from '../../../actions/Security/AccountRecovery/actionTypes'

export type RequestAccountRecoveryState = {
    submitted: boolean,
    error: boolean
}

const initialState = {
    submitted: false,
    error: false
}

const accountRecoveryRequestReducer = (
    state: RequestAccountRecoveryState = initialState,
    action: Action
): RequestAccountRecoveryState => {
    switch (action.type) {
        case REQUEST_ACCOUNT_RECOVERY_SUCCESS:
            return {
                ...state,
                submitted: true,
                error: false
            }
        case REQUEST_ACCOUNT_RECOVERY_FAILED:
            return {
                ...state,
                submitted: true,
                error: true
            }
        default:
            return state
    }
}

export default accountRecoveryRequestReducer
