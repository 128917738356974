import * as cs from 'classnames'
import React, {MutableRefObject, useRef, useState} from 'react'
import {FieldError} from 'react-hook-form'
import PasswordIcon from '../Icons/PasswordIcon/PasswordIcon'
import {Link} from "react-router-dom";
import {RESET_PASSWORD_REQUEST_PATH} from "../../../../../config/routing/paths";
import {useTranslation} from "react-i18next";

interface IProps {
    label: string,
    error: FieldError | undefined,
    blockClasses?: [string],
    onChange?: (data: any) => void,
    onBlur?: (data: any) => void,
    value?: string,
    showRestoreLink?: boolean
}

type Ref = ((instance: HTMLInputElement | null) => void) | MutableRefObject<HTMLInputElement | null> | null

const InputPassword = React.forwardRef<HTMLInputElement, IProps>((
    props: IProps, ref: Ref
) => {
    const {t} = useTranslation()
    const [visible, setVisible] = useState(false)
    const [active, setActive] = useState(false)

    const htmlFor = `password-${Math.random()}`
    const inputCurrentType = visible ? 'text' : 'password'

    const inputRef = useRef<HTMLInputElement>(null)
    const labelRef = useRef<HTMLLabelElement>(null)

    const handleAutoFill = e => e.animationName === 'onAutoFillStart' && setActive(true)

    function labelClickHandler() {
        inputRef.current!.focus()
    }

    function focusInHandler() {
        setActive(true)
    }

    function focusOutHandler(event) {
        const target = event.target as HTMLInputElement
        setActive(!!target.value)
    }

    const placeHolderClasses = cs('form__placeholder', {'is-active': active})
    const blockClasses = cs(props.blockClasses, 'form__group', {'has-error': !!props.error})

    const label: string = props.error?.message || props.label || ''

    const onBlurHandler = event => {
        focusOutHandler(event)
        props.onBlur && props.onBlur(event)
    }
    const onChangeHandler = event => props.onChange && props.onChange(event)

    const showRestoreLink = !!props.showRestoreLink

    return (
        <div className={blockClasses} ref={ref}>
            <div className="form__field-group">
                <input
                    className="form__input"
                    type={inputCurrentType}
                    id={htmlFor}
                    value={props.value}
                    onChange={onChangeHandler}
                    onFocus={focusInHandler}
                    onBlur={onBlurHandler}
                    ref={inputRef}
                    onAnimationStart={handleAutoFill}
                />
                <label
                    htmlFor={htmlFor}
                    className={placeHolderClasses}
                    ref={labelRef}
                    onClick={labelClickHandler}>{label}</label>
                <PasswordIcon
                    onMouseDown={() => setVisible(true)}
                    onMouseUp={() => setVisible(false)}
                    onMouseOut={() => setVisible(false)}
                />
            </div>
            {
                showRestoreLink
                ?
                    <div className="form__under-field">
                        <Link className="form__link form__link--grey" to={RESET_PASSWORD_REQUEST_PATH}>
                            {t('security:buttons.forgot_password')}
                        </Link>
                    </div>
                : null
            }

        </div>
    )
})

export default InputPassword
