import React, {useState} from 'react'
import ModalWindow from '../../../hoc/ModalWindow/ModalWindow'
import tokensImg from '../../../styles/img/token-img-1.png'
import tokensImg_2x from '../../../styles/img/token-img-1@2x.png'
import moment from 'moment'
import {useTranslation} from 'react-i18next'
import {Line} from 'react-chartjs-2'
import * as cs from 'classnames'
import {IPainting, IToken} from '../../../model/Interfaces'

interface IProps {
    token: IToken,
    painting?: IPainting,
    visible: boolean,
    close: () => void,
}

enum graphOptions {
    THIS_YEAR = 'THIS_YEAR',
    NEXT_YEAR = 'NEXT_YEAR',
    NEXT_TWO_YEARS = 'NEXT_TWO_YEARS',
}

const TokenGraphModal: React.FC<IProps> = (props: IProps) => {
    const {visible, close} = props
    const {t, i18n} = useTranslation()
    const [activeOption, setActiveOption] = useState<graphOptions>(graphOptions.THIS_YEAR)

    const currentQ = moment().quarter()
    const currentY = moment().year()
    const labels: string[] = []
    const graphValues: (string | null)[] = []
    const m = moment().quarter(1)
    while (m.quarter() <= currentQ && m.year() === currentY) {
        labels.push(`Q${m.quarter()} '${m.year()}`)
        graphValues.push(props.token.currentPrice.toString())
        m.add(1, 'quarter')
    }

    const predictions = props.token.predictionGraph
    const predictionKeys = Object.keys(props.token.predictionGraph)
    let year = currentY
    if (activeOption === graphOptions.NEXT_YEAR) {
        year = moment().add(1, 'year').year()
    }
    if (activeOption === graphOptions.NEXT_TWO_YEARS) {
        year = moment().add(2, 'year').year()
    }

    let lastValue: string = props.token.currentPrice.toString()
    while (m.year() <= year) {
        const yearQuarterString = `${m.format('YY')}_${m.quarter()}`
        const hasPrediction = predictionKeys.indexOf(yearQuarterString) !== -1
        graphValues.push(hasPrediction ? predictions[yearQuarterString].toString() : lastValue)
        labels.push(`Q${m.quarter()} '${m.year()}`)
        lastValue = hasPrediction ? predictions[yearQuarterString].toString() : lastValue
        m.add(1, 'quarter')
    }

    return (
        <ModalWindow
            visible={visible}
            close={close}
            classes="popup-token"
        >
            <div className="popup__body">
                <div className="popup-token__header">
                    <div className="token-item popup-token__token">
                        <div className="token-item__picture">
                            <img
                                className="token-item__img"
                                src={props.token.icon || tokensImg}
                                srcSet={`${props.token.icon2x ? props.token.icon2x : tokensImg_2x} 2x'`}
                                alt={props.token.name}
                            />
                        </div>
                        <div className="token-item__descr">
                            <div className="token-item__value">
                                {props.painting?.name || ''} <span className="token-item__unit">
                                    {t('common:price_change_graph')}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tabs popup-token__tabs">
                    <div className="row popup-token__row">
                        <div className="col-sm-5 popup-token__col">
                            <div className="popup-token__rates">
                                <div className="popup-token__rates-title">
                                    {t('common:rate_on_date')} <span className="green-color">
                                        {moment().locale(i18n.language).format('DD MMMM')}
                                    </span>
                                </div>
                                <div className="popup-token__rates-price">
                                    1 {props.token.name} = $ {props.token.currentPrice}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-7 popup-token__col">
                            <ul className="tabs-toggle popup-token__tabs-toggle">
                                <li
                                    className={cs(
                                        'tabs-toggle__item',
                                        {'on': activeOption === graphOptions.THIS_YEAR}
                                    )}
                                    onClick={() => setActiveOption(graphOptions.THIS_YEAR)}
                                >
                                    {t('common:current_year')}
                                </li>
                                <li
                                    className={cs(
                                        'tabs-toggle__item',
                                        {'on': activeOption === graphOptions.NEXT_YEAR}
                                    )}
                                    onClick={() => setActiveOption(graphOptions.NEXT_YEAR)}
                                >
                                    {t('common:forecast_1_year')}
                                </li>
                                <li
                                    className={cs(
                                        'tabs-toggle__item',
                                        {'on': activeOption === graphOptions.NEXT_TWO_YEARS}
                                    )}
                                    onClick={() => setActiveOption(graphOptions.NEXT_TWO_YEARS)}
                                >
                                    {t('common:forecast_2_year')}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="tabs-panes popup-token__tabs-panes">
                        <div className="tabs__pane on visible" id="token-tab-1">
                            <div className="popup-token__chart">
                                <div
                                    style={{
                                        position: "relative",
                                    }}
                                >
                                    <Line
                                        width={700}
                                        options={{
                                            layout: {
                                                padding: {
                                                    left: 0,
                                                    right: 2,
                                                    top: 20,
                                                    bottom: 20
                                                }
                                            },
                                            legend: { display: false },
                                            tooltips: { enabled: true },
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            scales: {
                                                yAxes: [{
                                                    gridLines: {
                                                        display: false,
                                                        drawTicks: false,
                                                    },
                                                    ticks: {
                                                        display: false,
                                                        min: 50,
                                                    },
                                                }],
                                                xAxes: [{
                                                    gridLines: {
                                                        display: true,
                                                        drawTicks: true,
                                                    },
                                                    ticks: { display: true }
                                                }]
                                            }
                                        }}
                                        data={{
                                            labels: labels,
                                            datasets: [{
                                                borderCapStyle: 'round',
                                                borderColor: 'rgba(0, 0, 0, 1)',
                                                borderWidth: 1,
                                                lineTension: 0.5,
                                                pointRadius: 3,
                                                data: graphValues
                                            }]
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*<div className="tabs__pane" id="token-tab-2">*/}
                        {/*    <div className="popup-token__chart">*/}
                        {/*        <img*/}
                        {/*            className="popup-token__chart-img"*/}
                        {/*            src="img/popup-token-chart.svg"*/}
                        {/*            alt="alt"*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="tabs__pane" id="token-tab-3">*/}
                        {/*    <div className="popup-token__chart">*/}
                        {/*        <img*/}
                        {/*            className="popup-token__chart-img"*/}
                        {/*            src="img/popup-token-chart.svg"*/}
                        {/*            alt="alt"*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="tabs__pane" id="token-tab-4">*/}
                        {/*    <div className="popup-token__chart">*/}
                        {/*        <img*/}
                        {/*            className="popup-token__chart-img"*/}
                        {/*            src="img/popup-token-chart.svg"*/}
                        {/*            alt="alt"*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </ModalWindow>
    )
}

export default TokenGraphModal
