import React from 'react'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {clearFilter, updateFilter} from '../../../store/actions/gallery'
import {filterSwitchVisibility} from '../../../store/actions/overlay'
import FilterIcon from '../../UI/Icons/FilterIcon/FilterIcon'
import CartIcon from '../../UI/Icons/CartIcon/CartIcon'
import Toggle from '../GalleryFilterElements/Toggle/Toggle'
import Search from '../GalleryFilterElements/Search/Search'
import SingleChoice from '../GalleryFilterElements/SingleChoice/SingleChoice'
import MultipleChoice from '../GalleryFilterElements/MultipleChoice/MultipleChoice'
import * as cs from 'classnames'
import {ErasList, GenresList, TechnicsList, SortList} from '../../../model/Structure'
import {AuthorListType, ChoiceValuesListType, ChoiceValueType, EnumObjectType} from '../../../model/Types'
import {Era, FilterSort, Genre, Technic} from '../../../model/Enums'
import {IFilterState} from '../../../model/Interfaces'


interface IProps {
    authors: AuthorListType,
    authorsLoaded: boolean,
    isActive: boolean,
    toggleFilter: (visible: boolean) => void
    name?: string,
    filterState: IFilterState,
    updateFilter: (filterState: IFilterState | null) => void,
    clearFilter: () => void
}

const GalleryFilterSidebar: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()

    if (!props.authorsLoaded) {
        return null
    }

    const onSearchChanged = (value: string) => props.updateFilter({
        ...props.filterState,
        search_query: value,
    })

    const onBuyWholeChanged = (value: boolean) => props.updateFilter({
        ...props.filterState,
        buy_a_whole: value,
    })

    const onSortChanged = (value: ChoiceValueType) => props.updateFilter({
        ...props.filterState,
        sort: value as FilterSort,
    })

    const authorsChanged = (selected: ChoiceValuesListType) => {
        props.updateFilter({
            ...props.filterState,
            authors: selected
        })
    }

    const genresChanged = (selected: ChoiceValuesListType) => {
        props.updateFilter({
            ...props.filterState,
            genres: selected as Genre[]
        })
    }

    const technicsChanged = (selected: ChoiceValuesListType) => {
        props.updateFilter({
            ...props.filterState,
            technics: selected as Technic[]
        })
    }

    const erasChanged = (selected: ChoiceValuesListType) => {
        props.updateFilter({
            ...props.filterState,
            eras: selected as Era[]
        })
    }

    const sortItems = SortList.map((item: EnumObjectType) => {
        return {
            label: t(item.label),
            value: item.value,
            isChecked: props.filterState.sort === item.value
        }
    })

    return (
        <>
            <div className={cs('filter-sidebar', {'is-visible': props.isActive})}>
                <div className="filter-sidebar__toggle" onClick={() => {props.toggleFilter(!props.isActive)}}>
                    <FilterIcon/>
                </div>
                <div className="filter-sidebar__inner">
                    <form className="filter-form">
                        <Search
                            placeholder={t('pictures:texts.search')}
                            value={props.filterState.search_query}
                            onChange={onSearchChanged}
                        />
                        <Toggle
                            label={t('pictures:texts.buy_a_whole_painting')}
                            id="sidebar-filter-buy-a-whole"
                            isChecked={props.filterState.buy_a_whole}
                            onChange={onBuyWholeChanged}
                            icon={<CartIcon className="checkbox__label-icon" />}
                        />
                        <SingleChoice
                            title={t('pictures:texts.sort_by')}
                            id="sidebar-filter-sort-by"
                            onChange={onSortChanged}
                            items={sortItems}
                        />
                        <MultipleChoice
                            title={t('pictures:buttons.author')}
                            id="sidebar-filter-authors"
                            onChange={authorsChanged}
                            items={Object.keys(props.authors).map((id: string) => {
                                const author = props.authors[id]
                                return {
                                    label: author.name,
                                    value: author.id,
                                    isChecked: props.filterState.authors.includes(author.id)
                                }
                            })}
                        />
                        <MultipleChoice
                            title={t('pictures:buttons.painting_genres')}
                            id="sidebar-filter-genres"
                            onChange={genresChanged}
                            items={GenresList.map((item: EnumObjectType) => {
                                return {
                                    label: t(item.label),
                                    value: item.value,
                                    isChecked: props.filterState.genres.includes(item.value as Genre)
                                }
                            })}
                        />
                        <MultipleChoice
                            title={t('pictures:buttons.painting_techniques')}
                            id="sidebar-filter-painting-techniques"
                            onChange={technicsChanged}
                            items={TechnicsList.map((item: EnumObjectType) => {
                                return {
                                    label: t(item.label),
                                    value: item.value,
                                    isChecked: props.filterState.technics.includes(item.value as Technic)
                                }
                            })}
                        />
                        <MultipleChoice
                            title={t('pictures:buttons.eras_and_styles')}
                            id="sidebar-filter-eras-and-styles"
                            onChange={erasChanged}
                            items={ErasList.map((item: EnumObjectType) => {
                                return {
                                    label: t(item.label),
                                    value: item.value,
                                    isChecked: props.filterState.eras.includes(item.value as Era)
                                }
                            })}
                        />
                    </form>
                </div>
            </div>
            {/*  */}

            {/*<button*/}
            {/*    className="button-link button-link--red filter-form__clear"*/}
            {/*    type="button"*/}
            {/*    onClick={props.clearFilter}*/}
            {/*>*/}
            {/*                    <span>*/}
            {/*                        {t('pictures:buttons.clear')}*/}
            {/*                    </span>*/}
            {/*    <CloseIcon/>*/}
            {/*</button>*/}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isActive: state.overlayReducer.filterVisible,
        filterState: state.galleryReducer.filter,
        authorsLoaded: state.galleryReducer.authorsLoaded,
        authors: state.galleryReducer.authors,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleFilter: (visible: boolean): void => dispatch(filterSwitchVisibility(visible)),
        updateFilter: (filterState: IFilterState): void => dispatch(updateFilter(filterState)),
        clearFilter: (): void => dispatch(clearFilter())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GalleryFilterSidebar)

