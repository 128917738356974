export type AccountNotificationDataObject = {
    phone_news: boolean,
    phone_new_paintings: boolean,
    email_news: boolean,
    email_new_paintings: boolean,
}

class AccountNotification {
    private _phoneNews: boolean
    private _phoneNewPaintings: boolean
    private _emailNews: boolean
    private _emailNewPaintings: boolean

    constructor(data: AccountNotificationDataObject) {
        this._phoneNews = data.phone_news;
        this._phoneNewPaintings = data.phone_new_paintings;
        this._emailNews = data.email_news;
        this._emailNewPaintings = data.email_new_paintings;
    }


    get phoneNews(): boolean {
        return this._phoneNews
    }

    set phoneNews(value: boolean) {
        this._phoneNews = value
    }

    get phoneNewPaintings(): boolean {
        return this._phoneNewPaintings
    }

    set phoneNewPaintings(value: boolean) {
        this._phoneNewPaintings = value
    }

    get emailNews(): boolean {
        return this._emailNews
    }

    set emailNews(value: boolean) {
        this._emailNews = value
    }

    get emailNewPaintings(): boolean {
        return this._emailNewPaintings
    }

    set emailNewPaintings(value: boolean) {
        this._emailNewPaintings = value
    }

    toDataObject = (): AccountNotificationDataObject => {
        return {
            phone_news: this._phoneNews,
            phone_new_paintings: this._phoneNewPaintings,
            email_news: this._emailNews,
            email_new_paintings: this._emailNewPaintings,
        }
    }
}

export default AccountNotification
