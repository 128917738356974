import {
    RESET_PASSWORD_DONE,
    RESET_PASSWORD_FAILED,
    RESET_PASSWORD_SUCCESS,
} from '../../../actions/Security/ResetPassword/actionTypes'

export type ResetPasswordConfirmState = {
    submitted: boolean,
    error: boolean,
    errors: any[],
}

const initialState = {
    submitted: false,
    error: false,
    errors: [],
}

const resetPasswordConfirmReducer = (
    state: ResetPasswordConfirmState = initialState,
    action: {type: string, payload?: any}
): ResetPasswordConfirmState => {
    switch (action.type) {
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                submitted: true,
                error: false,
                errors: [],
            }
        case RESET_PASSWORD_FAILED:
            return {
                ...state,
                submitted: true,
                error: true,
                errors: action.payload || [],
            }
        case RESET_PASSWORD_DONE:
            return {
                ...state,
                submitted: false,
                error: false,
                errors: [],
            }
        default:
            return state
    }
}

export default resetPasswordConfirmReducer
