import React from 'react'

interface IProps {
    image: string,
    image2x?: string,
}

const AboutItem: React.FC<IProps> = (props: IProps) => (
    <picture className="main-image-slider__picture">
        <img
            className="main-image-slider__img"
            src={props.image}
            srcSet={`${props.image2x} 2x`}
            alt="alt"
        />
    </picture>
)

export default AboutItem
