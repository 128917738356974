import React from 'react'
import aboutIcon1 from '../../../styles/img/main/picture-icon-1.svg'
import aboutImg1 from '../../../styles/img/main/picture-1.jpg'
import aboutImg1_2x from '../../../styles/img/main/picture-1@2x.jpg'
import aboutIcon2 from '../../../styles/img/main/picture-icon-2.svg'
import aboutImg2 from '../../../styles/img/main/picture-2.jpg'
import aboutImg2_2x from '../../../styles/img/main/picture-2@2x.jpg'
import aboutIcon3 from '../../../styles/img/main/picture-icon-3.svg'
import aboutImg3 from '../../../styles/img/main/picture-3.jpg'
import aboutImg3_2x from '../../../styles/img/main/picture-3@2x.jpg'
import {useTranslation} from 'react-i18next'

interface Item {
    title: string,
    text: string,
    icon: string,
    img: string,
    img2x: string,
}

const items: Item[] = [{
    title: 'landing:titles.what',
    text: 'landing:texts.what',
    icon: aboutIcon1,
    img: aboutImg1,
    img2x: aboutImg1_2x,
}, {
    title: 'landing:titles.how',
    text: 'landing:texts.how',
    icon: aboutIcon2,
    img: aboutImg2,
    img2x: aboutImg2_2x,
}, {
    title: 'landing:titles.who',
    text: 'landing:texts.who',
    icon: aboutIcon3,
    img: aboutImg3,
    img2x: aboutImg3_2x,
}]

const Capabilities: React.FC = () => {
    const {t} = useTranslation()

    const renderItems = () => items.map((item: Item, i: number) => (
        <div className="col-lg-4 col-md-6 picture-section__col" key={`item-` + i}>
            <div className="main-picture-card">
                <picture className="main-picture-card__picture">
                    <img
                        className="main-picture-card__img"
                        src={item.img}
                        srcSet={item.img2x + ' 2x'}
                        alt={t(item.title)}
                    />
                </picture>
                <div className="main-picture-card__descr">
                    <div className="main-picture-card__header">
                        <h3 className="main-picture-card__title">
                            {t(item.title)}
                        </h3>
                        <img
                            className="main-picture-card__icon"
                            src={item.icon}
                            alt={t(item.title)}
                        />
                    </div>
                    <div className="main-picture-card__text">
                        <p>{t(item.text)}</p>
                    </div>
                </div>
            </div>
        </div>
    ))

    return (
        <section className="picture-section this-anchor" id="capabilities">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="section-title picture-section__title">
                            {t('landing:titles.capabilities')}
                        </h2>
                    </div>
                </div>
                <div className="row">
                    { renderItems() }
                </div>
            </div>
        </section>
    )
}

export default Capabilities
