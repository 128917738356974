import React, {useLayoutEffect} from 'react'
import Capabilities from '../../components/Landing/Capabilities/Capabilities'
import Footer from '../../components/Landing/Footer/Footer'
import HowItWork from '../../components/Landing/HowItWork/HowItWork'
import Join from '../../components/Landing/Join/Join'
import Main from '../../components/Landing/Main/Main'
import Road from '../../components/Landing/Road/Road'
import DarkOverlay from '../../components/Layout/DarkOverlay/DarkOverlay'
import {connect} from 'react-redux'
import About from "../../components/Landing/About/About";
import history from '../../utils/history'
import MainLayout from '../../hoc/MainLayout/MainLayout'
import SupportLink from '../../components/UI/SupportLink/SupportLink'

interface IProps {
    isAuthenticated: boolean,
}

const LandingPage: React.FC<IProps> = (props: IProps) => {

    useLayoutEffect(() => {
        console.log('1')
        const hash = history.location.hash.substring(1)
        const elem = document.getElementById(hash)
        if (!elem) {
            setTimeout(() => {
                window.scrollTo(0, 0)
            }, 10)

            return
        }

        const yOffset = 40
        const y = elem.getBoundingClientRect().top + window.pageYOffset - yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
    }, [])

    return (
        <MainLayout>
            <>
                <Main isAuthenticated={props.isAuthenticated}/>
                <HowItWork/>
                <About/>
                <Road/>
                <Capabilities />
                <Join isAuthenticated={props.isAuthenticated}/>
                <Footer/>
                <DarkOverlay />
            </>
        </MainLayout>
    )
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.authReducer.authToken.isFullyAuthenticated(),
    }
}

export default connect(mapStateToProps)(LandingPage)
