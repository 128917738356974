import queryString, {ParsedQuery} from 'query-string'
import axios from '../../../../axios/axios-backend'
import {API_REGISTER_CONFIRM} from '../../../../axios/paths'
import history from '../../../../utils/history'
import {AUTH_TOKEN_STORAGE_NAME, autoLogin} from '../Auth/auth'
import {REGISTRATION_CONFIRM_FAILED, REGISTRATION_CONFIRM_SUCCESS} from './actionTypes'

type ConfirmEmailResponse = {
    token: string,
}

export const checkConfirmationToken = () => async dispatch => {
    const searchParams: ParsedQuery = queryString.parse(history.location.search)
    const token: string | null = searchParams.token?.toString() || null

    if (!token) {
        dispatch(confirmationFailed())

        return
    }

    try {
        const {data}: {data: ConfirmEmailResponse} = await axios.post(API_REGISTER_CONFIRM, {token})
        localStorage.setItem(AUTH_TOKEN_STORAGE_NAME, JSON.stringify(data.token))
        await dispatch(confirmationSuccess())
        dispatch(autoLogin())
    } catch (e) {
        console.log(e)
        dispatch(confirmationFailed())
    }
}

export const confirmationSuccess = () => {
    return {
        type: REGISTRATION_CONFIRM_SUCCESS
    }
}

export const confirmationFailed = () => {
    return {
        type: REGISTRATION_CONFIRM_FAILED
    }
}
