import React, {useState} from 'react'
import {connect} from 'react-redux'
import Tokens from '../../model/Tokens'
import MainLayout from '../../hoc/MainLayout/MainLayout'
import solanaLogo from '../../styles/img/crypto-icon-solana.png'
import solanaLogo_2x from '../../styles/img/crypto-icon-solana@2x.png'
import confirmedImg from '../../styles/img/confirmed-icon.svg'
import ApiClient from '../../services/ApiClient'
import {API_ACCOUNT_WITHDRAW} from '../../axios/paths'
import WalletType from '../../model/Enums/WalletType'
import {useTranslation} from 'react-i18next'
import Select, {SelectOption} from '../../components/UI/Form/Elements/Select/Select'
import {IToken} from '../../model/Interfaces'
import tokensImg from '../../styles/img/token-img-1.png'
import tokensImg_2x from '../../styles/img/token-img-1@2x.png'
import AccountBalance from '../../model/AccountBalance'
import ArtTokenBalance from '../../model/ArtTokenBalance'

interface IProps {
    balance: AccountBalance | null,
    tokensLoaded: boolean,
    tokens: Tokens | null,
}

const WithdrawalPage: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()
    const [selectedToken, setSelectedToken] = useState<string|null>(null)
    const [countTokens, setCountTokens] = useState<number>(1)
    const [walletAddress, setWalletAddress] = useState<string>('')
    const [isSent, setSent] = useState<boolean>(false)

    const tokens: Tokens | null = props.tokens
    const balance: AccountBalance | null = props.balance
    if (balance === null || !props.tokensLoaded || tokens === null) {
        return null
    }

    const mappedArtTokens = tokens.mappedArtTokens()

    const listTokens:{[key: string]: SelectOption}  = {}

    balance.artTokenBalances.map((artTokenBalance: ArtTokenBalance) => {
        const tokenId = artTokenBalance.id
        const token: IToken = mappedArtTokens[tokenId]
        listTokens[token.id] = {
            name: token.name,
            icon: token.icon || tokensImg,
            icon2x: token.icon2x || tokensImg_2x,
        }

        if (selectedToken === null) {
            setSelectedToken(tokenId)
        }
    })

    if (selectedToken === null) {
        return null
    }

    const tokenBalance: ArtTokenBalance = balance.getArtTokenById(selectedToken)
    const currentToken: IToken = mappedArtTokens[selectedToken]
    const currentTokenPrice: number = currentToken.currentPrice
    const currentTokenName: string = currentToken.name
    const currentTokenAvailable: string = tokenBalance.quantity

    const tokenOnChangeHandler = (id: string) => {
        setCountTokens(1)
        setSelectedToken(id)
    }

    const validateCount = (value: number): number => {
        const maxValue = parseInt(tokenBalance.quantity)

        return maxValue >= value && value > 0 ? value : maxValue
    }

    const decrementCount = () => {
        const value = validateCount(countTokens - 1)
        setCountTokens(value)
    }

    const incrementCount = () => {
        const value = validateCount(countTokens + 1)
        setCountTokens(value)
    }

    const onCountChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = parseFloat((event.target.value).replace(/[^0-9]/, '') || '1')
        if (value < 1) {
            value = 1
        }

        const currentTokenAvailableValue = parseFloat(currentTokenAvailable || '')
        if (value > currentTokenAvailableValue) {
            event.preventDefault()
            return
        }

        setCountTokens(value)
    }

    const addressChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWalletAddress(event.target.value)
    }

    const submitFromHandler = async () => {
        if (!walletAddress) {
            return
        }
        const walletType: string = WalletType.SOLANA
        const apiClient = ApiClient()
        try {
            await apiClient.post(API_ACCOUNT_WITHDRAW, {
                token_id: selectedToken,
                amount: countTokens,
                wallet_address: walletAddress,
                wallet_type: walletType
            })
            setSent(true)
        } catch (e) {
            console.log(e)
            throw e
        }
    }

    if (isSent) {
        return <MainLayout>
            <main className="operations-page">
                <div className="operations-page__inner operations-page__inner--center">
                    <div className="confirmed">
                        <img className="confirmed__icon" src={confirmedImg} alt={t('common:confirmed')}/>
                        <h1 className="confirmed__title">{t('common:withdraw.withdrawal_request_accepted')}</h1>
                        <div className="confirmed__text">
                            {/*<p>Номер заявки — #456897</p>*/}
                            {/*<p>Вы можете отслеживать статус вашей заявки в разделе Операции</p>*/}
                        </div>
                        {/*<a className="button confirmed__button" href="#">Операции</a>*/}
                    </div>
                </div>
            </main>
        </MainLayout>
    }

    return (
        <MainLayout>
            <main className="operations-page">
                <div className="operations-page__inner">
                    <h1 className="operations-page__title">
                        {t('common:titles.tokens_withdrawal')}
                    </h1>
                    <div className="form operations-page__form">
                        <div className="form__thead">
                            <div className="row row--sm-m-10">
                                <div className="col-4 col-sm-4 col--sm-p-10">
                                    <label className="form__label">
                                        {t('common:token')}
                                    </label>
                                </div>
                                <div className="col-4 col-sm-5 col--sm-p-10">
                                    <label className="form__label">
                                        {t('common:quantity')}
                                    </label>
                                </div>
                                <div className="col-4 col-sm-3 col--sm-p-10">
                                    <label className="form__label form__label--right">
                                        {t('common:amount')}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row row--sm-m-10">
                            <div className="col-4 col-sm-4 col--sm-p-10">
                                <div className="form__group form__group--mb-10">
                                    <Select
                                        options={listTokens}
                                        onChange={tokenOnChangeHandler}
                                        selected={selectedToken}
                                        disabled={[]}
                                    />
                                </div>
                            </div>
                            <div className="col-4 col-sm-5 col--sm-p-10">
                                <div className="form__group form__group--mb-10">
                                    <div className="quantity">
                                        <button
                                            className="quantity__minus"
                                            type="button"
                                            onClick={decrementCount}
                                        >-</button>
                                        <input
                                            className="quantity__input"
                                            type="text"
                                            value={countTokens}
                                            onChange={onCountChangeHandler}
                                        />
                                        <button
                                            className="quantity__plus"
                                            type="button"
                                            onClick={incrementCount}
                                        >+</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 col-sm-3 col--sm-p-10">
                                <div className="form__group form__group--mb-10">
                                    <input
                                        className="form__input form__input--right"
                                        type="text"
                                        name="inputTotal"
                                        placeholder=""
                                        value={'$ ' + (countTokens * (currentTokenPrice || 0)).toLocaleString()}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row row--sm-m-10">
                            <div className="col-4 col-sm-4 col--sm-p-10">
                                <div className="form__group">
                                    <ul className="form__list">
                                        <li>
                                            1 {currentTokenName} = $ {(currentTokenPrice || 0).toLocaleString()}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-8 col-sm-5 col--sm-p-10">
                                <div className="form__group">
                                    <ul className="form__list form__list--center">
                                        <li className="green-color">
                                            {t('common:Available')}: {
                                                parseFloat(currentTokenAvailable || '0').toLocaleString()
                                            } {currentTokenName}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="withdrawal-info">
                            <div className="withdrawal-info__header">
                                <img
                                    className="radio-button__img"
                                    src={solanaLogo}
                                    srcSet={`${solanaLogo_2x} 2x`}
                                    alt="alt"
                                />
                                <h2 className="withdrawal-info__title">
                                    {t('common:withdraw.sub_title')}
                                </h2>
                            </div>
                            <div className="withdrawal-info__text">
                                <p>
                                    {t('common:withdraw.description')}
                                </p>
                            </div>
                        </div>
                        <div className="form__group">
                            <label className="form__label">
                                {t('common:withdraw.solana_wallet_address')}
                            </label>
                            <input
                                className="form__input"
                                type="text"
                                name="address"
                                placeholder={t('common:withdraw.enter_wallet_address')}
                                onChange={addressChangeHandler}
                                required
                            />
                        </div>
                        <div className="form__group">
                            <div className="form__info">
                                <svg className="form__info-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                     fill="currentColor">
                                    <path fillRule="evenodd"
                                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                          clipRule="evenodd"/>
                                </svg>
                                <p>
                                    {t('common:withdraw.provide_only_real_address')}
                                </p>
                            </div>
                        </div>
                        <div className="form__buttons form__buttons--center">
                            <button
                                className="button form__button form__button--auto"
                                onClick={submitFromHandler}
                            >
                                {t('common:buttons.withdraw')}
                            </button>
                        </div>
                    </div>
                </div>
            </main>
        </MainLayout>
    )
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.authReducer.authToken.isFullyAuthenticated(),
        balance: state.balanceReducer.balance,
        tokensLoaded: state.tokenReducer.tokensLoaded,
        tokens: state.tokenReducer.tokens,
    }
}

export default connect(mapStateToProps)(WithdrawalPage)
