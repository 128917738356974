import React from 'react'
import {useTranslation} from 'react-i18next'
import {Partner} from '../../../containers/PartnerPage/PartnerPage'

interface IProps {
    partner: Partner,
}

const PartnerItem: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()
    const {partner} = props

    return (
        <div className="partners-block">
            <div className="partners-item">
                <div className="partners-item__inner">
                    {/*<div className="partners-item__button">+</div>*/}
                    <div className="partners-item__left">
                        <div className="partners-item__number">
                            {partner.level}
                        </div>
                        <div className="partners-item__descr">
                            <div className="partners-item__email">
                                {partner.email}
                            </div>
                            <div className="partners-item__status">
                                {t('common:registered')}
                            </div>
                        </div>
                    </div>
                    <div className="partners-item__right">
                        <div className="partners-item__bonus">
                            <div className="partners-item__bonus-val">
                                0
                            </div>
                            <div className="partners-item__bonus-title">
                                {t('common:bonus')} INDX
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PartnerItem
