import React from 'react'
import {useTranslation} from 'react-i18next'
import TwoFactorCodeInput from '../TwoFactorCodeInput/TwoFactorCodeInput'
import * as cs from 'classnames'

type CodeType = (number | null)[]

interface IProps {
    code: CodeType,
    onChange: (code: CodeType) => void,
    error?: boolean,
    errorMessage?: string,
    bindFocus?: boolean,
}

const TwoFactorCode: React.FC<IProps> = (props: IProps) => {
    const {code, onChange} = props
    const {t} = useTranslation()

    const hasError = !!props.error

    const bindFocus: boolean = props.bindFocus !== false

    const changeHandler = (value: string, i: number): void => {
        const newState = code.slice()
        if (value.length === 0) {
            newState[i] = null
            onChange(newState)
            return
        }

        for (let j = 0; j < value.length && i < 6; j++, i++) {
            newState[i] = value[j] !== '' ? parseInt(value[j]) : null
        }

        onChange(newState)
    }

    const stepBackHandler = (i: number) => {
        if (i === 0) {
            return
        }

        const newState = code.slice()
        newState[i - 1] = null
        onChange(newState)
    }

    return (
        <div className="form__group">
            <label className={cs('form__error', {'is-active': hasError})}>
                {
                    props.errorMessage
                    ? t(props.errorMessage)
                    : t('security:errors.wrong_2fa_code')
                }
            </label>
            <div className="form__row">
                {
                    code.map((value: number | null, i: number) => {
                        const isActive = value === null && ((i === 0 && bindFocus) || (i !== 0 && code[i - 1] !== null))

                        return (
                            <TwoFactorCodeInput
                                key={`two-fa-code-${i}`}
                                value={value}
                                isActive={isActive}
                                onChange={(value: string) => {changeHandler(value, i)}}
                                onStepBack={() => {stepBackHandler(i)}}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default TwoFactorCode
