enum Era {
    UNKNOWN = 0,
    RENAISSANCE = 1,
    BAROQUE = 2,
    EXPRESSIONISM = 3,
    ABSTRACT_EXPRESSIONISM = 4,
    ABSTRACT_ART = 5,
    COLOR_FIELD_PAINTING = 6,
}

export default Era
