import {connect} from 'react-redux'
import React, {useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {API_ACCOUNT_SETTINGS} from '../../../axios/paths'
import Select, {SelectOption} from '../../../components/UI/Form/Elements/Select/Select'
import AvatarIcon from '../../../components/UI/Icons/AvatarIcon/AvatarIcon'
import CloseRoundedIcon from '../../../components/UI/Icons/CloseRoundedIcon/CloseRoundedIcon'
import EditIcon from '../../../components/UI/Icons/EditIcon/EditIcon'
import GoogleIcon from '../../../components/UI/Icons/GoogleIcon/GoogleIcon'
import Account from '../../../model/Account'
import * as cs from 'classnames'
import ApiClient from '../../../services/ApiClient'
import {reloadAccountData} from '../../../store/actions/Security/Auth/auth'
import PreLoader from '../../UI/PreLoader/PreLoader'
// import SocialRemoveIcon from '../../components/UI/Icons/SocialRemoveIcon/SocialRemoveIcon'
// import FacebookIcon from '../../components/UI/Icons/FacebookIcon/FacebookIcon'
// import TwitterIcon from '../../components/UI/Icons/TwitterIcon/TwitterIcon'

interface StateProps {
    account: Account | null,
}

interface DispatchProps {
    reloadAccountData: () => void,
}

type IProps = StateProps & DispatchProps

const Profile: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation();
    const fileRef = useRef<HTMLInputElement>(null)

    const [usernameIsActive, setUsernameActive] = useState<boolean>(false)
    const [usernameValue, setUsernameValue] = useState<string>('')
    const [usernameBtnIsActive, setNickButtonActive] = useState<boolean>(true)

    const [phoneIsActive, setPhoneActive] = useState<boolean>(false)
    const [phoneValue, setPhoneValue] = useState<string>('')
    const [newAvatar, setNewAvatar] = useState<string>('')



    if (!props.account) {
        return <PreLoader/>
    }

    const account: Account = props.account

    const saveProfile = async () => {
        const apiClient = ApiClient()
        await apiClient.post(API_ACCOUNT_SETTINGS, {
             username: account.username,
             phone: account.phone,
             language: account.language || 'en',
             avatar: newAvatar ? newAvatar : (account.avatar ? null : '')
        })

        setNewAvatar('')
        props.reloadAccountData()
    }

    if (newAvatar) {
        saveProfile()
    }

    const onUsernameChanged = (event) => {
        const val = event.target.value.trim()
        if (usernameBtnIsActive && !/[a-z]+/is.test(val)) {
            setNickButtonActive(false)
        }
        if (!usernameBtnIsActive && /[a-z]+/is.test(val)) {
            setNickButtonActive(true)
        }
        setUsernameValue(event.target.value.trim())
    }

    const onUsernameEdit = () => {
        if (usernameBtnIsActive
            && !/[a-z]+/is.test(account.username)) {
            setNickButtonActive(false)
        }
        if (!usernameBtnIsActive
            && /[a-z]+/is.test(account.username)) {
            setNickButtonActive(true)
        }
        setUsernameActive(true)
        setUsernameValue(account.username)
    }

    const onUsernameCancel = (event) => {
        event.preventDefault()
        setUsernameActive(false)
    }

    const onUsernameSave = () => {
        setUsernameActive(false)
        account.username = usernameValue
        saveProfile()
    }

    const phoneIsEmpty = !/[0-9]+/.test(account.phone)
    const onPhoneChanged = (event) => {
        const val = event.target.value.trim()
        setPhoneValue(val)
    }

    const onPhoneEdit = () => {
        setPhoneActive(true)
        setPhoneValue(account.phone)
    }

    const onPhoneCancel = (event) => {
        event.preventDefault()
        setPhoneActive(false)
    }

    const onPhoneSave = () => {
        setPhoneActive(false)
        account.phone = phoneValue
        saveProfile()
    }

    const onLanguageChange = (value) => {
        account.language = value
        saveProfile()
    }

    const onFileChange = (event) => {
        if (!event.target.files.length) {
            return
        }

        const file = event.target.files[0]
        const reader = new FileReader()
        reader.onload = (readerEvent: ProgressEvent<FileReader>) => {
            const binaryString = readerEvent.target ? readerEvent.target.result : ''
            if (!binaryString) {
                return
            }

            setNewAvatar(binaryString.toString())
        }
        reader.readAsDataURL(file)
    }

    const onFileRemove = (event) => {
        event.preventDefault()
        event.stopPropagation()

        account.avatar = ''
        saveProfile()
    }

    const avatarView = newAvatar || account.avatar
    const languageOptions: {[key: string]: SelectOption} = {
        'ru': {name: t('common:langs.russian')},
        'en': {name: t('common:langs.english')}
    }

    return (
        <div className="tabs__pane on visible">
            <div className="form settings-page__form">
                <div className="row settings-page__row">
                    <div className="col-lg-3 col-md-3 settings-page__col">
                        <div className="form__group">
                            <label
                                className="form__label form__label--mb-20 form__label--grey"
                            >
                                {t('settings:titles.profile_picture')}
                            </label>
                            <div className="upload-avatar" onClick={() => {
                                if (fileRef.current) {
                                    fileRef.current.click()
                                }
                            }}>
                                <div className="upload-avatar__avatar">
                                    <div className="upload-avatar__default">
                                        {
                                            avatarView
                                            ?
                                                <>
                                                    <picture className="upload-avatar__picture">
                                                        <img
                                                            className="upload-avatar__photo"
                                                            src={avatarView}
                                                            alt="alt"
                                                        />
                                                    </picture>
                                                    <div
                                                        className="upload-avatar__delete"
                                                        onClick={onFileRemove}
                                                    >
                                                        <CloseRoundedIcon />
                                                    </div>
                                                </>
                                            :
                                                <div className="upload-avatar__default-circle">
                                                    <AvatarIcon />
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="upload-avatar__descr">
                                    <p>2 {t('MB')} {t('maximum')}</p>
                                    <p>{t('image_formats')}</p>
                                    <p>{t('min_resolution')}: 120x120px</p>
                                </div>
                                <button className="button button--bordered upload-avatar__button" type="button">
                                    {t('settings:buttons.upload_photo')}
                                </button>
                                <input
                                    type="file"
                                    style={{display: 'none'}}
                                    onChange={onFileChange}
                                    accept="image/jpeg,image/gif,image/png"
                                    ref={fileRef}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 settings-page__col">
                        {
                            usernameIsActive
                            ?
                                <>
                                    <div className="form__group form__group--mb-20">
                                        <input
                                            className="form__input"
                                            type="text"
                                            name="inputLogin"
                                            placeholder=""
                                            value={usernameValue}
                                            required
                                            onChange={onUsernameChanged}
                                        />
                                        <label
                                            className="form__placeholder is-active"
                                        >
                                            {t('common:forms.users.nickname')}
                                        </label>
                                    </div>
                                    <div className="form__buttons">
                                        <button
                                            className="button button--small form__button"
                                            onClick={onUsernameSave}
                                            disabled={!usernameBtnIsActive}
                                        >
                                            {t('common:buttons.save')}
                                        </button>
                                        <a
                                            href="#cancel"
                                            className="form__link form__link--red"
                                            onClick={onUsernameCancel}
                                        >
                                            {t('common:buttons.cancel')}
                                        </a>
                                    </div>
                                </>
                            :
                                <div className="form__group">
                                    <label className="form__label form__label--grey">
                                        {t('common:forms.users.nickname')}
                                    </label>
                                    <input
                                        className="form__input form__input--clear"
                                        type="text"
                                        name="inputLogin"
                                        placeholder=""
                                        value={account.username}
                                        readOnly
                                    />
                                    <button
                                        className="form__edit"
                                        type="button"
                                        onClick={onUsernameEdit}>
                                        <EditIcon />
                                    </button>
                                </div>
                        }

                        <div className="form__group">
                            <label className="form__label form__label--grey">
                                {t('common:forms.users.email')}
                            </label>
                            <input
                                className="form__input form__input--clear"
                                type="email"
                                name="inputEmail"
                                value={account.email}
                                readOnly
                            />
                        </div>


                        {
                            phoneIsActive
                                ?
                                <>
                                    <div className="form__group form__group--mb-20">
                                        <input
                                            className="form__input"
                                            type="text"
                                            name="inputPhone"
                                            placeholder=""
                                            value={phoneValue}
                                            required
                                            onChange={onPhoneChanged}
                                        />
                                        <label
                                            className="form__placeholder is-active"
                                        >
                                            {t('common:forms.users.phone')}
                                        </label>
                                    </div>
                                    <div className="form__buttons">
                                        <button
                                            className="button button--small form__button"
                                            onClick={onPhoneSave}
                                        >
                                            {t('common:buttons.save')}
                                        </button>
                                        <a
                                            href="#cancel"
                                            className="form__link form__link--red"
                                            onClick={onPhoneCancel}
                                        >
                                            {t('common:buttons.cancel')}
                                        </a>
                                    </div>
                                </>
                                :
                                <div className="form__group">
                                    <label
                                        className="form__label form__label--grey
                                            form__label--mb-10"
                                    >
                                        {t('common:forms.users.phone')}
                                    </label>
                                    {
                                    phoneIsEmpty
                                        ?
                                        <button
                                            className="button button--small button--bordered"
                                            type="button"
                                            onClick={onPhoneEdit}
                                        >
                                            {t('settings:buttons.add_phone')}
                                        </button>
                                        :
                                        <>
                                            <input
                                                className="form__input form__input--clear"
                                                type="text"
                                                name="inputPhone"
                                                placeholder=""
                                                value={account.phone}
                                                readOnly
                                            />
                                            <button
                                                className="form__edit"
                                                type="button"
                                                onClick={onPhoneEdit}>
                                                <EditIcon />
                                            </button>
                                        </>
                                    }
                                </div>
                        }

                        <div className="form__group">
                            <label className="form__label form__label--grey">
                                {t('settings:form.language')}
                            </label>
                            <Select
                                options={languageOptions}
                                onChange={onLanguageChange}
                                selected={account.language || 'en'}
                            />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-5 settings-page__col">
                        {/*<div className="form__group">*/}
                        {/*    <label*/}
                        {/*        className="form__label form__label--grey form__label--mb-10"*/}
                        {/*    >*/}
                        {/*        {t('settings:form.socials')}*/}
                        {/*    </label>*/}
                        {/*    <div className="connect-social">*/}
                        {/*        <div className="connect-social__item">*/}
                        {/*            <button className="connect-social__button" type="button">*/}
                        {/*                <div className="connect-social__button-pic">*/}
                        {/*                    <GoogleIcon />*/}
                        {/*                </div>*/}
                        {/*                {t('settings:form.connect_google')}*/}
                        {/*            </button>*/}
                        {/*            <button className="connect-social__delete" type="button">*/}
                        {/*                <SocialRemoveIcon/>*/}
                        {/*            </button>*/}
                        {/*        </div>*/}
                        {/*        <div className="connect-social__item">*/}
                        {/*            <button className="connect-social__button" type="button">*/}
                        {/*                <div className="connect-social__button-pic">*/}
                        {/*                    <FacebookIcon/>*/}
                        {/*                </div>*/}
                        {/*                {t('settings:form.connect_facebook')}*/}
                        {/*            </button>*/}
                        {/*            <button className="connect-social__delete" type="button">*/}
                        {/*                <SocialRemoveIcon/>*/}
                        {/*            </button>*/}
                        {/*        </div>*/}
                        {/*        <div className="connect-social__item">*/}
                        {/*            <button className="connect-social__button" type="button">*/}
                        {/*                <div className="connect-social__button-pic">*/}
                        {/*                    <TwitterIcon/>*/}
                        {/*                </div>*/}
                        {/*                {t('settings:form.connect_twitter')}*/}
                        {/*            </button>*/}
                        {/*            <button className="connect-social__delete" type="button">*/}
                        {/*                <SocialRemoveIcon/>*/}
                        {/*            </button>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state): StateProps => {
    return {
        account: state.authReducer.account,
    }
}

const mapDispatchToProps = (dispatch): DispatchProps => {
    return {
        reloadAccountData: () => dispatch(reloadAccountData())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
