import {Action} from 'redux'
import {
    REGISTRATION_CONFIRM_FAILED,
    REGISTRATION_CONFIRM_SUCCESS
} from '../../../actions/Security/Registration/actionTypes'

export type RegistrationConfirmState = {
    error: boolean
}

const initialState = {
    error: false
}

const registrationConfirmReducer = (
    state: RegistrationConfirmState = initialState,
    action: Action
): RegistrationConfirmState => {
    switch (action.type) {
        case REGISTRATION_CONFIRM_SUCCESS:
            return {
                ...state,
                error: false
            }
        case REGISTRATION_CONFIRM_FAILED:
            return {
                ...state,
                error: true
            }
        default:
            return state
    }
}

export default registrationConfirmReducer
