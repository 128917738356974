export type TwoFactorSecretsDataObject = {
    qr_code: string,
    secret: string,
}

class TwoFactorSecrets {
    private _qrCode: string
    private _secret: string

    constructor(data: TwoFactorSecretsDataObject) {
        this._qrCode = data.qr_code
        this._secret = data.secret
    }

    get qrCode(): string {
        return this._qrCode
    }

    set qrCode(value: string) {
        this._qrCode = value
    }

    get secret(): string {
        return this._secret
    }

    set secret(value: string) {
        this._secret = value
    }

    toDataObject = (): TwoFactorSecretsDataObject => {
        return {
            qr_code: this._qrCode,
            secret: this._secret,
        }
    }
}

export default TwoFactorSecrets
