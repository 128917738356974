import React from 'react'
import sprite from '../../../../styles/img/sprite.svg'

const LogoIcon: React.FC = () => (
    <>
        <svg className="logo__img" width="183" height="29">
            <use xlinkHref={sprite + '#logo'}/>
        </svg>
        <div className="logo__version">beta</div>
    </>
)

export default LogoIcon
