import React from 'react'
import {IPainting, IToken} from '../../../model/Interfaces'
import TokensListItemMobile from '../TokensListItemMobile/TokensListItemMobile'

interface IProps {
    isAuthenticated: boolean,
    indexToken: IToken,
    artTokens: IToken[],
    paintings: {[key: string]: IPainting},
    subscribe: (paintingId: string) => void
}

const TokensListMobile: React.FC<IProps> = (props: IProps) => {

    return (
        <table className="table table--grey tokens-page__table tokens-page__table--mobile">
            <tbody>
                <TokensListItemMobile
                    token={props.indexToken}
                    subscribe={props.subscribe}
                />
                {
                    props.artTokens.map((artToken: IToken, i: number) => {

                        return (
                            <TokensListItemMobile
                                key={'art-token-mobile-' + i}
                                token={artToken}
                                painting={props.paintings[artToken.paintingId]}
                                subscribe={props.subscribe}
                            />
                        )
                    })
                }
            </tbody>
        </table>
    )
}

export default TokensListMobile
