import React from 'react'

const SmallInfoIcon: React.FC = () => (
    <svg className="info-link__icon" width="12" height="12" viewBox="0 0 12 12"
         fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M5.99985 0C9.31307 0 12 2.68692 12 6C12 9.31306 9.31307 12 5.99985 12C2.68678 12 0 9.31308 0 6C0 2.68694 2.68677 0 5.99985 0ZM5.23701 5.3063H6.763V9.12141H5.23701V5.3063ZM5.23701 4.40457H6.763V2.87858H5.23701V4.40457ZM5.99985 1.35291C8.56599 1.35291 10.6469 3.43386 10.6469 5.99983C10.6469 8.56597 8.56599 10.6469 5.99985 10.6469C3.43387 10.6469 1.35292 8.56597 1.35292 5.99983C1.35292 3.43386 3.43387 1.35291 5.99985 1.35291Z"
              fill="black"/>
    </svg>
)

export default SmallInfoIcon
