import {Dispatch} from 'redux'
import axios from '../../../../axios/axios-backend'
import {API_RESET_PASSWORD_REQUEST} from '../../../../axios/paths'
import callbackUrl from '../../../../config/routing/callbackUrl'
import {RESET_PASSWORD_CONFIRM_PATH, RESET_PASSWORD_REQUEST_DONE_PATH} from '../../../../config/routing/paths'
import history from '../../../../utils/history'
import {REQUEST_RESET_PASSWORD_DONE, REQUEST_RESET_PASSWORD_FAILED, REQUEST_RESET_PASSWORD_SUCCESS} from './actionTypes'
import {LANGUAGE_STORAGE_NAME} from '../Auth/auth'

export type RequestResetPasswordResponse = {
    resend_available_at: number,
    resend_token: string
}

export const RESET_PASSWORD_RESEND_NAME = 'resetPassResend'

export const resetPasswordRequest = (
    email: string
) => async (dispatch: Dispatch): Promise<void> => {
    try {
        const locale = localStorage.getItem(LANGUAGE_STORAGE_NAME)
        const {data}: {data: RequestResetPasswordResponse} = await axios.post(
            API_RESET_PASSWORD_REQUEST + '?_locale=' + locale,
            {
                email,
                callback_url: callbackUrl(RESET_PASSWORD_CONFIRM_PATH, {
                    token: '%s'
                })
            }
        )
        localStorage.setItem(RESET_PASSWORD_RESEND_NAME, JSON.stringify(data))
        dispatch(requestResetPasswordSuccess())
    } catch (e) {
        console.log(e)
        dispatch(requestResetPasswordSuccess())
    }
}


const requestResetPasswordSuccess = () => {
    return {
        type: REQUEST_RESET_PASSWORD_SUCCESS
    }
}

const requestResetPasswordFailed = () => {
    return {
        type: REQUEST_RESET_PASSWORD_FAILED
    }
}

export const requestResetPasswordDone = () => {
    history.push(RESET_PASSWORD_REQUEST_DONE_PATH)

    return {
        type: REQUEST_RESET_PASSWORD_DONE
    }
}
