import {FeatureFlagListType} from '../../model/Types'
import {FEATURE_FLAGS_LOADED} from '../actions/featureFlagsTypes'

interface IState {
    featureFlags: FeatureFlagListType,
    featureFlagsLoaded: boolean,
}

const initialState: IState = {
    featureFlags: {},
    featureFlagsLoaded: false,
}

const balanceReducer = (
    state: IState = initialState,
    action: {type: string, payload?: FeatureFlagListType}
): IState => {
    switch (action.type) {
        case FEATURE_FLAGS_LOADED:
            return {
                ...state,
                featureFlags: action.payload || {},
                featureFlagsLoaded: true,
            }
        default:
            return state
    }
}

export default balanceReducer
