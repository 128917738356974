import React from 'react'
import {connect} from 'react-redux'
import {landingMenuSwitchVisibility} from '../../../store/actions/overlay'
import * as cs from 'classnames'

interface IProps {
    isActive: boolean,
    switchVisibility: (visible: boolean) => void,
}

const MenuToggle: React.FC<IProps> = (props: IProps) => {

    const onClickHandler = () => {
        props.switchVisibility(!props.isActive)
    }

    return (
        <div
            className={cs('sidebar-toggle', {'is-active': props.isActive})}
            onClick={onClickHandler}
        >
            <div className="sidebar-toggle__1"/>
            <div className="sidebar-toggle__2"/>
            <div className="sidebar-toggle__3"/>
        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        isActive: state.overlayReducer.landingMenuVisible
    }
}

const mapDispatchToProps = dispatch => {
    return {
        switchVisibility: (visible: boolean) => dispatch(landingMenuSwitchVisibility(visible)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuToggle)
