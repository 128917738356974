import {Action} from 'redux'
import {
    REGISTRATION_DONE,
    REGISTRATION_FAILED,
    REGISTRATION_SUCCESS
} from '../../../actions/Security/Registration/actionTypes'

type Errors = {[key: string]: string} | null

export type RegisterUserState = {
    submitted: boolean,
    error: boolean,
    errors: Errors
}

const initialState = {
    submitted: false,
    error: false,
    errors: null
}

const registerUserReducer = (
    state: RegisterUserState = initialState,
    action: {type: string, errors?: Errors}
): RegisterUserState => {
    switch (action.type) {
        case REGISTRATION_SUCCESS:
            return {
                ...state,
                submitted: true,
                error: false,
                errors: null
            }
        case REGISTRATION_FAILED:
            return {
                ...state,
                submitted: true,
                error: true,
                errors: action.errors || null
            }
        case REGISTRATION_DONE:
            return {
                ...state,
                submitted: false,
                error: false,
                errors: null
            }
        default:
            return state
    }
}

export default registerUserReducer
