import {
    MODALS_VISIBLE,
    NOTICES_VISIBLE,
    OVERLAYS_INVISIBLE,
    SIDEBAR_VISIBLE,
    LANDING_MENU_VISIBLE,
    FILTER_VISIBLE,
} from '../actions/overlayTypes'

interface IOverlayState {
    sidebarVisible: boolean,
    noticesVisible: boolean,
    modalVisible: boolean,
    overlayVisible: boolean,
    landingMenuVisible: boolean,
    filterVisible: boolean,
}

const initialState: IOverlayState = {
    sidebarVisible: false,
    noticesVisible: false,
    modalVisible: false,
    overlayVisible: false,
    landingMenuVisible: false,
    filterVisible: false,
}

const overlayReducer = (
    state: IOverlayState = initialState,
    action: {type: string}
): IOverlayState => {
    switch (action.type) {
        case SIDEBAR_VISIBLE:
            return {
                ...initialState,
                overlayVisible: true,
                sidebarVisible: true,
            }
        case NOTICES_VISIBLE:
            return {
                ...initialState,
                overlayVisible: true,
                noticesVisible: true,
            }
        case MODALS_VISIBLE:
            return {
                ...initialState,
                overlayVisible: true,
                modalVisible: true,
            }
        case LANDING_MENU_VISIBLE:
            return {
                ...initialState,
                overlayVisible: true,
                landingMenuVisible: true,
            }
        case FILTER_VISIBLE:
            return {
                ...initialState,
                overlayVisible: true,
                filterVisible: true,
            }
        case OVERLAYS_INVISIBLE:
            return {
                ...initialState,
            }
        default:
            return state
    }
}

export default overlayReducer
