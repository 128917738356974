import React, {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import validator from 'validator'
import InputPassword from '../../../../components/UI/Form/Elements/InputPassword/InputPassword'
import SecurityLayout from '../../../../hoc/SecurityLayout/SecurityLayout'
import {
    resetPassword,
    resetPasswordDone
} from '../../../../store/actions/Security/ResetPassword/resetPasswordConfirm'
import messages from '../../../../utils/ValidatorMessages/ValidatorMessages'
import PassDiff, {PassDiffOptions} from '../../../../utils/Validators/PassDiff'
import TwoFactorCode from '../../../../components/UI/Form/Elements/TwoFactorCode/TwoFactorCode'
import SubmitButton from '../../../../components/UI/Form/Elements/SubmitButton/SubmitButton'
import queryString, {ParsedQuery} from 'query-string'
import history from '../../../../utils/history'
import ConfirmationToken from '../../../../model/ConfirmationToken'
import {CodeType} from '../../../../model/Types'

type FormData = {
    password: string,
    password_repeat: string,
}

interface DispatchProps {
    resetPassword: (
        password: string,
        password_repeat: string,
        token: string,
        code: string | null
    ) => void,
    resetPasswordDone: () => void,
}

interface StateProps {
    submitted: boolean,
    error: boolean,
    errors: [],
}

type PropsType = StateProps & DispatchProps

const ResetPasswordConfirm: React.FC<PropsType> = (props: PropsType) => {
    const {getValues, handleSubmit, errors, control, reset} = useForm<FormData>()
    const {t} = useTranslation()

    const [code, setCode] = useState<CodeType>((new Array(6)).fill(null))
    const [hasError, setHasError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const changeHandler = (code: CodeType) => {
        setCode(code)
        setHasError(false)
        setErrorMessage('')
    }

    useEffect(() => {
        if (props.submitted && !props.error) {
            reset()
            setCode((new Array(6)).fill(null))

            props.resetPasswordDone()
        }
    }, [props.submitted, props.error])

    useEffect(() => {
        if (!props.error) {
            return
        }

        props.errors.map((errorItem) => {
            if (!('path' in errorItem) || !('message' in errorItem)) {
                return
            }
            if (errorItem['path'] === 'code') {
                setCode((new Array(6)).fill(null))
                setHasError(true)
                setErrorMessage('')
                return;
            }
        })
    }, [props.submitted, props.error, props.errors])

    const searchParams: ParsedQuery = queryString.parse(history.location.search)
    const token: string = searchParams.token?.toString() || ''
    const confirmationToken: ConfirmationToken | null = token ? new ConfirmationToken(token) : null

    if (!confirmationToken) {
        return (
            <SecurityLayout>
                <>
                    <div className="login-page__body">
                        <h1 className="login-page__title login-page__title--sm-mb-10">
                            Error
                        </h1>
                        <div className="login-page__descr">
                            <p>
                                Token is not provided
                            </p>
                        </div>
                    </div>
                </>
            </SecurityLayout>
        )
    }

    if (confirmationToken.isExpired()) {
        return (
            <SecurityLayout>
                <>
                    <div className="login-page__body">
                        <h1 className="login-page__title login-page__title--sm-mb-10">
                            Error
                        </h1>
                        <div className="login-page__descr">
                            <p>
                                Token is expired
                            </p>
                        </div>
                    </div>
                </>
            </SecurityLayout>
        )
    }

    const twoFaRequired: boolean = confirmationToken.isTwoFaRequired
    const onSubmitHandler = (data: FormData) => {
        if (twoFaRequired && !code.join('')) {
            setHasError(true)
            setErrorMessage(messages.required)
            return
        }

        props.resetPassword(
            data.password,
            data.password_repeat,
            confirmationToken.token,
            code.join(''),
        )
    }

    return (
        <SecurityLayout>
            <>
                <div className="login-page__body">
                    <h1 className="login-page__title login-page__title--sm-mb-10">
                        {t('security:form.new_password')}
                    </h1>
                    <div className="login-page__descr">
                        <p>
                            {t('security:form.create_new_password')}
                        </p>
                    </div>
                    <form
                        className="form login-page__form"
                        onSubmit={handleSubmit(onSubmitHandler)}
                        autoComplete="chrome-off"
                    >
                        <Controller
                            name="password"
                            label={t('security:form.pick_password')}
                            defaultValue=""
                            error={errors.password}
                            blockClasses={['form__group--mb-10']}
                            as={InputPassword}
                            control={control}
                            rules={{
                                required: t(messages.required).toString(),
                                minLength: {value: 8, message: t(messages.minLength, {count: 8}).toString()},
                                validate: value =>
                                    PassDiff(value, [
                                        PassDiffOptions.lowerCase,
                                        PassDiffOptions.upperCase,
                                        PassDiffOptions.specChars,
                                        PassDiffOptions.digits,
                                    ])
                                    || t(messages.passwordDifficulty).toString()
                            }}
                        />
                        <div className="form__group">
                            <ul className="form__list">
                                <li>— {t('security:form.at_least_characters', {count: 8})}</li>
                                <li>— {t('security:form.upper_lower_letters')}</li>
                                <li>— {t('security:form.numbers')}</li>
                                <li>— {t('security:form.special_characters')}</li>
                            </ul>
                        </div>
                        <Controller
                            name="password_repeat"
                            label={t('security:form.repeat_password')}
                            defaultValue=""
                            error={errors.password_repeat}
                            as={InputPassword}
                            control={control}
                            rules={{
                                required: t('security:errors.required_field').toString(),
                                validate: value =>
                                    validator.equals(value, getValues('password'))
                                    || t(messages.strictEqual, {fields: t('security:form.passwords')}).toString()
                            }}
                        />
                        {
                            twoFaRequired
                                ?
                                    <>
                                        <div>
                                            <p>
                                                {t('settings:texts.enter_tfa_6_digit_code')}
                                            </p>
                                        </div>
                                        <TwoFactorCode
                                            code={code}
                                            onChange={changeHandler}
                                            error={hasError}
                                            errorMessage={errorMessage}
                                            bindFocus={false}
                                        />
                                    </>
                                : null
                        }
                        <div className="form__buttons form__buttons--center">
                            <SubmitButton className="button form__button">
                                {t('common:buttons.save')}
                            </SubmitButton>
                        </div>
                    </form>
                </div>
                <div className="login-page__bottom"/>
            </>
        </SecurityLayout>
    )
}

const mapStateToProps = (state): StateProps => {
    return {
        submitted: state.resetPasswordConfirmReducer.submitted,
        error: state.resetPasswordConfirmReducer.error,
        errors: state.resetPasswordConfirmReducer.errors,
    }
}

const mapDispatchToProps = (dispatch): DispatchProps => {
    return {
        resetPassword: (
            password: string,
            repeatPassword: string,
            token: string,
            code: string | null,
        ) => dispatch(resetPassword(password, repeatPassword, token, code)),
        resetPasswordDone: () => dispatch(resetPasswordDone())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordConfirm)
