import moment from 'moment'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import Button from '../../../../components/UI/Form/Elements/Button/Button'
import Timer from '../../../../components/UI/Timer/Timer'
import {AUTH_PATH, RESET_PASSWORD_REQUEST_PATH} from '../../../../config/routing/paths'
import SecurityLayout from '../../../../hoc/SecurityLayout/SecurityLayout'
import {
    RequestResetPasswordResponse,
    RESET_PASSWORD_RESEND_NAME
} from '../../../../store/actions/Security/ResetPassword/resetPasswordRequest'
import history from '../../../../utils/history'

const ResetPasswordRequestDone: React.FC = () => {
    const resendDataString: string | null = localStorage.getItem(RESET_PASSWORD_RESEND_NAME) || null
    let resendData: RequestResetPasswordResponse | null = resendDataString ? JSON.parse(resendDataString) : null

    if (!resendData) {
        resendData = {
            resend_token: '',
            resend_available_at: moment().unix() + 300 // add 5 min as fake timeout
        }
        localStorage.setItem(RESET_PASSWORD_RESEND_NAME, JSON.stringify(resendData))
    }

    const resendToken: string | null = resendData?.resend_token || null
    const expiresInTimestamp: number = resendData?.resend_available_at || moment().unix() + 300
    const expiresIn = moment.unix(expiresInTimestamp).diff(moment(), 's')

    const [resendAvailable, setResendAvailable] = useState<boolean>(expiresIn <= 0)
    const {t} = useTranslation();

    const resendEmailHandler = () => {
        if (resendAvailable && !resendToken) {
            history.push(RESET_PASSWORD_REQUEST_PATH)
        }

        console.log('@todo: resend email')
    }

    return (
        <SecurityLayout>
            <>
                <div className="login-page__body">
                    <h1 className="login-page__title login-page__title--sm-mb-20">
                        {t('security:titles.check_your_email')}
                    </h1>
                    <div className="login-page__descr">
                        <p>
                            {t('security:texts.we_send_recovery_access_email')}
                        </p>
                        <p>
                            {t('security:texts.check_spam')}
                        </p>
                    </div>
                    <div className="login-info">
                        <p>
                            {t('security:texts.did_not_receive_email')}
                        </p>
                        {resendAvailable
                            ?
                            <div className="form__buttons form__buttons--center">
                                <Button
                                    disabled={false}
                                    onClick={resendEmailHandler}
                                    className="button form__button form__button--auto"
                                >
                                    {t('security:buttons.send_again')}
                                </Button>
                            </div>

                            :
                            <>
                                <p>
                                    {t('security:texts.send_next_in')}
                                </p>
                                <Timer
                                    expiresIn={expiresIn}
                                    callWhenExpired={() => setResendAvailable(true)}
                                />
                            </>
                        }
                    </div>
                </div>
                <div className="login-page__bottom">
                    <div className="login-info">
                        <Link className="login-info__link" to={AUTH_PATH}>
                            {t('security:buttons.sign_in')}
                        </Link>
                    </div>
                </div>
            </>
        </SecurityLayout>
    )
}

export default connect(null, null)(ResetPasswordRequestDone)
