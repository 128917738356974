import React from 'react'
import {useTranslation} from 'react-i18next'
import WalletList from '../../components/Wallet/WalletList/WalletList'
import WalletEmpty from '../../components/Wallet/WalletEmpty/WalletEmpty'
import WalletHeader from '../../components/Wallet/WalletHeader/WalletHeader'
import PreLoader from '../../components/UI/PreLoader/PreLoader'
import {connect} from 'react-redux'
import AccountBalance from '../../model/AccountBalance'
import Tokens from '../../model/Tokens'
import MainLayout from '../../hoc/MainLayout/MainLayout'

interface IProps {
    tokensLoaded: boolean,
    tokens: Tokens | null,
    balance: AccountBalance | null,
}

const WalletPage: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()

    if (!props.tokensLoaded || !props.balance) {
        return <PreLoader/>
    }

    if (props.tokens === null) {
        return null
    }

    const isEmpty = props.balance.balanceAmount === 0

    return (
        <MainLayout>
            <main className="portfolio-page">
                <div className="portfolio-page__inner">
                    {
                        !isEmpty
                            ?
                            <>
                                <WalletHeader/>
                                <WalletList
                                    tokens={props.tokens}
                                    balance={props.balance}
                                />
                            </>
                            : <WalletEmpty/>
                    }
                </div>
            </main>
        </MainLayout>
    )
}

function mapStateToProps(state) {
    return {
        tokensLoaded: state.tokenReducer.tokensLoaded,
        balance: state.balanceReducer.balance,
        tokens: state.tokenReducer.tokens,
    }
}

export default connect(mapStateToProps)(WalletPage)
