import React from 'react'

interface PropsInterface {
    onClick: any,
    disabled: boolean,
    children: any,
    className: string
}

const Button = (props: PropsInterface) => {
    return (
        <button
            onClick={props.onClick}
            className={props.className}
            disabled={props.disabled}
        >
            {props.children}
        </button>
    )
}

export default Button
