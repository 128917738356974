export enum PassDiffOptions {
    lowerCase = 'lowerCase',
    upperCase = 'upperCase',
    digits = 'digits',
    specChars = 'specChars',
}

const PassDiff = (value: string, config: string[]): boolean => {
    const errors = config.filter((type: string) => {
        switch (type) {
            case PassDiffOptions.lowerCase:
                return !/[a-z]/.test(value)
            case PassDiffOptions.upperCase:
                return !/[A-Z]/.test(value)
            case PassDiffOptions.digits:
                return !/[0-9]/.test(value)
            case PassDiffOptions.specChars:
                return !/[^a-zA-Z0-9 ]/.test(value)
            default:
                return false
        }
    })

    return !errors.length
}

export default PassDiff
