import React, {useLayoutEffect} from 'react'
import {connect} from 'react-redux'
import BackButton from '../../components/UI/BackButton/BackButton'
import {MAIN_PATH} from '../../config/routing/paths'
import MainLayout from '../MainLayout/MainLayout'

interface IProps {
    children: React.ReactNode[],
    isAuthenticated: boolean
}

const StaticPagesLayout: React.FC<IProps> = (props: IProps) => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <MainLayout>
            <main className="faq-page">
                <div className="faq-page__inner">
                    <div className="prev-step help-page__prev-step">
                        <BackButton url={MAIN_PATH}/>
                    </div>
                    {props.children}
                </div>
            </main>
        </MainLayout>
    )
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.authReducer.authToken.isFullyAuthenticated(),
    }
}

export default connect(mapStateToProps)(StaticPagesLayout)
