import {connect} from 'react-redux'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath} from 'react-router'
import {API_OPERATIONS} from '../../../axios/paths'
import {OPERATION_PATH, OPERATIONS_PATH} from '../../../config/routing/paths'
import BasketItem from '../../../model/BasketItem'
import ApiClient from '../../../services/ApiClient'
import {RENEW_DATA} from '../../../store/actions/actionTypes'
import {reloadOperations} from '../../../store/actions/operation'
import store from '../../../store/store'
import cryptoIcon1 from '../../../styles/img/crypto-icon-1.png'
import cryptoIcon1_2x from '../../../styles/img/crypto-icon-1@2x.png'
import cryptoIcon2 from '../../../styles/img/crypto-icon-2.png'
import cryptoIcon2_2x from '../../../styles/img/crypto-icon-2@2x.png'
import cryptoIcon3 from '../../../styles/img/crypto-icon-3.png'
import cryptoIcon3_2x from '../../../styles/img/crypto-icon-3@2x.png'
import cryptoIcon4 from '../../../styles/img/crypto-icon-4.png'
import cryptoIcon4_2x from '../../../styles/img/crypto-icon-4@2x.png'
import cryptoIcon5 from '../../../styles/img/crypto-icon-5.png'
import cryptoIcon5_2x from '../../../styles/img/crypto-icon-5@2x.png'
import cryptoIcon6 from '../../../styles/img/crypto-icon-6.png'
import cryptoIcon6_2x from '../../../styles/img/crypto-icon-6@2x.png'
import history from '../../../utils/history'
import PreLoader from '../../UI/PreLoader/PreLoader'
import CryptoPaymentMethodButton from './CryptoPaymentMethodButton/CryptoPaymentMethodButton'
import BackButton from '../../UI/BackButton/BackButton'
import {CryptoPaymentMethod} from '../../../model/Enums'
import {IOperation} from '../../../model/Interfaces'

interface IPaymentMethod {
    type: CryptoPaymentMethod,
    name: string,
    icon: string,
    icon2x: string,
}

const methods: IPaymentMethod[] = [{
    type: CryptoPaymentMethod.BITCOIN,
    name: 'Bitcoin',
    icon: cryptoIcon1,
    icon2x: cryptoIcon1_2x,

}, {
    type: CryptoPaymentMethod.BITCOIN_CASH,
    name: 'Bitcoin Cash',
    icon: cryptoIcon2,
    icon2x: cryptoIcon2_2x,
}, {
    type: CryptoPaymentMethod.ETHEREUM,
    name: 'Ethereum',
    icon: cryptoIcon3,
    icon2x: cryptoIcon3_2x,
}, {
    type: CryptoPaymentMethod.LITECOIN,
    name: 'Litecoin',
    icon: cryptoIcon4,
    icon2x: cryptoIcon4_2x,
}, {
    type: CryptoPaymentMethod.DAI,
    name: 'DAI',
    icon: cryptoIcon5,
    icon2x: cryptoIcon5_2x,
}, {
    type: CryptoPaymentMethod.USDCOIN,
    name: 'USD Coin',
    icon: cryptoIcon6,
    icon2x: cryptoIcon6_2x,
}]

interface IProps {
    items: BasketItem[]
    onCancel: () => void
}

const ChooseCrypto: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()
    const [cryptoPaymentMethod, setCryptoPaymentMethod] = useState<CryptoPaymentMethod|null>(null)
    const [sending, setSending] = useState<boolean>(false)

    const onClickConfirmHandler = () => {
        if (cryptoPaymentMethod === null) {
            return
        }

        setSending(true)

        const apiClient = ApiClient()
        apiClient
            .post(API_OPERATIONS, {
                currency: cryptoPaymentMethod,
                items: props.items.map((item: BasketItem) => {
                    return {
                        'token_id': item.id,
                        'quantity': item.count,
                    }
                })
            })
            .then(async ({data}: {data: IOperation}) => {
                await store.dispatch({type: RENEW_DATA})
                history.push(generatePath(OPERATION_PATH, {uuid: data.id}))
            })
    }

    if (sending) {
        return (
            <div className="operations-page__inner operations-page__inner--mw-400">
                <PreLoader/>
            </div>
        )
    }

    return (
        <div className="operations-page__inner operations-page__inner--mw-400">
            <div className="prev-step">
                <BackButton url={OPERATIONS_PATH} callback={props.onCancel}/>
            </div>
            <h1 className="operations-page__title operations-page__title--center">
                {t('common:titles.select_payment_currency')}
            </h1>
            <div className="operations-page__descr operations-page__descr--small">
                <p>
                    {t('common:we_will_generate_wallet')}
                </p>
            </div>
            <div className="form operations-page__form">
                <div className="form__group form__group--mb-10">
                    <div className="row row--10">
                        {
                            methods.map((method: IPaymentMethod, i) => {
                                return (
                                    <CryptoPaymentMethodButton
                                        key={'crypto-method-payment' + i}
                                        active={method.type === cryptoPaymentMethod}
                                        setActive={() => {setCryptoPaymentMethod(method.type)}}
                                        name={method.name}
                                        icon={method.icon}
                                        icon2x={method.icon2x}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
                <div className="form__buttons form__buttons--center">
                    <button
                        className="button form__button"
                        onClick={onClickConfirmHandler}
                    >
                        {t('common:buttons.confirm')}
                    </button>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        reloadOperations: () => dispatch(reloadOperations())
    }
}

export default connect(null, mapDispatchToProps)(ChooseCrypto)
