import React from 'react'
import sprite from '../../../../styles/img/sprite.svg'

const LeftIcon: React.FC = () => (
    <svg className="prev-step__icon">
        <use xlinkHref={`${sprite}#arrow-prev`}/>
    </svg>
)

export default LeftIcon
