import axios from '../../../../axios/axios-backend'
import {API_CHANGE_PASSWORD} from '../../../../axios/paths'
import {reloadAccountData} from '../Auth/auth'
import {
    CHANGE_PASSWORD_DONE,
    CHANGE_PASSWORD_FAILED,
    CHANGE_PASSWORD_SUCCESS
} from './actionTypes'

export function changePassword(
    newPassword: string,
    repeatNewPassword: string,
    currentPassword: string | null,
    code: string | null = null
) {
    return async dispatch => {
        try {
            const {data} = await axios.post(API_CHANGE_PASSWORD, {
                new_password: newPassword,
                repeat_new_password: repeatNewPassword,
                current_password: currentPassword,
                code: code
            })
            await dispatch(changePasswordSuccess())
            dispatch(reloadAccountData())
        } catch (e) {
            console.log(e)
            dispatch(changePasswordFailed(e.response.data.errors || null))
        }
    }
}

const changePasswordSuccess = () => {
    return {
        type: CHANGE_PASSWORD_SUCCESS
    }
}

const changePasswordFailed = (errors) => {
    return {
        type: CHANGE_PASSWORD_FAILED,
        payload: errors
    }
}

export const changePasswordDone = () => {
    return {
        type: CHANGE_PASSWORD_DONE
    }
}
