import React from 'react'
import sprite from '../../../../styles/img/sprite.svg'

const FilterIcon: React.FC = () => (
    <svg className="filter-sidebar__toggle-icon" width="14" height="14">
        <use xlinkHref={`${sprite}#filter`}/>
    </svg>
)

export default FilterIcon
