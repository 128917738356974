import React from 'react'
import sprite from '../../../../styles/img/sprite.svg'

interface IProps {
    name: string,
}

const PaintingPriceIcon: React.FC<IProps> = (props: IProps) => (
    <svg className="picture-price__pic-icon" width="16" height="16">
        <use xlinkHref={`${sprite}#${props.name}`}/>
    </svg>
)

export default PaintingPriceIcon
