export type ArtTokenBalanceDataObject = {
    id: string,
    quantity: string,
}

class ArtTokenBalance {
    private _id: string
    private _quantity: string

    constructor(data: ArtTokenBalanceDataObject) {
        this._id = data.id
        this._quantity = data.quantity
    }


    get id(): string {
        return this._id
    }

    get quantity(): string {
        return this._quantity
    }

    toDataObject = (): ArtTokenBalanceDataObject => {
        return {
            id: this._id,
            quantity: this._quantity,
        }
    }
}

export default ArtTokenBalance
