import React, {useEffect, useRef} from 'react'

interface IProps {
    value: number | null,
    isActive: boolean,
    onChange?: (value: string) => void,
    onStepBack?: () => void,
}

const TwoFactorCodeInput: React.FC<IProps> = (props: IProps) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const {value, isActive, onChange, onStepBack} = props

    useEffect(() => {
        if (!inputRef || !inputRef.current || !isActive) {
            return
        }

        inputRef.current.focus()
    }, [inputRef, isActive])

    const handleFocus = (event: React.FocusEvent) => {
        event.target.setAttribute('placeholder', '')
    }

    const handleBlur = (event: React.FocusEvent) => {
        event.target.setAttribute('placeholder', '0')
    }

    const changeHandler = () => {
        if (!inputRef || !inputRef.current) {
            return
        }
        let value = inputRef.current.value
        if (!/\d{1}/.test(value)) {
            value = ''
        }

        onChange && onChange(value)
    }

    const keyDownHandler = (event: React.KeyboardEvent) => {
        if (!inputRef || !inputRef.current) {
            return
        }
        if (event.key !== 'Backspace' && event.key !== 'Delete') {
            return
        }

        if (inputRef.current.value !== '') {
            onChange && onChange('')
            return
        }

        onStepBack && onStepBack()
    }

    const pasteHandler = (event: React.ClipboardEvent) => {
        const value = event.clipboardData.getData('text').replace(/[^\d]/, '')
        if (!value.length) {
            return
        }

        event.preventDefault()
        onChange && onChange(value)
    }

    return (
        <div className="form__col">
            <input
                className="form__input form__input--center"
                type="text"
                placeholder="0"
                name={'two-fa-' + Math.random()}
                maxLength={1}
                required
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={changeHandler}
                value={value !== null ? value : ''}
                ref={inputRef}
                onKeyDown={keyDownHandler}
                onPaste={pasteHandler}
                autoComplete={'two-fa-' + Math.random()}
            />
        </div>
    )
}

export default TwoFactorCodeInput
