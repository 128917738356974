import React from 'react'
import sprite from '../../../../styles/img/sprite.svg'

const UserArrowIcon: React.FC = () => (
    <svg className="header-user__arrow" width="12" height="7">
        <use xlinkHref={`${sprite}#user-arrow`}/>
    </svg>
)

export default UserArrowIcon
