import React, {useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import validator from 'validator'
import Button from '../../../../components/UI/Form/Elements/Button/Button'
import Checkbox from '../../../../components/UI/Form/Elements/Checkbox/Checkbox'
import Input from '../../../../components/UI/Form/Elements/Input/Input'
import InputMobile from '../../../../components/UI/Form/Elements/InputMobile/InputMobile'
import InputPassword from '../../../../components/UI/Form/Elements/InputPassword/InputPassword'
import {STATIC_COOKIES_POLICY, STATIC_PRIVACY_POLICY, STATIC_TERMS_OF_USE} from '../../../../config/routing/paths'
import SecurityLayout from '../../../../hoc/SecurityLayout/SecurityLayout'
import {registerUser, registrationDone} from '../../../../store/actions/Security/Registration/registerUser'
import messages from '../../../../utils/ValidatorMessages/ValidatorMessages'
import PassDiff, {PassDiffOptions} from '../../../../utils/Validators/PassDiff'

type FormData = {
    email: string,
    username: string,
    phone: string,
    password: string,
    password_repeat: string,
    subscribe: boolean
}

interface DispatchProps {
    registerUser: (
        email: string,
        username: string,
        phone: string,
        password: string,
        password_repeat: string,
        subscribe: boolean,
        setError: any
    ) => void,
    registrationDone: () => void
}

interface StateProps {
    submitted: boolean,
    error: boolean,
    errors: {[key: string]: string}
}

type PropsType = StateProps & DispatchProps

const Registration: React.FC<PropsType> = (props: PropsType) => {
    const {t} = useTranslation()
    const {getValues, handleSubmit, errors, control, reset, setError} = useForm<FormData>({
        mode: 'onBlur'
    })

    const registrationHandler = (data: FormData) => {
        props.registerUser(
            data.email,
            data.username,
            data.phone,
            data.password,
            data.password_repeat,
            data.subscribe,
            setError
        )
    }

    const onSubmitHandler = (event) => {
        event.preventDefault()
    }

    useEffect(() => {
        if (props.submitted && !props.error) {
            reset()
            props.registrationDone()
        }
    }, [props.submitted, props.error])

    return (
        <SecurityLayout>
            <div className="login-page__body">
                <h1 className="login-page__title">
                    {t('security:titles.registration')}
                </h1>
                <form className="form login-page__form" onSubmit={onSubmitHandler} autoComplete="chrome-off">
                    <Controller
                        name="email"
                        label={t('security:form.your_email')}
                        defaultValue=""
                        error={errors.email}
                        as={Input}
                        control={control}
                        rules={{
                            required: t(messages.required).toString(),
                            validate: value =>
                                validator.isEmail(value)
                                || t(messages.isEmail).toString()
                        }}
                    />
                    <Controller
                        name="username"
                        label={t('security:form.nickname')}
                        defaultValue=""
                        error={errors.username}
                        as={Input}
                        control={control}
                        rules={{required: t(messages.required).toString()}}
                    />
                    <Controller
                        name="phone"
                        label={t('security:form.phone')}
                        defaultValue=""
                        error={errors.phone}
                        control={control}
                        as={InputMobile}
                        rules={{
                            validate: value =>
                                !value.trim()
                                || validator.isMobilePhone(value, null, {strictMode: true})
                                || t(messages.isPhone).toString()
                        }}
                    />
                    <Controller
                        name="password"
                        label={t('security:form.pick_password')}
                        defaultValue=""
                        error={errors.password}
                        blockClasses={['form__group--mb-10']}
                        as={InputPassword}
                        control={control}
                        rules={{
                            required: t(messages.required).toString(),
                            minLength: {value: 8, message: t(messages.minLength, {count: 8}).toString()},
                            validate: value =>
                                PassDiff(value, [
                                    PassDiffOptions.lowerCase,
                                    PassDiffOptions.upperCase,
                                    PassDiffOptions.specChars,
                                    PassDiffOptions.digits,
                                ])
                                || t(messages.passwordDifficulty).toString()
                        }}
                    />
                    <div className="form__group">
                        <ul className="form__list">
                            <li>— {t('security:form.at_least_characters', {count: 8})}</li>
                            <li>— {t('security:form.upper_lower_letters')}</li>
                            <li>— {t('security:form.numbers')}</li>
                            <li>— {t('security:form.special_characters')}</li>
                        </ul>
                    </div>
                    <Controller
                        name="password_repeat"
                        label={t('security:form.repeat_password')}
                        defaultValue=""
                        error={errors.password_repeat}
                        as={InputPassword}
                        control={control}
                        rules={{
                            required: t(messages.required).toString(),
                            validate: value =>
                                validator.equals(value, getValues('password'))
                                || t(messages.strictEqual, {fields: t('security:form.passwords')}).toString()
                        }}
                    />
                    <Controller
                        name="subscribe"
                        label={t('security:form.subscribe')}
                        defaultValue={true}
                        error={errors.subscribe}
                        as={Checkbox}
                        control={control}
                    />
                    <div className="form__buttons">
                        <Button
                            onClick={handleSubmit(registrationHandler)}
                            className="button form__button form__button--wide"
                            disabled={false}
                        >
                            {t('security:buttons.create_account')}
                        </Button>
                    </div>
                    <div className="form__group">
                        <div className="login-info login-info--small login-info--left">
                            <p>
                                {t('security:buttons.by_clicking_create_account')} <Link to={STATIC_TERMS_OF_USE}>
                                    {t('security:buttons.terms_of_use')}
                                </Link>, <Link to={STATIC_PRIVACY_POLICY}>
                                    {t('security:buttons.privacy_policy')}
                                </Link> {t('security:texts.and')} <Link to={STATIC_COOKIES_POLICY}>
                                    {t('security:buttons.cookie_policy')}
                                </Link>
                            </p>
                        </div>
                    </div>
                </form>
            </div>
        </SecurityLayout>
    )
}

const mapStateToProps = (state): StateProps => {
    return {
        submitted: state.registerUserReducer.submitted,
        error: state.registerUserReducer.error,
        errors: state.registerUserReducer.errors
    }
}

const mapDispatchToProps = (dispatch): DispatchProps => {
    return {
        registerUser: (
            email: string,
            username: string,
            phone: string,
            password: string,
            repeatPassword: string,
            subscribe: boolean,
            setError: any
        ) => dispatch(registerUser(email, username, phone, password, repeatPassword, subscribe, setError)),
        registrationDone: () => dispatch(registrationDone())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Registration)
