import React from 'react'

interface IProps {
    label: string,
    id: string,
    isChecked: boolean,
    onChange: (value: boolean) => void,
    icon?: React.ReactElement,
}

const Toggle: React.FC<IProps> = (props: IProps) => {
    const onChangeHandler = () => {
        props.onChange(!props.isChecked)
    }

    return (
        <div className="filter-form__single">
            <div className="checkbox checkbox--black">
                <input
                    className="checkbox__input"
                    id={props.id}
                    type="checkbox"
                    name="checkbox"
                    value="1"
                    checked={props.isChecked}
                    onChange={onChangeHandler}
                />
                <label className="checkbox__label" htmlFor={props.id}>
                    {props.label}
                    {props.icon}
                </label>
            </div>
        </div>
    )
}

export default Toggle
