import React from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import SecurityLayout from '../../../../hoc/SecurityLayout/SecurityLayout'
import {accountRecoverySendRequest} from '../../../../store/actions/Security/AccountRecovery/accountRecoveryRequest'

interface DispatchProps {
    accountRecoverySendRequest: () => void
}

interface StateProps {
    submitted: boolean,
    error: boolean
}

type PropsType = StateProps & DispatchProps

const AccountRecoveryRequest: React.FC<PropsType> = (props: PropsType) => {
    const requestAccountRecoveryHandler = () => {
        props.accountRecoverySendRequest()
    }

    const {t} = useTranslation()

    return (
        <SecurityLayout>
            <>
                <div className="login-page__body">
                    <h1 className="login-page__title login-page__title--sm-mb-20">
                        {t('security:titles.account_recovery')}
                    </h1>
                    <div className="login-page__descr">
                        <p>
                            {t('security:texts.you_can_restore_account')}
                        </p>
                    </div>
                    <form className="form login-page__form">
                        <div className="form__buttons form__buttons--center">
                            <button
                                onClick={requestAccountRecoveryHandler}
                                className="button form__button form__button--auto"
                            >
                                {t('security:buttons.recover_account')}
                            </button>
                        </div>
                    </form>
                </div>
                <div className="login-page__bottom"/>
            </>
        </SecurityLayout>
    )
}

const mapStateToProps = (state): StateProps => {
    return {
        submitted: state.accountRecoveryRequestReducer.submitted,
        error: state.accountRecoveryRequestReducer.error
    }
}

const mapDispatchToProps = (dispatch): DispatchProps => {
    return {
        accountRecoverySendRequest: () => dispatch(accountRecoverySendRequest()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountRecoveryRequest)
