import jwt_decode from 'jwt-decode'
import {IAuthToken} from './Interfaces'

type JWTTokenData = {
    exp: number,
    two_fa_required: boolean,
    two_fa_verified: boolean,
}

class AuthToken implements IAuthToken {
    private _token: string
    private _expiresAt: Date
    private _isTwoFaRequired: boolean

    constructor(token: string) {
        this._token = token
        const payload = jwt_decode(token) as JWTTokenData
        this._expiresAt = new Date(payload.exp * 1000)
        this._isTwoFaRequired = payload.two_fa_required && !payload.two_fa_verified
    }

    isExpired = (): boolean => {
        return this._expiresAt <= new Date()
    }

    getExpiresIn = (): number => {
        return this._expiresAt.getTime() - new Date().getTime()
    }

    isFullyAuthenticated = (): boolean => {
        return !!this.token && !this.isExpired() && !this.isTwoFaRequired
    }

    get token(): string {
        return this._token
    }

    get isTwoFaRequired(): boolean {
        return this._isTwoFaRequired;
    }
}

export default AuthToken
