import moment from 'moment'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import Button from '../../../../components/UI/Form/Elements/Button/Button'
import Timer from '../../../../components/UI/Timer/Timer'
import SecurityLayout from '../../../../hoc/SecurityLayout/SecurityLayout'
import {
    ACCOUNT_RECOVERY_RESEND_NAME,
    AccountRecoveryResponse
} from '../../../../store/actions/Security/AccountRecovery/accountRecoveryRequest'

const AccountRecoveryDone: React.FC = () => {
    const resendDataString: string | null = localStorage.getItem(ACCOUNT_RECOVERY_RESEND_NAME) || null
    const resendData: AccountRecoveryResponse | null = resendDataString ? JSON.parse(resendDataString) : null

    const expiresInTimestamp: number = resendData?.resend_available_at || moment().unix()
    const expiresIn = moment.unix(expiresInTimestamp).diff(moment(), 's')

    const [resendAvailable, setResendAvailable] = useState<boolean>(expiresIn <= 0)

    const {t} = useTranslation()

    const resendEmailHandler = () => {
        console.log('@todo: resend email')
    }

    return (
        <SecurityLayout>
            <>
                <div className="login-page__body">
                    <h1 className="login-page__title login-page__title--sm-mb-20">
                        {t('security:titles.check_your_email')}
                    </h1>
                    <div className="login-page__descr">
                        <p>
                            {t('security:texts.we_send_recovery_removed_email')}
                        </p>
                        <p>
                            {t('security:texts.check_spam')}
                        </p>
                    </div>
                    <div className="login-info">
                        <p>
                            {t('security:texts.did_not_receive_email')}
                        </p>
                        {!resendAvailable
                            ? (
                                <>
                                    <p>
                                        {t('security:texts.send_next_in')}
                                    </p>
                                    <Timer
                                        expiresIn={expiresIn}
                                        callWhenExpired={() => setResendAvailable(true)}
                                    />
                                </>
                            )
                            :
                            <div className="form__buttons form__buttons--center">
                                <Button
                                    disabled={false}
                                    onClick={resendEmailHandler}
                                    className="button form__button form__button--auto"
                                >
                                    {t('security:buttons.send_again')}
                                </Button>
                            </div>
                        }
                    </div>
                </div>
                <div className="login-page__bottom"/>
            </>
        </SecurityLayout>
    )
}

export default connect(null, null)(AccountRecoveryDone)
