import {IToken} from './Interfaces'

export type IndexTokenDataObject = {
    id: string,
    name: string,
    base_price: number,
    current_price: number,
    available: string,
    rest: string,
    emission: string,
    painting_id: string,
}

class IndexToken implements IToken {
    private _id: string
    private _name: string
    private _icon: string
    private _icon2x: string
    private _basePrice: number
    private _currentPrice: number
    private _available: string
    private _rest: string
    private _emission: string
    private _predictionGraph: {[key: string]: string}
    private _paintingId: string

    constructor(data: IndexTokenDataObject) {
        this._id = data.id
        this._name = data.name
        this._icon = ''
        this._icon2x = ''
        this._basePrice = data.base_price
        this._currentPrice = data.current_price
        this._available = data.available
        this._rest = data.rest
        this._emission = data.emission
        this._predictionGraph = {}
        this._paintingId = data.painting_id
    }

    get id(): string {
        return this._id
    }

    get name(): string {
        return this._name
    }

    get icon(): string {
        return this._icon
    }

    get icon2x(): string {
        return this._icon2x
    }

    get basePrice(): number {
        return this._basePrice
    }

    get currentPrice(): number {
        return this._currentPrice
    }

    get available(): string {
        return this._available
    }

    get rest(): string {
        return this._rest
    }

    get emission(): string {
        return this._emission
    }

    get predictionGraph(): {[key: string]: string} {
        return this._predictionGraph;
    }

    get paintingId(): string {
        return this._paintingId
    }

    toDataObject = (): IndexTokenDataObject => {
        return {
            id: this._id,
            name: this._name,
            base_price: this._basePrice,
            current_price: this._currentPrice,
            available: this._available,
            rest: this._rest,
            emission: this._emission,
            painting_id: this._paintingId,
        }
    }
}

export default IndexToken
