import React from 'react'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {IPainting, IToken} from '../../../model/Interfaces'
import TokensListItemDesktop from '../TokensListItemDesktop/TokensListItemDesktop'
import SmallInfoIcon from '../../UI/Icons/SmallInfoIcon/SmallInfoIcon'
import {DEPOSIT_PATH} from '../../../config/routing/paths'
import {Link} from 'react-router-dom'
import {FeatureFlagListType} from '../../../model/Types'
import {FeatureFlagName} from '../../../model/Enums'

interface IProps {
    isAuthenticated: boolean,
    featureFlagsLoaded: boolean,
    featureFlags: FeatureFlagListType,
    indexToken: IToken,
    artTokens: IToken[],
    paintings: {[key: string]: IPainting},
    subscribe: (paintingId: string) => void
}

const TokensListDesktop: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()

    return (
        <table className="table table--grey tokens-page__table tokens-page__table--desktop">
            <thead>
                <tr>
                    <th>
                        {t('common:col_name')}
                    </th>
                    <th>
                        {t('common:col_capitalization')}
                    </th>
                    <th>
                        {t('common:col_unavailable')}
                    </th>
                    <th>
                        {t('common:col_available')}
                    </th>
                    <th>
                        {t('common:col_price')}
                    </th>
                    <th>{t('common:col_price_chart')}</th>
                    <th>
                        {
                            props.featureFlagsLoaded
                            && props.featureFlags[FeatureFlagName.DEPOSIT]
                            ?
                                <div className="table__info-links">
                                    <div className="info-link">
                                        <Link
                                            to={DEPOSIT_PATH}
                                            className="info-link__link"
                                        >
                                            {t('common:buttons.deposit')}
                                        </Link>
                                        <SmallInfoIcon/>
                                    </div>
                                </div>
                            : null
                        }
                    </th>
                </tr>
            </thead>
            <tbody>
                <TokensListItemDesktop
                    token={props.indexToken}
                    subscribe={props.subscribe}
                />
                {
                    props.artTokens.map((artToken: IToken, i: number) => {

                        return (
                            <TokensListItemDesktop
                                key={'art-token-desktop-' + i}
                                token={artToken}
                                painting={props.paintings[artToken.paintingId]}
                                subscribe={props.subscribe}
                            />
                        )
                    })
                }
            </tbody>
        </table>

    )
}

function mapStateToProps(state) {
    return {
        featureFlagsLoaded: state.featureFlagReducer.featureFlagsLoaded,
        featureFlags: state.featureFlagReducer.featureFlags,
    }
}

export default connect(mapStateToProps)(TokensListDesktop)
