import React from 'react'
import sprite from '../../../../styles/img/sprite.svg'

const SearchIcon: React.FC = () => (
    <svg className="search__button-icon" width="12" height="12">
        <use xlinkHref={`${sprite}#search`}/>
    </svg>
)

export default SearchIcon
