import {API_ACCOUNT_BALANCE} from '../../axios/paths'
import AccountBalance, {AccountBalanceDataObject} from '../../model/AccountBalance'
import ApiClient from '../../services/ApiClient'
import {BALANCE_LOADED} from './balanceTypes'

export const loadBalance = () => {
    return async (dispatch): Promise<void> => {
        const apiClient = ApiClient()

        const response = await apiClient.get<AccountBalanceDataObject>(API_ACCOUNT_BALANCE)
        const accountBalance = new AccountBalance(response.data)

        dispatch(balanceLoaded(accountBalance))
    }
}

export const balanceLoaded = (accountBalance: AccountBalance) => {
    return {
        type: BALANCE_LOADED,
        payload: accountBalance
    }
}
