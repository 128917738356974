import React from 'react'

const SlideLeftBigIcon: React.FC = () => (
    <svg
        className="main-work-slider-nav__icon"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15 19l-7-7 7-7"
        />
    </svg>
)

export default SlideLeftBigIcon
