import Tokens from '../../model/Tokens'
import {DESTROY_DATA, RENEW_DATA} from '../actions/actionTypes'
import {TOKENS_LOADED} from '../actions/tokenTypes'

interface IState {
    tokensLoaded: boolean,
    tokens: Tokens | null,
    needRenew: boolean,
}

const initialState: IState = {
    tokensLoaded: false,
    tokens: null,
    needRenew: false,
}

const tokenReducer = (
    state: IState = initialState,
    action: {type: string, payload?: Tokens}
): IState => {
    switch (action.type) {
        case TOKENS_LOADED:
            return {
                ...state,
                tokensLoaded: true,
                tokens: action.payload || null,
                needRenew: false,
            }
        case RENEW_DATA:
            return {
                ...state,
                needRenew: true,
            }
        case DESTROY_DATA:
            return {
                ...initialState,
            }
        default:
            return state
    }
}


export default tokenReducer
