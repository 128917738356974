import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import ModalWindow from '../../../hoc/ModalWindow/ModalWindow'
import {deactivateTwoFactor} from '../../../store/actions/Security/TwoFactorSetup/twoFactorSetup'
import {connect} from 'react-redux'
import TwoFactorCode from '../../UI/Form/Elements/TwoFactorCode/TwoFactorCode'
import {CodeType} from '../../../model/Types'

interface IProps {
    visible: boolean,
    close: () => void,
}

interface IDispatchProps {
    deactivateTwoFactor: (
        code: string,
        onSuccess: () => void,
        onError: () => void
    ) => void,
}

type Props = IProps & IDispatchProps

const TwoFactorDeactivateSetupModal: React.FC<Props> = (props: Props) => {
    const {t} = useTranslation()

    const [code, setCode] = useState<CodeType>((new Array(6)).fill(null))
    const [hasError, setHasError] = useState<boolean>(false)
    const [inProgress, setInProgress] = useState<boolean>(false)

    inProgress && hasError && setInProgress(false)

    const changeHandler = (code: CodeType) => {
        setCode(code)
    }

    const reset = () => {
        setCode((new Array(6)).fill(null))
        setHasError(false)
        setInProgress(false)
    }

    const onSuccess = () => {
        reset()
        props.close()
    }

    const onError = () => {
        setHasError(true)
        setInProgress(false)
    }

    const submitCodeHandler = (event: React.FormEvent) => {
        event.preventDefault()
        if (inProgress) {
            return
        }

        setInProgress(true)
        props.deactivateTwoFactor(code.join(''), onSuccess, onError)
    }

    const onCloseHandler = () => {
        reset()
        props.close()
    }

    return (
        <ModalWindow
            visible={props.visible}
            close={onCloseHandler}
        >
            <div className="popup__body">
                <div className="popup__text popup__text--left">
                    <h2>
                        {t('settings:titles.tfa_deactivation')}
                    </h2>
                    <p>
                        {t('settings:texts.enter_tfa_6_digit_code')}
                    </p>
                </div>
                <TwoFactorCode
                    code={code}
                    onChange={changeHandler}
                    error={hasError}
                />
                <div className="form popup__form">
                    <div className="form__buttons form__buttons--center">
                        <button
                            className="button form__button"
                            type="button"
                            onClick={submitCodeHandler}
                        >
                            {t('settings:buttons.confirm')}
                        </button>
                    </div>
                </div>
            </div>
        </ModalWindow>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        deactivateTwoFactor: (
            code: string,
            onSuccess: () => void,
            onError: () => void
        ) => dispatch(deactivateTwoFactor(code, onSuccess, onError)),
    }
}

export default connect(null, mapDispatchToProps)(TwoFactorDeactivateSetupModal)
