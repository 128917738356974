import React from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import MainLayout from '../../hoc/MainLayout/MainLayout'
import FaqItem, {FaqItemType} from '../../components/Support/FaqItem/FaqItem'

const faqs: FaqItemType[] = [{
    title: 'common:faq.question_first',
    text: 'common:faq.answer_first'
}, {
    title: 'common:faq.question_second',
    text: 'common:faq.answer_second'
}, {
    title: 'common:faq.question_third',
    text: 'common:faq.answer_third'
}, {
    title: 'common:faq.question_fourth',
    text: 'common:faq.answer_fourth'
}, {
    title: 'common:faq.question_fifth',
    text: 'common:faq.answer_fifth'
}, {
    title: 'common:faq.question_sixth',
    text: 'common:faq.answer_sixth'
}]

const FaqPage: React.FC = () => {
    const {t} = useTranslation()

    return (
        <MainLayout>
            <main className="faq-page">
                <div className="faq-page__inner">
                    <h1 className="faq-page__title">
                        {t('common:titles.faq')}
                    </h1>
                    <div className="faq-page__blocks">
                        {faqs.map((faqItem: FaqItemType, i: number) => {
                            return (
                                <FaqItem item={faqItem} key={`faq-item-${i}`}/>
                            )
                        })}
                    </div>
                </div>
            </main>
        </MainLayout>
    )
}

function mapStateToProps(state) {
    return {
        account: state.authReducer.account,
        isAuthenticated: state.authReducer.authToken.isFullyAuthenticated(),
    }
}

export default connect(mapStateToProps)(FaqPage)
