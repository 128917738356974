import React from 'react'
import ModalWindow from '../../../hoc/ModalWindow/ModalWindow'

interface IProps {
    visible: boolean,
    close: () => void,
    content: string,
}

const SuccessModal: React.FC<IProps> = (props: IProps) => {
    const {content, visible, close} = props

    return (
        <ModalWindow
            visible={visible}
            close={close}
        >
            <div className="system-popup__body">
                <h2 className="system-popup__title">
                    {content}
                    <svg
                        className="system-popup__icon system-popup__icon--green"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                        />
                    </svg>
                </h2>
                <div className="system-popup__buttons">
                    <button
                        className="button button--bordered system-popup__button"
                        type="button"
                        onClick={close}
                    >
                        Ok
                    </button>
                </div>
            </div>
        </ModalWindow>
    )
}

export default SuccessModal
