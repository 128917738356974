import React from 'react'
import {useTranslation} from 'react-i18next'
import * as cs from 'classnames'

interface RoadItem {
    title: string,
    date: string,
    passed: boolean,
}

const items: RoadItem[] = [{
    title: 'landing:titles.road_map_first',
    date: 'landing:texts.road_map_first',
    passed: true,
}, {
    title: 'landing:titles.road_map_second',
    date: 'landing:texts.road_map_second',
    passed: true,
}, {
    title: 'landing:titles.road_map_third',
    date: 'landing:texts.road_map_third',
    passed: false,
}, {
    title: 'landing:titles.road_map_fourth',
    date: 'landing:texts.road_map_fourth',
    passed: false,
}, {
    title: 'landing:titles.road_map_fifth',
    date: 'landing:texts.road_map_fifth',
    passed: false,
}, {
    title: 'landing:titles.road_map_sixth',
    date: 'landing:texts.road_map_sixth',
    passed: false,
}]

const Road: React.FC = () => {
    const {t} = useTranslation()

    const renderItems = () => items.map((item: RoadItem, i: number) => (
        <div className="col-lg-2 road-line__col" key={`key-` + i}>
            <div className="road-line-item">
                <div
                    className={cs(
                        'road-line-item__icon',
                        {'road-line-item__icon--checked': item.passed}
                    )} />
                <div className="road-line-item__descr">
                    <div className="road-line-item__date">
                        {t(item.date)}
                    </div>
                    <div className="road-line-item__title">
                        {t(item.title)}
                    </div>
                </div>
            </div>
        </div>
    ))

    return (
        <section className="map-section" id="road_map">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="section-title map-section__title">
                            {t('landing:titles.road_map')}
                        </h2>
                        <div className="road-line">
                            <div className="row road-line__row">
                                { renderItems() }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Road
