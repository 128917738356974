import * as cs from 'classnames'
import React, {MutableRefObject, useRef, useState} from 'react'
import {FieldError} from 'react-hook-form'

interface IProps {
    label: string,
    error: FieldError | undefined,
    blockClasses?: [string],
    onChange?: (event: MouseEvent) => void,
    onBlur?: (event: MouseEvent) => void,
    value?: string,
}

type Ref = ((instance: HTMLDivElement | null) => void) | MutableRefObject<HTMLDivElement | null> | null

const Textarea = React.forwardRef<HTMLDivElement, IProps>((
    props: IProps, ref: Ref
) => {
    const [active, setActive] = useState<boolean>(false)

    const htmlFor = `text-${Math.random()}`
    const labelRef = useRef<HTMLLabelElement>(null)
    const counterRef = useRef<HTMLDivElement>(null)

    const handleAutoFill = e => e.animationName === 'onAutoFillStart' && !active && setActive(true)

    const blockClasses = cs(props.blockClasses, 'form__group', {'has-error': !!props.error})
    const label: string = props.error?.message || props.label || ''

    const onChangeHandler = event => {
        props.onChange && props.onChange(event)
    }

    let value = props.value || ''
    if (counterRef.current !== null) {
        const countLetters = 400
        value = value.substr(0, countLetters)
        const resultLength = countLetters - value.length
        counterRef.current.innerHTML = resultLength.toString().substring(0, countLetters)
    }

    return (
        <div className={blockClasses} ref={ref}>
            <label
                htmlFor={htmlFor}
                ref={labelRef}
                className="form__label"
            >
                {label}
            </label>
            <textarea
                className="form__input"
                id={htmlFor}
                onChange={onChangeHandler}
                rows={3}
                value={value}
                onAnimationStart={handleAutoFill}
                autoComplete="chrome-off"
            />
            <div className="form__textarea-length" ref={counterRef}>400</div>
        </div>
    )
})

export default Textarea
