import {API_TOKENS} from '../../axios/paths'
import Tokens, {TokensDataObject} from '../../model/Tokens'
import ApiClient from '../../services/ApiClient'
import {TOKENS_LOADED} from './tokenTypes'

export const loadTokens = () => {
    return async (dispatch): Promise<void> => {
        const apiClient = ApiClient()

        const response = await apiClient.get<TokensDataObject>(API_TOKENS)
        const tokens = new Tokens(response.data)

        dispatch(tokensLoaded(tokens))
    }
}

export const tokensLoaded = (tokens: Tokens) => {
    return {
        type: TOKENS_LOADED,
        payload: tokens
    }
}
