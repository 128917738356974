import {Dispatch} from 'redux'
import {API_ACCOUNT_2FA_CHANGE_STATUS, API_ACCOUNT_2FA_SECRETS} from '../../../../axios/paths'
import TwoFactorSecrets, {TwoFactorSecretsDataObject} from '../../../../model/TwoFactorSecrets'
import ApiClient from '../../../../services/ApiClient'
import {reloadAccountData} from '../Auth/auth'
import {
    TWO_FACTOR_SECRETS_LOADED,
    TWO_FACTOR_SECRETS_DESTROY,
} from './actionTypes'

export const activateTwoFactorCode = (
    code: string,
    onSuccess: () => void,
    onError: () => void
) => async (dispatch: Dispatch<any>): Promise<void> => {
    if (!isCodeValid(code)) {
        return
    }

    const apiClient = ApiClient((error): boolean => {
        return false
    })

    try {
        await apiClient.post(API_ACCOUNT_2FA_CHANGE_STATUS, {code, enable: true})
        await dispatch(destroyTwoFactorSecrets())
        await dispatch(reloadAccountData())
        onSuccess()
    } catch (e) {
        console.log(e)
        onError()
    }
}

export const deactivateTwoFactor = (
    code: string,
    onSuccess: () => void,
    onError: () => void
) => async (dispatch: Dispatch<any>): Promise<void> => {
    debugger
    if (!isCodeValid(code)) {
        return
    }

    const apiClient = ApiClient((error): boolean => {
        return false
    })

    try {
        await apiClient.post(API_ACCOUNT_2FA_CHANGE_STATUS, {code, enable: false})
        await dispatch(destroyTwoFactorSecrets())
        await dispatch(reloadAccountData())
        onSuccess()
    } catch (e) {
        console.log(e)
        onError()
    }
}

export const loadSecrets = () => async (dispatch: Dispatch<any>): Promise<void> => {
    const apiClient = ApiClient()

    try {
        const response = await apiClient.get<TwoFactorSecretsDataObject>(API_ACCOUNT_2FA_SECRETS)
        const twoFactorSecrets = new TwoFactorSecrets(response.data)

        dispatch(twoFactorSecretsLoaded(twoFactorSecrets))
    } catch (e) {
        console.log(e)
    }
}

export const destroyTwoFactorSecrets = () => {
    return {
        type: TWO_FACTOR_SECRETS_DESTROY,
    }
}

const twoFactorSecretsLoaded = (twoFactorSecrets: TwoFactorSecrets | null) => {
    return {
        type: TWO_FACTOR_SECRETS_LOADED,
        payload: twoFactorSecrets
    }
}

const isCodeValid = (code: string): boolean => {
    return !!(code && code.length === 6);
}
