import React from 'react'
import {useTranslation} from 'react-i18next'
import BasketItem from '../../../model/BasketItem'
import Select, {SelectOption} from '../../UI/Form/Elements/Select/Select'
import CloseRoundedIcon from '../../UI/Icons/CloseRoundedIcon/CloseRoundedIcon'
import tokensImg from '../../../styles/img/token-img-1.png'
import tokensImg_2x from '../../../styles/img/token-img-1@2x.png'
import {IToken} from '../../../model/Interfaces'


interface IProps {
    tokens: {[key: string]: IToken},
    unavailable: string[],
    item: BasketItem,
    onChange: (item: BasketItem) => void
    onRemove: () => void
}

const BasketToken: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()

    const tokenOnChangeHandler = (id: string) => {
        const newBasketItem = new BasketItem(id, props.item.count)
        props.onChange(newBasketItem)
    }

    const validateCount = (value: number): number => {
        const maxValue = parseInt(props.tokens[props.item.id].available)

        return maxValue >= value && value > 0 ? value : maxValue
    }

    const decrementCount = () => {
        const value = validateCount(props.item.count - 1)
        const newBasketItem = new BasketItem(props.item.id, value)
        props.onChange(newBasketItem)
    }

    const incrementCount = () => {
        const value = validateCount(props.item.count + 1)
        const newBasketItem = new BasketItem(props.item.id, value)
        props.onChange(newBasketItem)
    }

    const listTokens:{[key: string]: SelectOption}  = {}
    const listDisabled: string[] = []

    let currentTokenPrice: number | null = null
    let currentTokenName: string | null = null
    let currentTokenAvailable: string | null = null

    Object.keys(props.tokens).map((key: string) => {
        const token: IToken = props.tokens[key]
        listTokens[token.id] = {
            name: token.name,
            icon: token.icon || tokensImg,
            icon2x: token.icon2x || tokensImg_2x,
        }
        if (props.unavailable.includes(token.id)) {
            listDisabled.push(token.id)
        }

        if (props.item.id === token.id) {
            currentTokenPrice = token.currentPrice
            currentTokenName = token.name
            currentTokenAvailable = token.available
        }
    })

    const onCountChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = parseFloat((event.target.value).replace(/[^0-9]/, '') || '1')
        if (value < 1) {
            value = 1
        }

        const currentTokenAvailableValue = parseFloat(currentTokenAvailable || '')
        if (value > currentTokenAvailableValue) {
            event.preventDefault()
            return
        }

        const newBasketItem = new BasketItem(props.item.id, value)
        props.onChange(newBasketItem)
    }

    return (
        <>
            <div className="row row--sm-m-10">
                <div className="col-4 col-sm-4 col--sm-p-10">
                    <div className="form__group form__group--mb-10">
                        <Select
                            options={listTokens}
                            onChange={tokenOnChangeHandler}
                            selected={props.item.id}
                            disabled={listDisabled}
                        />
                    </div>
                </div>
                <div className="col-4 col-sm-5 col--sm-p-10">
                    <div className="form__group form__group--mb-10">
                        <div className="quantity">
                            <button
                                className="quantity__minus"
                                type="button"
                                onClick={decrementCount}
                            >-</button>
                            <input
                                className="quantity__input"
                                type="text"
                                value={props.item.count}
                                onChange={onCountChangeHandler}
                            />
                            <button
                                className="quantity__plus"
                                type="button"
                                onClick={incrementCount}
                            >+</button>
                        </div>
                    </div>
                </div>
                <div className="col-4 col-sm-3 col--sm-p-10">
                    <div className="form__group form__group--mb-10 form__group--with-delete">
                        <input
                            className="form__input form__input--clear form__input--right"
                            type="text"
                            name="inputTotal"
                            placeholder=""
                            value={'$ ' + (props.item.count * (currentTokenPrice || 0)).toLocaleString()}
                            readOnly
                        />
                        <button className="form__delete" type="button" onClick={props.onRemove}>
                            <CloseRoundedIcon/>
                        </button>
                    </div>
                </div>
            </div>

            <div className="row row--sm-m-10">
                <div className="col-4 col-sm-4 col--sm-p-10">
                    <div className="form__group">
                        <ul className="form__list">
                            <li>
                                1 {currentTokenName} = $ {(currentTokenPrice || 0).toLocaleString()}
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-8 col-sm-5 col--sm-p-10">
                    <div className="form__group">
                        <ul className="form__list form__list--center">
                            <li>
                                {t('common:Available')}: {
                                    parseFloat(currentTokenAvailable || '0').toLocaleString()
                                } {currentTokenName}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </>
    )
}

export default BasketToken
