import moment from 'moment'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import Button from '../../../../components/UI/Form/Elements/Button/Button'
import Timer from '../../../../components/UI/Timer/Timer'
import {AUTH_PATH} from '../../../../config/routing/paths'
import SecurityLayout from '../../../../hoc/SecurityLayout/SecurityLayout'
import {googleAuth} from '../../../../store/actions/Security/Auth/auth'
import {
    RegisterUserResponse,
    REGISTRATION_RESEND_NAME
} from '../../../../store/actions/Security/Registration/registerUser'

const RegistrationDone: React.FC = () => {
    const resendDataString: string | null = localStorage.getItem(REGISTRATION_RESEND_NAME) || null
    const resendData: RegisterUserResponse | null = resendDataString ? JSON.parse(resendDataString) : null

    const resendToken: string | null = resendData?.resend_token || null
    const expiresInTimestamp: number = resendData?.resend_available_at || moment().unix()
    const expiresIn = moment.unix(expiresInTimestamp).diff(moment(), 's')

    const [resendAvailable, setResendAvailable] = useState<boolean>(expiresIn <= 0 && !!resendToken)

    const {t} = useTranslation()

    const resendEmailHandler = () => {
        console.log('@todo: resend email')
    }

    return (
        <SecurityLayout>
            <>
                <div className="login-page__body">
                    <h1 className="login-page__title login-page__title--sm-mb-20">
                        {t('security:titles.almost_done')}
                    </h1>
                    <div className="login-page__descr">
                        <p>
                            {t('security:texts.we_send_confirm_email')}
                        </p>
                        <p>
                            {t('security:texts.check_spam')}
                        </p>
                    </div>
                    <div className="login-info">
                        <p>
                            {t('security:texts.did_not_receive_email')}
                        </p>
                        {!resendAvailable
                            ? (resendToken
                                    ?
                                    <>
                                        <p>
                                            {t('security:texts.send_next_in')}
                                        </p>
                                        <Timer
                                            expiresIn={expiresIn}
                                            callWhenExpired={() => setResendAvailable(true)}
                                        />
                                    </>
                                    :
                                    <p>
                                        {t('security:texts.sing_in_to_resend')}
                                    </p>
                            )
                            :
                            <div className="form__buttons form__buttons--center">
                                <Button
                                    disabled={false}
                                    onClick={resendEmailHandler}
                                    className="button form__button form__button--auto"
                                >
                                    {t('security:buttons.send_again')}
                                </Button>
                            </div>
                        }
                    </div>
                </div>
                <div className="login-page__bottom">
                    <div className="login-info">
                        <Link className="login-info__link" to={AUTH_PATH}>
                            {t('security:buttons.sign_in')}
                        </Link>
                    </div>
                </div>
            </>
        </SecurityLayout>
    )
}


function mapDispatchToProps(dispatch) {
    return {
        googleAuth: (code: string) => dispatch(googleAuth(code)),
    }
}

export default connect(null, mapDispatchToProps)(RegistrationDone)
