import React from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import PayWithCrypto from '../../components/Operation/PayWithCrypto/PayWithCrypto'
import PreLoader from '../../components/UI/PreLoader/PreLoader'
import Tokens from '../../model/Tokens'
import {IOperation} from '../../model/Interfaces'
import MainLayout from '../../hoc/MainLayout/MainLayout'

interface IProps {
    operationsLoaded: boolean,
    operations: [],
    tokensLoaded: boolean,
    tokens: Tokens | null,
}

const OperationPage: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()

    const {uuid}: {uuid: string} = useParams()

    const operation:IOperation | undefined = (props.operations.find((operationItem: IOperation) => {
        return operationItem.id === uuid
    }))

    if (!props.operationsLoaded || !props.tokensLoaded || !operation || !props.tokens) {
        return (
            <MainLayout>
                <main className="transactions-page">
                    <div className="transactions-page__inner">
                        <div className="transactions-page__header">
                            <h1 className="transactions-page__title">{t('common:titles.operations')}</h1>
                            <PreLoader/>
                        </div>
                    </div>
                </main>
            </MainLayout>
        )
    }

    return (
        <MainLayout>
            <main className="operations-page">
                <PayWithCrypto
                    operation={operation}
                    mappedTokens={props.tokens.mappedTokens()}
                />
            </main>
        </MainLayout>
    )
}

function mapStateToProps(state) {
    return {
        tokensLoaded: state.tokenReducer.tokensLoaded,
        tokens: state.tokenReducer.tokens,
        operationsLoaded: state.operationReducer.operationsLoaded,
        operations: state.operationReducer.operations
    }
}

export default connect(mapStateToProps)(OperationPage)
