import {Partner} from '../../containers/PartnerPage/PartnerPage'
import {DESTROY_DATA} from '../actions/actionTypes'
import {PARTNERS_LOADED} from '../actions/partnerTypes'

interface IOverlayState {
    partnersLoaded: boolean,
    partners: {[key: string]: Partner},
}

const initialState: IOverlayState = {
    partnersLoaded: false,
    partners: {},
}

const partnerReducer = (
    state: IOverlayState = initialState,
    action: {type: string, payload?: any}
): IOverlayState => {
    switch (action.type) {
        case PARTNERS_LOADED:
            return {
                ...state,
                partnersLoaded: true,
                partners: action.payload || {},
            }
        case DESTROY_DATA:
            return {
                ...initialState,
            }
        default:
            return state
    }
}

export default partnerReducer
