import PropTypes from 'prop-types'
import React from 'react'
import loginImg1 from '../../styles/img/login-img-1.jpg'
import loginImg1_2x from '../../styles/img/login-img-1@2x.jpg'
import loginImg2 from '../../styles/img/login-img-2.jpg'
import loginImg2_2x from '../../styles/img/login-img-2@2x.jpg'
import Logo from '../../components/UI/Logo/Logo'

interface IProps {
    children: React.ReactNode
}

const SecurityLayout: React.FC<IProps> = ({children}) => {
    return (
        <main className="login-page">
            <picture className="login-page__picture">
                <img
                    className="login-page__img"
                    src={loginImg1}
                    srcSet={loginImg1_2x + ' 2x'}
                    alt=""
                />
            </picture>
            <div className="login-page__content">
                <div className="logo login-page__logo">
                    <Logo />
                </div>
                {children}
            </div>
            <picture className="login-page__picture">
                <img
                    className="login-page__img"
                    src={loginImg2}
                    srcSet={loginImg2_2x + ' 2x'}
                    alt=""
                />
            </picture>
        </main>
    )
}

SecurityLayout.propTypes = {
    children: PropTypes.element.isRequired
}

export default SecurityLayout
