import React from 'react'
import icon from '../../../../styles/img/sprite.svg'

interface IProps {
    className?: string,
}

const CartIcon: React.FC<IProps> = (props: IProps) => (
    <svg className={`${props.className || ''}`} width="17" height="18">
        <use xlinkHref={`${icon}#buy-cart`}/>
    </svg>
)

export default CartIcon
