import React, {useState} from 'react'
import * as cs from 'classnames'
import {useTranslation} from 'react-i18next'
import {CSSTransition} from 'react-transition-group'
import './FaqItem.sass'

export type FaqItemType = {
    title: string,
    text: string
}

interface IProps {
    item: FaqItemType
}

const FaqItem: React.FC<IProps> = (props: IProps) => {
    const [isActive, setIsActive] = useState<boolean>(false)

    const {t} = useTranslation()

    const onToggleClick = () => {
        setIsActive(!isActive)
    }

    return (
        <div className={cs('main-faq-block', {'on': isActive})}>
            <div className="main-faq-block__toggle" onClick={onToggleClick}>
                <h3 className="main-faq-block__title">
                    {t(props.item.title)}
                </h3>
                <div className="main-faq-block__icon"/>
            </div>
            <CSSTransition
                in={isActive}
                timeout={500}
                mountOnEnter
                unmountOnExit
            >
                <div className="main-faq-block__hidden visible">
                    <div className="main-faq-block__descr">
                        <p dangerouslySetInnerHTML={{ __html: t(props.item.text) }}/>
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
}

export default FaqItem
