import queryString, {ParsedQuery} from 'query-string'
import axios from '../../../../axios/axios-backend'
import {API_GET_REFERRAL} from '../../../../axios/paths'
import history from '../../../../utils/history'
import {ActionType} from '../../../reducers/Security/Registration/registrationReferralReducer'
import {REFERRAL_USER_DATA_LOADED} from '../../actionTypes'

type GetReferralResponse = {
    email: string
}

export const REFERRAL_STORAGE_NAME = 'referral'

export const checkReferralUserData = () => async dispatch => {
    const searchParams: ParsedQuery = queryString.parse(history.location.search)
    const referralToken: string | null = searchParams.referral?.toString() || null

    if (!referralToken) {
        dispatch(loadReferralUser())

        return
    }

    try {
        const {data}: {data: GetReferralResponse} = await axios.get(API_GET_REFERRAL(referralToken))
        localStorage.setItem(REFERRAL_STORAGE_NAME, JSON.stringify({
            email: data.email,
            token: referralToken
        }))
    } catch (e) {
        console.log(e)
    }

    dispatch(loadReferralUser())
}

export const loadReferralUser = () => async dispatch => {
    const jsonData = localStorage.getItem(REFERRAL_STORAGE_NAME) || null
    if (!jsonData) {
        dispatch(noReferral())
        return
    }

    const referral = jsonData ? JSON.parse(jsonData) : null
    if (!referral) {
        dispatch(noReferral())
        return
    }

    dispatch(userDataLoaded(referral))
}

export const userDataLoaded = (referral): ActionType => {
    return {
        type: REFERRAL_USER_DATA_LOADED,
        payload: {...referral}
    }
}

export const noReferral = (): ActionType => {
    return {
        type: REFERRAL_USER_DATA_LOADED,
        payload: null
    }
}
