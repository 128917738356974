import React from 'react'
import {useTranslation} from 'react-i18next'
import {matchPath, Link, useLocation} from 'react-router-dom'
import {
    GALLERY_PATH,
    MAIN_PATH,
    OPERATIONS_PATH,
    TOKENS_PATH,
    WALLET_PATH
} from '../../../config/routing/paths'
import * as cs from 'classnames'
import {connect} from 'react-redux'
import {landingMenuSwitchVisibility} from '../../../store/actions/overlay'
import history from '../../../utils/history'
import {updateFilter} from '../../../store/actions/gallery'
import {IFilterState} from '../../../model/Interfaces'
import MenuItemType from '../../../model/Enums/MenuItemType'

const publicMenuItems = [{
    path: GALLERY_PATH,
    type: MenuItemType.ACTION,
    label: 'common:menu.buy_a_painting',
},{
    path: GALLERY_PATH,
    type: MenuItemType.LINK,
    label: 'common:menu.gallery',
},{
//    path: BUY_A_PAINTING_PATH,
//    anchor: false,
//    label: 'landing:menu.buy_a_painting',
//},{
    path: 'how_it_works',
    type: MenuItemType.ANCHOR,
    label: 'common:menu.how_it_works',
},{
    path: 'about_project',
    type: MenuItemType.ANCHOR,
    label: 'common:menu.about_project',
},{
    path: 'road_map',
    type: MenuItemType.ANCHOR,
    label: 'common:menu.road_map',
},{
    path: 'capabilities',
    type: MenuItemType.ANCHOR,
    label: 'common:menu.capabilities',
}]

const protectedMenuItems = [{
    path: GALLERY_PATH,
    type: MenuItemType.ACTION,
    label: 'common:menu.buy_a_painting',
},{
    path: GALLERY_PATH,
    type: MenuItemType.LINK,
    label: 'common:menu.gallery',
},{
    path: WALLET_PATH,
    type: MenuItemType.LINK,
    label: 'common:menu.wallet',
},{
    path: TOKENS_PATH,
    type: MenuItemType.LINK,
    label: 'common:menu.tokens',
},{
    path: OPERATIONS_PATH,
    type: MenuItemType.LINK,
    label: 'common:menu.operations',
}]

export enum MenuType {
    DESKTOP = 'desktop',
    MOBILE = 'mobile'
}

interface IProps {
    type: MenuType,
    hideMenu: () => void,
    isAuthenticated: boolean,
    updateFilter: (filter: IFilterState) => void,
    filter: IFilterState,
}

const Menu: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()
    const location = useLocation()

    const menuItems = props.isAuthenticated ? protectedMenuItems : publicMenuItems

    return (
        <nav
            className={cs(
                {'menu': props.type == MenuType.DESKTOP},
                {'main-menu': props.type == MenuType.MOBILE},
            )}>
            <ul
                className={cs(
                    {'menu__list': props.type == MenuType.DESKTOP},
                    {'main-menu__list': props.type == MenuType.MOBILE},
                )}>
                {menuItems.map((menuElement, i) => (
                    <li
                        className={cs(
                            {'menu__item': props.type == MenuType.DESKTOP},
                            {'main-menu__item': props.type == MenuType.MOBILE},
                            {'is-active': menuElement.type === MenuItemType.LINK
                                    && !!matchPath(location.pathname, menuElement.path)},
                        )}
                        key={i}
                    >
                        {
                            menuElement.type === MenuItemType.ANCHOR
                            ?
                                <a
                                    className={cs(
                                        {'menu__link': props.type == MenuType.DESKTOP},
                                        {'main-menu__link': props.type == MenuType.MOBILE},
                                    )}
                                    href={`#${menuElement.path}`}
                                    onClick={(event: React.MouseEvent) => {
                                        event.preventDefault()
                                        props.hideMenu()
                                        const elem = document.getElementById(menuElement.path)
                                        if (!elem) {
                                            history.push(`${MAIN_PATH}#${menuElement.path}`)
                                            return
                                        }

                                        const yOffset = 40
                                        const y = elem.getBoundingClientRect().top + window.pageYOffset - yOffset;
                                        window.scrollTo({top: y, behavior: 'smooth'});
                                    }}
                                >
                                    {t(menuElement.label)}
                                </a>
                            : null
                        }
                        {
                            menuElement.type === MenuItemType.ACTION
                            ?
                                <Link
                                    className={cs(
                                        {'menu__link': props.type == MenuType.DESKTOP},
                                        {'main-menu__link': props.type == MenuType.MOBILE},
                                    )}
                                    to={menuElement.path}
                                    onClick={() => {
                                        props.updateFilter({
                                            ...props.filter,
                                            buy_a_whole: true
                                        })
                                        window.scrollTo({top: 0});
                                        props.hideMenu()
                                    }}
                                >
                                    {t(menuElement.label)}
                                </Link>
                            : null
                        }
                        {
                            menuElement.type === MenuItemType.LINK
                            ?
                                <Link
                                    className={cs(
                                        {'menu__link': props.type == MenuType.DESKTOP},
                                        {'main-menu__link': props.type == MenuType.MOBILE},
                                    )}
                                    to={menuElement.path}
                                    onClick={() => {
                                        window.scrollTo({top: 0});
                                        props.hideMenu()
                                    }}
                                >
                                    {t(menuElement.label)}
                                </Link>
                            : null
                        }
                    </li>
                ))}
            </ul>
        </nav>
    )
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.authReducer.authToken.isFullyAuthenticated(),
        filter: state.galleryReducer.filter,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hideMenu: () => dispatch(landingMenuSwitchVisibility(false)),
        updateFilter: (filter: IFilterState) => dispatch(updateFilter(filter)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu)

