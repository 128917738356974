import React from 'react'

const CloseRoundedIcon: React.FC = () => (
    <svg className="upload-avatar__delete-icon" width="12"
         height="12" viewBox="0 0 12 12" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1 1L11 11M1 11L11 1L1 11Z"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default CloseRoundedIcon
