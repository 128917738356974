import React from 'react'
import * as cs from 'classnames'

interface IProps {
    active: boolean,
    name: string,
    icon: string,
    icon2x: string,
    setActive: () => void
}

const CryptoPaymentMethodButton: React.FC<IProps> = (props: IProps) => {
    return (
        <div className="col-6 col--10">
            <button
                className={cs('radio-button radio-button--crypto', {'is-active': props.active})}
                type="button"
                onClick={props.setActive}
            >
                <img
                    className="radio-button__img"
                    src={props.icon}
                    srcSet={props.icon2x + ' 2x'}
                    alt="alt"
                />
                <div className="radio-button__text">
                    <div className="radio-button__title">
                        {props.name}
                    </div>
                </div>
            </button>
        </div>
    )
}

export default CryptoPaymentMethodButton
