import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {BUY_TOKENS_PATH, GALLERY_PATH, TOKENS_PATH} from '../../../config/routing/paths'

const WalletEmpty: React.FC = () => {
    const {t} = useTranslation()

    return (
        <div className="portfolio-empty">
            <svg
                className="portfolio-empty__icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                />
            </svg>
            <div className="portfolio-empty__text">
                <p>
                    {t('common:there_are_no_assets')}
                </p>
            </div>
            <div className="portfolio-empty__buttons">
                <Link
                    className="button portfolio-empty__button"
                    to={GALLERY_PATH}
                >
                    {t('common:buttons.go_to_gallery')}
                </Link>
                <Link
                    className="button button--bordered portfolio-empty__button"
                    to={BUY_TOKENS_PATH}
                >
                    {t('common:buttons.buy_tokens')}
                </Link>
                {/*<a*/}
                {/*    className="button portfolio-empty__button"*/}
                {/*    href="#"*/}
                {/*>*/}
                {/*    <svg*/}
                {/*        className="button__icon"*/}
                {/*        xmlns="http://www.w3.org/2000/svg"*/}
                {/*        fill="none"*/}
                {/*        viewBox="0 0 24 24"*/}
                {/*        stroke="currentColor"*/}
                {/*    >*/}
                {/*        <path*/}
                {/*            strokeLinecap="round"*/}
                {/*            strokeLinejoin="round"*/}
                {/*            strokeWidth="2"*/}
                {/*            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"*/}
                {/*        />*/}
                {/*    </svg>*/}
                {/*    Депозит*/}
                {/*</a>*/}
            </div>
        </div>
    )
}

export default WalletEmpty
