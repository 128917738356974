import queryString, {ParsedQuery} from 'query-string'
import axios from '../../../../axios/axios-backend'
import {API_ACCOUNT_RECOVERY_CONFIRM} from '../../../../axios/paths'
import {DEFAULT_PROTECTED_PATH} from '../../../../config/routing/paths'
import history from '../../../../utils/history'
import {ACCOUNT_RECOVERY_CONFIRM_FAILED, ACCOUNT_RECOVERY_CONFIRM_SUCCESS,} from './actionTypes'

export const checkConfirmationToken = () => async dispatch => {
    const searchParams: ParsedQuery = queryString.parse(history.location.search)
    const token: string | null = searchParams.token?.toString() || null

    if (!token) {
        dispatch(confirmationFailed())

        return
    }

    try {
        await axios.post(API_ACCOUNT_RECOVERY_CONFIRM, {token})
        await dispatch(confirmationSuccess())
    } catch (e) {
        console.log(e)
        dispatch(confirmationFailed())
    }
}

export const confirmationSuccess = () => {
    history.push(DEFAULT_PROTECTED_PATH)

    return {
        type: ACCOUNT_RECOVERY_CONFIRM_SUCCESS
    }
}

export const confirmationFailed = () => {
    return {
        type: ACCOUNT_RECOVERY_CONFIRM_FAILED
    }
}
