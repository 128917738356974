import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {AUTH_PATH, WALLET_PATH} from '../../../config/routing/paths'

interface IProps {
    isAuthenticated: boolean,
}

const Join: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()

    return (
        <section className="join-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="join-section__inner">
                            <h2 className="section-title join-section__title">
                                {t('landing:titles.join_us')}
                            </h2>
                            <div className="join-section__descr">
                                <p>
                                    {t('landing:texts.join_us')}
                                </p>
                            </div>
                            <Link
                                className="main-button"
                                to={props.isAuthenticated ? WALLET_PATH : AUTH_PATH}
                            >
                                {t('landing:buttons.start_investing')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Join
