import WalletType from '../model/Enums/WalletType'

export const API_LOGIN = '/api/login'
export const API_GOOGLE_LOGIN = '/api/login/google-oauth'
export const API_TWO_FA_VERIFY = '/api/verify'
export const API_FEEDBACK = '/api/feedback'

export const API_REGISTER = '/api/register'
export const API_REGISTER_CONFIRM = '/api/register/confirm'

export const API_RESET_PASSWORD_REQUEST = '/api/reset-password/request'
export const API_RESET_PASSWORD_RESET = '/api/reset-password/reset'

export const API_CHANGE_PASSWORD = '/api/account/change-password'

export const API_ACCOUNT_RECOVERY_REQUEST = '/api/account-recovery/request'
export const API_ACCOUNT_RECOVERY_CONFIRM = '/api/account-recovery/confirm'

export const API_REMOVE_ACCOUNT = '/api/account/remove'

export const API_GET_REFERRAL = (token: string): string => `/api/referral/owner/${token}`

export const API_ACCOUNT_SETTINGS = '/api/account'
export const API_ACCOUNT_NOTIFICATION_UPDATE = '/api/account/notification'
export const API_ACCOUNT_BALANCE = '/api/account/balance'
export const API_ACCOUNT_2FA_SECRETS = '/api/account/2fa/secrets'
export const API_ACCOUNT_2FA_CHANGE_STATUS = '/api/account/2fa/change-status'
export const API_ACCOUNT_GET_WALLET = (walletType: WalletType): string => `/api/account/get-wallet/${walletType}`
export const API_ACCOUNT_WITHDRAW = `/api/tokens/withdraw`
export const API_ACCOUNT_SWAP = `/api/tokens/swap`

export const API_GALLERY = '/api/paintings'
export const API_AUTHORS = '/api/authors'
export const API_PARTNERS = '/api/referral/partners'
export const API_TOKENS = '/api/tokens'
export const API_OPERATIONS = '/api/order'
export const API_OPERATION_CANCEL = (id: string): string => `/api/order/${id}/cancel`
export const API_VOCABULARIES = '/api/vocabularies'

export const API_PAINTING = (id: string): string => `/api/paintings/${id}`
export const API_PAINTING_SUBSCRIBE = (id: string): string => `/api/paintings/${id}/subscribe`
export const API_PAINTING_BUY = (id: string): string => `/api/paintings/${id}/buy`


export const API_SYSTEM_FEATURE_FLAGS_STATE = '/api/system/feature-flags/state'
