import {
    FILTER_VISIBLE,
    LANDING_MENU_VISIBLE,
    MODALS_VISIBLE,
    NOTICES_VISIBLE,
    OVERLAYS_INVISIBLE,
    SIDEBAR_VISIBLE
} from './overlayTypes'

export const hideOverlay = () => {
    return {
        type: OVERLAYS_INVISIBLE,
    }
}

export const sidebarSwitchVisibility = (visible: boolean) => {
    if (!visible) {
        return hideOverlay()
    }

    return {
        type: SIDEBAR_VISIBLE,
    }
}

export const noticesSwitchVisibility = (visible: boolean) => {
    if (!visible) {
        return hideOverlay()
    }

    return {
        type: NOTICES_VISIBLE,
    }
}

export const modalsSwitchVisibility = (visible: boolean) => {
    if (!visible) {
        return hideOverlay()
    }

    return {
        type: MODALS_VISIBLE,
    }
}

export const landingMenuSwitchVisibility = (visible: boolean) => {
    if (!visible) {
        document.querySelector('.index-body')?.classList.remove('scroll-disabled')
        document.querySelector('.main-header')?.classList.remove('is-white')

        return hideOverlay()
    }

    document.querySelector('.index-body')?.classList.add('scroll-disabled')
    document.querySelector('.main-header')?.classList.add('is-white')

    return {
        type: LANDING_MENU_VISIBLE,
    }
}

export const filterSwitchVisibility = (visible: boolean) => {
    if (!visible) {
        return hideOverlay()
    }

    return {
        type: FILTER_VISIBLE,
    }
}
