import TwoFactorSecrets from '../../../../model/TwoFactorSecrets'
import {
    TWO_FACTOR_SECRETS_LOADED,
    TWO_FACTOR_SECRETS_DESTROY,
} from '../../../actions/Security/TwoFactorSetup/actionTypes'

export type IState = {
    twoFactorSecrets: TwoFactorSecrets | null,
}

const initialState: IState = {
    twoFactorSecrets: null,
}

const twoFactorSetupReducer = (
    state: IState = initialState,
    action: {type: string, payload?: TwoFactorSecrets}
): IState => {
    switch (action.type) {
        case TWO_FACTOR_SECRETS_LOADED:
            return {
                ...state,
                twoFactorSecrets: action.payload || state.twoFactorSecrets,
            }
        case TWO_FACTOR_SECRETS_DESTROY:
            return {
                ...state,
                twoFactorSecrets: null,
            }
        default:
            return state
    }
}

export default twoFactorSetupReducer
