import moment from 'moment'
import React, {useEffect, useState} from 'react'

interface IProps {
    expiresIn: number,
    callWhenExpired?: () => void
}

const Timer: React.FC<IProps> = (props: IProps) => {
    const [timer, setTimer] = useState<number>(props.expiresIn)
    const clearTimer = (): void => {
        localStorage.removeItem('timer')
    }

    const isExpired = timer <= 0

    if (isExpired) {
        clearTimer()
        props.callWhenExpired && props.callWhenExpired()
    }

    useEffect(() => {
        const timeout = setTimeout(function () {
            setTimer(prev => {
                return prev - 1
            })
        }, 1000)

        return () => {
            clearTimeout(timeout)
        }
    }, [timer])

    const getRestTimeString = () => {
        if (isExpired) {
            return ''
        }

        const format = timer >= 3600 ? 'HH:mm:ss' : 'mm:ss'
        const restTime = moment.unix(timer).format(format)

        return `${restTime}`
    }

    return (
        <div className="login-page__timer">
            {getRestTimeString()}
        </div>
    )
}

export default Timer
