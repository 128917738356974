import React, {useState} from 'react'
import {connect} from 'react-redux'
import Tokens from '../../model/Tokens'
import MainLayout from '../../hoc/MainLayout/MainLayout'
import ApiClient from '../../services/ApiClient'
import {API_ACCOUNT_SWAP} from '../../axios/paths'
import {useTranslation} from 'react-i18next'
import Select, {SelectOption} from '../../components/UI/Form/Elements/Select/Select'
import {IToken} from '../../model/Interfaces'
import tokensImg from '../../styles/img/token-img-1.png'
import tokensImg_2x from '../../styles/img/token-img-1@2x.png'
import AccountBalance from '../../model/AccountBalance'
import ArtTokenBalance from '../../model/ArtTokenBalance'
import ArtToken from '../../model/ArtToken'

interface IProps {
    balance: AccountBalance | null,
    tokensLoaded: boolean,
    tokens: Tokens | null,
}

const SwapPage: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()
    const [userSelectedToken, setUserSelectedToken] = useState<string|null>(null)
    const [platformSelectedToken, setPlatformSelectedToken] = useState<string|null>(null)
    const [userCountTokens, setUserCountTokens] = useState<number>(1)
    const [platformCountTokens, setPlatformCountTokens] = useState<number>(1)

    const tokens: Tokens | null = props.tokens
    const balance: AccountBalance | null = props.balance
    if (balance === null || !props.tokensLoaded || tokens === null) {
        return null
    }

    const mappedArtTokens = tokens.mappedArtTokens()

    const userListTokens:{[key: string]: SelectOption}  = {}
    let selectedToken: string | null = userSelectedToken
    balance.artTokenBalances.map((artTokenBalance: ArtTokenBalance) => {
        const tokenId = artTokenBalance.id
        const token: IToken = mappedArtTokens[tokenId]
        userListTokens[token.id] = {
            name: token.name,
            icon: token.icon || tokensImg,
            icon2x: token.icon2x || tokensImg_2x,
        }

        if (selectedToken === null && platformSelectedToken !== token.id) {
            selectedToken = token.id
        }
    })
    if (selectedToken !== userSelectedToken) {
        setUserSelectedToken(selectedToken)
    }

    const platformListTokens:{[key: string]: SelectOption}  = {}
    selectedToken = platformSelectedToken
    tokens.artTokens.map((token: ArtToken) => {
        if (!parseInt(token.available)) {
            return
        }
        platformListTokens[token.id] = {
            name: token.name,
            icon: token.icon || tokensImg,
            icon2x: token.icon2x || tokensImg_2x,
        }

        if (selectedToken === null && platformSelectedToken !== token.id) {
            selectedToken = token.id
        }
    })
    if (selectedToken !== platformSelectedToken) {
        setPlatformSelectedToken(selectedToken)
    }


    if (userSelectedToken === null || platformSelectedToken === null) {
        return null
    }

    const userTokenBalance: ArtTokenBalance = balance.getArtTokenById(userSelectedToken)
    const userCurrentToken: IToken = mappedArtTokens[userSelectedToken]
    const userCurrentTokenPrice: number = userCurrentToken.currentPrice
    const userCurrentTokenName: string = userCurrentToken.name
    const userCurrentTokenAvailable: string = userTokenBalance.quantity

    const platformCurrentToken: ArtToken = mappedArtTokens[platformSelectedToken]
    const platformCurrentTokenPrice: number = platformCurrentToken.currentPrice
    const platformCurrentTokenName: string = platformCurrentToken.name
    const platformCurrentTokenAvailable: string = platformCurrentToken.available

    const validateCounts = (): void => {
        const userTokenAvailable: number = parseFloat(userCurrentTokenAvailable)
        if (userCountTokens > userTokenAvailable) {
            const platformTokenAmount: number = userTokenAvailable
                * userCurrentTokenPrice
                / platformCurrentTokenPrice

            setPlatformCountTokens(platformTokenAmount)
            setUserCountTokens(userTokenAvailable)

            return
        }

        const platformTokenAvailable: number = parseFloat(platformCurrentTokenAvailable)
        if (platformCountTokens > platformTokenAvailable) {
            const userTokenAmount: number = platformTokenAvailable
                * platformCurrentTokenPrice
                / userCurrentTokenPrice

            setPlatformCountTokens(platformTokenAvailable)
            setUserCountTokens(userTokenAmount)

            return
        }

        const platformTokenAmount: number = userCountTokens
            * userCurrentTokenPrice
            / platformCurrentTokenPrice
        if (platformCountTokens !== platformTokenAmount) {
            setPlatformCountTokens(platformTokenAmount)
        }
    }

    validateCounts()

    const validateUserCount = (value: number): number => {
        const maxValue = parseFloat(userTokenBalance.quantity)

        return maxValue >= value && value > 0 ? value : maxValue
    }

    const validatePlatformCount = (value: number): number => {
        const maxValue = parseFloat(mappedArtTokens[platformSelectedToken].available)

        return maxValue >= value && value > 0 ? value : maxValue
    }

    const onUserTokenChangeHandler = (id: string) => {
        setUserSelectedToken(id)
    }

    const onPlatformTokenChangeHandler = (id: string) => {
        setPlatformSelectedToken(id)
    }

    const onUserCountChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = parseFloat(((event.target.value).replace(/,/, '.') || '1').replace(/[^0-9.]/, ''))
        if (value < 1) {
            value = 1
        }

        value = validateUserCount(value)
        setUserCountTokens(value)
    }

    const onPlatformCountChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = parseFloat(((event.target.value).replace(/,/, '.') || '1').replace(/[^0-9.]/, ''))
        if (value < 1) {
            value = 1
        }

        value = validatePlatformCount(value)
        setPlatformCountTokens(value)
    }

    const submitFromHandler = async () => {
        if (!userSelectedToken || !platformSelectedToken) {
            return
        }
        const apiClient = ApiClient()
        try {
            await apiClient.post(API_ACCOUNT_SWAP, {
                token_id: userSelectedToken,
                to_token_id: platformSelectedToken,
                amount: userCountTokens.toString()
            })

        } catch (e) {
            console.log(e)
            throw e
        }
    }

    return (
        <MainLayout>
            <main className="operations-page">
                <div className="operations-page__inner operations-page__inner--mw-400">
                    <h1 className="operations-page__title">Обмен токенов</h1>
                    <div className="form operations-page__form">
                        <div className="row row--sm-m-10">
                            <div className="col-8 col--sm-p-10">
                                <div className="form__group form__group--mb-10">
                                    <label className="form__label">Отдаёте</label>
                                    <input
                                        className="form__input"
                                        type="text"
                                        value={userCountTokens}
                                        onChange={onUserCountChangeHandler}
                                        required
                                    />
                                </div>
                                <div className="form__group">
                                    <ul className="form__list">
                                        <li className="green-color">
                                            {t('common:Available')}: {
                                                parseFloat(userCurrentTokenAvailable || '0').toLocaleString()
                                            } {userCurrentTokenName}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-4 col--sm-p-10">
                                <div className="form__group form__group--mb-10">
                                    <label className="form__label">
                                        {t('common:token')}
                                    </label>
                                    <Select
                                        options={userListTokens}
                                        onChange={onUserTokenChangeHandler}
                                        selected={userSelectedToken}
                                        disabled={[platformSelectedToken]}
                                    />
                                </div>
                                <div className="form__group">
                                    <ul className="form__list">
                                        <li className="green-color">
                                            1 {userCurrentTokenName} = {userCurrentTokenPrice} USD
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <button className="form__swap" type="button">
                            <svg className="form__swap-icon" xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"
                                      d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"/>
                            </svg>
                        </button>

                        <div className="row row--sm-m-10">
                            <div className="col-8 col--sm-p-10">
                                <div className="form__group form__group--mb-10">
                                    <label className="form__label">Получаете</label>
                                    <input
                                        className="form__input"
                                        type="text"
                                        value={platformCountTokens}
                                        onChange={onPlatformCountChangeHandler}
                                        required
                                    />
                                </div>
                                <div className="form__group">
                                    <ul className="form__list">
                                        <li className="green-color">
                                            {t('common:Available')}: {
                                            parseFloat(platformCurrentTokenAvailable || '0').toLocaleString()
                                        } {platformCurrentTokenName}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-4 col--sm-p-10">
                                <div className="form__group form__group--mb-10">
                                    <label className="form__label">Арт-токен</label>
                                    <Select
                                        options={platformListTokens}
                                        onChange={onPlatformTokenChangeHandler}
                                        selected={platformSelectedToken}
                                        disabled={[userSelectedToken]}
                                    />
                                </div>
                                <div className="form__group">
                                    <ul className="form__list">
                                        <li className="green-color">
                                            1 {platformCurrentTokenName} = {platformCurrentTokenPrice} USD
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="progress">
                            <div className="progress-bar">
                                <div className="progress-bar__sold" style={{width: '80%'}}/>
                                <div className="progress-bar__profit" style={{width: '1%'}}/>
                            </div>
                            <div className="progress-info">
                                <table className="progress-info__table">
                                    <tbody>
                                    <tr>
                                        <td>
                                            <div className="progress-info__icon progress-info__icon--striped"/>
                                            <div className="progress-info__title">продано токенов</div>
                                        </td>
                                        <td>
                                            <div className="progress-info__val">86 544</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="progress-info__icon"/>
                                            <div className="progress-info__title">можно получить</div>
                                        </td>
                                        <td>
                                            <div className="progress-info__val">13 456</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="progress-info__icon progress-info__icon--green"/>
                                            <div className="progress-info__title">вы получаете</div>
                                        </td>
                                        <td>
                                            <div className="progress-info__val">225</div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="form__buttons">
                            <button
                                className="button form__button"
                                onClick={submitFromHandler}
                            >
                                Конвертировать
                            </button>
                        </div>
                    </div>
                </div>
            </main>
        </MainLayout>
    )
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.authReducer.authToken.isFullyAuthenticated(),
        balance: state.balanceReducer.balance,
        tokensLoaded: state.tokenReducer.tokensLoaded,
        tokens: state.tokenReducer.tokens
    }
}

export default connect(mapStateToProps)(SwapPage)
