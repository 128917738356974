const messages = {
    required: 'security:errors.required_field',
    isEmail: 'security:errors.must_be_correct',
    isPhone: 'security:errors.must_be_correct',
    passwordDifficulty: 'security:errors.password_should_contains',
    minLength: 'security:errors.min_characters',
    strictEqual: 'security:errors.fields_do_not_match'
}

export default messages
