import React from 'react'
import sprite from '../../../../styles/img/sprite.svg'

const SupportIcon: React.FC = () => (
    <>
        <svg className="support-fixed__icon">
            <use xlinkHref={`${sprite}#support`}/>
        </svg>
    </>
)

export default SupportIcon
