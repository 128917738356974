import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'
import {logout} from '../../store/actions/Security/Auth/auth'

interface PropInterface {
    logout
}

const Logout: React.FC<PropInterface> = (props: PropInterface) => {
    const {logout}: PropInterface = props

    useEffect(() => {
        logout()
    })

    return <Redirect
        to={'/'}
        push
    />
}


function mapDispatchToProps(dispatch) {
    return {
        logout: (): void => dispatch(logout())
    }
}

export default connect(null, mapDispatchToProps)(Logout)
