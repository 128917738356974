import {
    ACCOUNT_LOADED, ACCOUNT_DESTROY,
    AUTH_LOGOUT,
    AUTH_SUCCESS, TWO_FA_REQUIRED, TWO_FA_ACCEPTED
} from '../../../actions/Security/Auth/authTypes'
import IAuhAction from './IAuhAction'
import IAuthState from './IAuthState'
import NullToken from '../../../../model/NullToken'

const initialState: IAuthState = {
    account: null,
    authToken: new NullToken(),
    twoFactorAuthRequired: false,
}

export default function authReducer(
    state: IAuthState = initialState,
    action: IAuhAction
): IAuthState {
    switch (action.type) {
        case AUTH_SUCCESS:
            return {
                ...state,
                authToken: action.authToken || new NullToken(),
            }
        case TWO_FA_REQUIRED:
            return {
                ...state,
                twoFactorAuthRequired: true,
            }
        case TWO_FA_ACCEPTED:
            return {
                ...state,
                twoFactorAuthRequired: false,
            }
        case AUTH_LOGOUT:
            return {
                ...initialState,
            }
        case ACCOUNT_LOADED:
            return {
                ...state,
                account: action.account || null
            }
        case ACCOUNT_DESTROY:
            return {
                ...state,
                account: null,
            }
        default:
            return state
    }
}
