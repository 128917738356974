import {EnumObjectListType} from '../Types'
import {FilterSort} from '../Enums'

const SortList:EnumObjectListType = [{
    label: 'pictures:texts.recommended',
    value: FilterSort.SORT_RECOMMENDED_ASC,
}, {
    label: 'pictures:texts.price_low_to_high',
    value: FilterSort.SORT_PRICE_ASC,
}, {
    label: 'pictures:texts.price_high_to_low',
    value: FilterSort.SORT_PRICE_DESC,
}]

export default SortList
