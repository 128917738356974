import {connect} from 'react-redux'
import React, {useState} from 'react'
import {generatePath, Link} from 'react-router-dom'
import {LOGOUT_PATH, SETTINGS_PATH} from '../../../config/routing/paths'
import Account from '../../../model/Account'
import AvatarIcon from '../../UI/Icons/AvatarIcon/AvatarIcon'
import UserIcon from '../../UI/Icons/UserIcon/UserIcon'
import {TabsList} from '../../../containers/SettingsPage/SettingsPage'
import avatarImg from '../../../styles/img/avatar-img.png'
import avatarImg2x from '../../../styles/img/avatar-img@2x.png'
import UserArrowIcon from '../../UI/Icons/UserArrowIcon/UserArrowIcon'
import * as cs from 'classnames'
import {useTranslation} from 'react-i18next'

interface IProps {
    account: Account | null
}

const UserMenu: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()
    const [active, setActive] = useState<boolean>(false)

    if (!props.account) {
        return null
    }

    const onClickHandler = (event: React.MouseEvent) => {
        event.preventDefault()
        setActive(!active)
    }

    return (
        <>
            <div className="header-user">
                <div className={cs('header-user__toggle', {'is-active': active})} onClick={onClickHandler}>
                    <div className="header-user__avatar">
                        <img
                            className="header-user__img"
                            src={props.account.avatar || avatarImg}
                        />
                    </div>
                    <div className="header-user__nickname">
                        {props.account.username.substr(0, 24)}
                    </div>
                    <UserArrowIcon/>
                </div>
                <div className={cs('header-user__dropdown', {'is-visible': active})}>
                    <ul className="header-user__list">
                        <li className="header-user__item">
                            {/*<a className="header-user__link" href="#">*/}
                            {/*    Invites*/}
                            {/*</a>*/}
                        </li>
                        <li className="header-user__item">
                            <Link
                                className="header-user__link"
                                to={generatePath(SETTINGS_PATH, {tab: TabsList.PROFILE_TAB})}
                            >
                                {t('common:menu.settings')}
                            </Link>
                        </li>
                        <li className="header-user__item">
                            <Link className="header-user__link" to={LOGOUT_PATH}>
                                {t('common:menu.log_out')}
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}


function mapStateToProps(state) {
    return {
        account: state.authReducer.account,
    }
}

export default connect(mapStateToProps, null)(UserMenu)
