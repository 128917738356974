import React from 'react'
import {useTranslation} from 'react-i18next'
import callbackUrl from '../../../config/routing/callbackUrl'
import {MAIN_PATH} from '../../../config/routing/paths'
import {Partner} from '../../../containers/PartnerPage/PartnerPage'
import tokenImg1 from '../../../styles/img/token-img-1.png'
import tokenImg1_2x from '../../../styles/img/token-img-1@2x.png'
import PartnerItem from '../PartnerItem/PartnerItem'

interface IProps {
    partners: Partner[],
    referral: string
}

const PartnersList: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()
    const referralLink = callbackUrl(MAIN_PATH, {
        referral: props.referral
    })
    const copyLinkClickHandler = (event: React.MouseEvent) => {
        event.preventDefault()
        navigator.clipboard.writeText(referralLink)
    }

    return (
        // Partners Main
        <main className="partners-page">
            <div className="partners-page__inner">
                <div className="row partners-page__row">
                    <div className="col-lg-6 partners-page__col-top">
                        <div className="partners-page__list">
                            <h1 className="partners-page__title">
                                {t('common:titles.partners')}
                            </h1>
                            {
                                props.partners.map((partner: Partner, i: number) => {
                                    return (
                                        <PartnerItem key={'partner' + i} partner={partner} />
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="col-lg-6 partners-page__col-bottom">
                        <div className="get-bonus">
                            <h2 className="get-bonus__title">
                                {t('common:titles.bonuses_received')}
                            </h2>
                            <div className="token-item token-item--big get-bonus__token">
                                <div className="token-item__picture">
                                    <img
                                        className="token-item__img"
                                        src={tokenImg1}
                                        srcSet={tokenImg1_2x + ' 2x'}
                                        alt="alt"
                                    />
                                </div>
                                <div className="token-item__descr">
                                    <div className="token-item__value">
                                        {/*11,506*/}
                                        0
                                        <span className="token-item__unit">INDX</span>
                                    </div>
                                </div>
                            </div>
                            <div className="get-bonus__descr">
                                <p>
                                    {t('common:bonuses_from_partners')}
                                </p>
                            </div>
                        </div>
                        <div className="referral">
                            <h2 className="referral__title">
                                {t('common:referral_link')}
                            </h2>
                            <form className="form referral__form">
                                <div className="form__group">
                                    <input
                                        className="form__input form__input--bordered"
                                        type="text"
                                        name="inputLink"
                                        placeholder=""
                                        value={referralLink}
                                    />
                                </div>
                                <div className="form__buttons form__buttons--center">
                                    <button
                                        className="button form__button"
                                        onClick={copyLinkClickHandler}
                                    >
                                        {t('common:copy')}
                                    </button>
                                </div>
                            </form>
                            <div className="referral__descr">
                                <p>
                                    {t('common:send_your_personalized_link')}
                                </p>
                                <p>
                                    <a href="#referral_program_terms_and_conditions">
                                        {t('common:referral_program_terms_and_conditions')}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default PartnersList
