export function createControl(config, validation) {
    return {
        ...config,
        validation,
        valid: !validation,
        touched: false,
        value: config.value || ''
    }
}

export function validate(control = null, formControls) {
    const value = control.value
    const validation = control.validation

    if (!validation) {
        return true
    }

    if (validation.required && control.type === 'checkbox' && !control.checked) {
        control.errorMessage = control.errorMessages.required || ''

        return false
    }

    if (control.type === 'checkbox') {
        return true
    }

    if (validation.required && value.trim() === '') {
        control.errorMessage = control.errorMessages.required || ''

        return false
    }

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (validation.email && !re.test(value.trim().toLowerCase())) {
        control.errorMessage = control.errorMessages.email || ''

        return false
    }

    if (validation.minLength && value.length < validation.minLength) {
        control.errorMessage = control.errorMessages.minLength || ''

        return false
    }

    if (validation.passwordDifficulty
        && (
            !(/[a-zа-я]{1,}/.test(value))
            || !(/[A-ZА-Я]{1,}/.test(value))
            || !(/\d{1,}/.test(value))
            || !(/[^a-zа-я\d]{1,}/i.test(value))
        )
    ) {
        control.errorMessage = control.errorMessages.passwordDifficulty || ''

        return false
    }

    if (!!validation.equalTo
        && Object.prototype.hasOwnProperty.call(formControls, validation.equalTo)
        && value.trim() !== formControls[validation.equalTo].value.trim()
    ) {
        control.errorMessage = control.errorMessages.equalTo || ''

        return false
    }

    return true
}

export function validateForm(formControls) {
    let isFormValid = true

    for (let control in formControls) {
        if (Object.prototype.hasOwnProperty.call(formControls, control)) {
            isFormValid = formControls[control].valid && isFormValid
        }
    }

    return isFormValid
}
