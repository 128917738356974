import React, {useEffect, useState} from 'react'
import 'react-fancybox/lib/fancybox.css'
import {Line} from 'react-chartjs-2'
import {useTranslation} from 'react-i18next'
import {Link, useParams} from 'react-router-dom'
import {API_PAINTING, API_PAINTING_BUY, API_PAINTING_SUBSCRIBE} from '../../axios/paths'
import {AUTH_PATH, BUY_TOKENS_PATH, GALLERY_PATH} from '../../config/routing/paths'
import ApiClient from '../../services/ApiClient'
import painterUnknown from '../../styles/img/painter-unknown.svg'
import {connect} from 'react-redux'
import * as cs from 'classnames'
import { SRLWrapper, useLightbox } from 'simple-react-lightbox'
import history from '../../utils/history'
import SuccessModal from '../../components/Modals/SuccessModal/SuccessModal'
import TokenGraphModal from '../../components/Modals/TokenGraphModal/TokenGraphModal'
import BackButton from '../../components/UI/BackButton/BackButton'
import {AuthorListType, EnumObjectType, KeyValueItemType} from '../../model/Types'
import {ErasList, GenresList, TechnicsList} from '../../model/Structure'
import {Era} from '../../model/Enums'
import {IAuthor, IPainting, IPaintingImage} from '../../model/Interfaces'
import SlidePrevIcon from '../../components/UI/Icons/SlidePrevIcon/SlidePrevIcon'
import SlideNextIcon from '../../components/UI/Icons/SlideNextIcon/SlideNextIcon'
import ParamsTable from '../../components/Painting/ParamsTable/ParamsTable'
import PriceItem from '../../components/Painting/PriceItem/PriceItem'
import MainLayout from '../../hoc/MainLayout/MainLayout'
import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {Controller, Thumbs, Mousewheel, Navigation, Keyboard, Swiper as SwiperType} from 'swiper'
import 'swiper/swiper.scss'
import 'swiper/components/controller/controller.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/thumbs/thumbs.scss'
import Painting, {PaintingDataObject} from '../../model/Painting'
import {AxiosResponse} from 'axios'
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal'
import ArtToken from '../../model/ArtToken'

SwiperCore.use([Mousewheel, Navigation, Keyboard, Thumbs, Controller])

interface IProps {
    isAuthenticated: boolean
    authors: AuthorListType,
    authorsLoaded: boolean,
}

const PaintingPage: React.FC<IProps> = (props: IProps) => {
    const [successModalVisible, setSuccessModalVisible] = useState<boolean>(false)
    const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>(false)
    const [item, setItem] = useState<IPainting | null>(null)
    const {uuid} = useParams()
    const {t} = useTranslation()
    const {openLightbox} = useLightbox()
    const [graphModalIsVisible, setGraphModalIsVisible] = useState<boolean>(false)
    const [swiper, setSwiper] = useState<SwiperType | undefined>()
    const [thumbSwiper, setThumbSwiper] = useState<SwiperType | undefined>()

    useEffect(() => {
        if (!uuid) {
            return
        }

        const apiClient = ApiClient()
        const response = apiClient.get(API_PAINTING(uuid))
        response.then((responseData: AxiosResponse<PaintingDataObject>) => {
            const painting: IPainting = new Painting(responseData.data)
            setItem(painting)
        })
    }, [])

    if (!props.authorsLoaded || !item) {
        return null
    }

    const renderItems = (items: IPaintingImage[]) => items.map((item: IPaintingImage, i: number) => {
        return (
            <SwiperSlide key={'slide' + i} className="picture-slider__slide">
                <a
                    className="picture-slider__link"
                    href="#view"
                    onClick={onImageClickHandler}
                >
                    <picture className="picture-slider__picture">
                        <img
                            className="picture-slider__img"
                            src={item.normal}
                            srcSet={`${item.normal2x} 2x`}
                        />
                    </picture>
                </a>
            </SwiperSlide>
        )
    })

    const renderThumbsItems = (items: IPaintingImage[]) => items.map((item: IPaintingImage, i: number) => {
        return (
            <SwiperSlide key={'slide-thumb-' + i} className="picture-thumbs__slide">
                <img
                    className="picture-thumbs__img"
                    src={item.thumbnail}
                    srcSet={`${item.thumbnail2x} 2x`}
                />
            </SwiperSlide>
        )
    })

    const subscribeHandler = async (paintingId: string) => {
        const apiClient = ApiClient()
        try {
            await apiClient.post(API_PAINTING_SUBSCRIBE(paintingId))
            setSuccessModalVisible(true)
        } catch (e) {
            console.log(e)
        }
    }

    const buyPaintingHandler = async (saleId: string) => {
        const apiClient = ApiClient()
        try {
            await apiClient.post(API_PAINTING_BUY(saleId))
            setSuccessModalVisible(true)
        } catch (e) {
            console.log(e)
        }
    }

    const showGraph = () => {
        setGraphModalIsVisible(true)
    }
    const closeModalHandler = () => {
        setGraphModalIsVisible(false)
    }

    const authors = props.authors

    const onImageClickHandler = (event: React.MouseEvent) => {
        event.preventDefault()
        openLightbox()
    }

    const author: IAuthor | null = item.author && item.author.id in authors ? authors[item.author.id] : null

    const labels: string[] = []
    const dataPoints: string[] = []
    const hasPredictionGraph = item.artToken !== null && Object.keys(item.artToken.predictionGraph).length > 0
    if (item.artToken !== null && hasPredictionGraph) {
        const artToken = item.artToken
        Object.keys(artToken.predictionGraph).map((key: string, i: number) => {
            const [year, quarter] = key.split('_')
            const dateStr = (quarter ? (' ' + 'Q' + quarter) : '') + ' \'' + year
            labels.push(dateStr)
            dataPoints.push(artToken.predictionGraph[key])
        })
    }

    const paramsTableItems: KeyValueItemType[] = [{
        label: t('pictures:texts.dimensions'),
        value: item.parameters.size,
    }, {
        label: t('pictures:texts.genres'),
        value: GenresList
            .filter((genre: EnumObjectType) => {
                return genre.value === item.parameters.genre
            })
            .map((genre: EnumObjectType) => {
                return t(genre.label.toLowerCase())
            })
            .join(', '),
    }, {
        label: t('pictures:texts.technics'),
        value: TechnicsList
            .filter((technic: EnumObjectType) => {
                return technic.value === item.parameters.technic
            })
            .map((technic: EnumObjectType) => {
                return t(technic.label.toLowerCase())
            })
            .join(', '),
    }, {
        label: t('pictures:texts.eras'),
        value: ErasList
            .filter((era: EnumObjectType) => {
                return item.parameters.eras_and_styles.includes(era.value as Era)
            })
            .map((era: EnumObjectType) => {
                return t(era.label.toLowerCase())
            })
            .join(', '),
    }, {
        label: t('pictures:texts.frame'),
        value: t(item.parameters.frame.toLowerCase()),
    }, {
        label: t('pictures:texts.year'),
        value: item.year ? item.year.toString() : '',
    }];

    const saleId: string | null = item.saleId || null;

    const artToken: ArtToken | null = item.artToken
    const availableAmount = artToken
        ? parseFloat(artToken.available)
        : 0
    const emissionAmount = artToken
        ? parseFloat(artToken.emission)
        : 0

    const paintingUnderAttribution = !(artToken && artToken.currentPrice > 0)
    const artTokenAvailable = artToken && !paintingUnderAttribution && availableAmount > 0

    const restPercentage = artToken && emissionAmount
        ? availableAmount / emissionAmount * 100
        : 0

    return (
        <MainLayout>
            <main className="picture-card-page">
                <div className="picture-card-page__inner">
                    <div className="prev-step picture-card-page__prev-step">
                        <BackButton url={GALLERY_PATH}/>
                    </div>
                    <div className="picture-card__item">
                        <div className="row picture-card__row">
                            <div className="col-lg-5 col-md-6 picture-card__left">
                                <div
                                    className={cs('picture-card__gallery', {
                                        'picture-card__gallery--vertical': item.isVertical()
                                    })}
                                >
                                    <Swiper
                                        spaceBetween={0}
                                        loop={false}
                                        loopedSlides={4}
                                        className={"picture-slider"}
                                        navigation={{
                                            nextEl: '.picture-arrow-next',
                                            prevEl: '.picture-arrow-prev',
                                        }}
                                        onSwiper={setSwiper}
                                        thumbs={{
                                            swiper: thumbSwiper,
                                        }}
                                    >
                                        {renderItems(item.allImages())}
                                    </Swiper>

                                    <SRLWrapper
                                        elements={item.allImages().map((image: IPaintingImage) => {
                                            return {
                                                src: image.large,
                                                thumbnail: image.thumbnail,
                                            }
                                        })}
                                        options={{ buttons: { showDownloadButton: false }}}
                                    />

                                    <div className="picture-thumbs-wrap">
                                        <Swiper
                                            spaceBetween={15}
                                            slidesPerView={item.isVertical() ? 3 : 4}
                                            loop={false}
                                            freeMode={true}
                                            loopedSlides={4}
                                            watchSlidesVisibility={true}
                                            watchSlidesProgress={true}
                                            className={cs('picture-thumbs', {
                                                'picture-thumbs--vertical': item.isVertical()
                                            })}
                                            slideActiveClass="swiper-slide-thumb-active"
                                            slideToClickedSlide={true}
                                            onSwiper={setThumbSwiper}
                                            direction={item.isVertical() ? 'vertical' : 'horizontal'}
                                        >
                                            {renderThumbsItems(item.allImages())}
                                        </Swiper>

                                        {
                                            item.allImages().length > 1
                                            ?
                                                <>
                                                    <div className="picture-arrow picture-arrow-prev">
                                                        <SlidePrevIcon/>
                                                    </div>
                                                    <div className="picture-arrow picture-arrow-next">
                                                        <SlideNextIcon/>
                                                    </div>
                                                </>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className="picture-info">
                                    <div className="picture-info__title">
                                        {t(item.name || 'pictures:texts.unknown')}
                                    </div>
                                    {
                                        item.shortDescription
                                            ? <div className="picture-info__descr"
                                                dangerouslySetInnerHTML={{
                                                    __html: item.shortDescription
                                                }}
                                            />
                                            : null
                                    }
                                    <div className="picture-info__author">
                                        <div className="painter">
                                            <picture className="painter__picture">
                                                <img
                                                    className=" painter__img"
                                                    src={author?.image?.x1 || painterUnknown}
                                                    srcSet={author?.image?.x2 || painterUnknown}
                                                    alt={t(author?.name || 'pictures:texts.author_unknown')}
                                                />
                                            </picture>
                                            <div className="painter__name">
                                                {t(author?.name || 'pictures:texts.author_unknown')}
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        !paintingUnderAttribution
                                        ?
                                            <>
                                                <div className="progress picture-info__progress">
                                                    <div className="progress__area">
                                                        <div className="progress__bar" style={{
                                                            width: Math.round(restPercentage).toString() + '%'
                                                        }}/>
                                                    </div>
                                                    <div className="progress__descr">
                                                        {availableAmount.toLocaleString()}
                                                        &nbsp;/
                                                        &nbsp;{emissionAmount.toLocaleString()}
                                                        &nbsp;{t('pictures:texts.tokens_available')}
                                                    </div>
                                                </div>
                                                <div className="picture-info__price">
                                                    {item.artToken?.currentPrice.toLocaleString()} $
                                                </div>
                                            </>
                                        : null
                                    }
                                    {
                                        artTokenAvailable
                                        ?
                                            <Link
                                                className="button picture-info__button"
                                                to={props.isAuthenticated ? BUY_TOKENS_PATH : AUTH_PATH}
                                            >
                                                {t('common:buttons.invest')}
                                            </Link>
                                        :
                                            <a
                                                className="button picture-info__button"
                                                href="#"
                                                onClick={(e: React.MouseEvent) => {
                                                    e.preventDefault()
                                                    if (props.isAuthenticated) {
                                                        subscribeHandler(item.id)
                                                        return
                                                    }
                                                    history.push(AUTH_PATH)
                                                }}
                                            >
                                                {t('common:buttons.report_on_arrival')}
                                            </a>
                                    }
                                    {
                                        !paintingUnderAttribution && saleId
                                        ?
                                            <a
                                                className="button button--bordered picture-info__button"
                                                href="#"
                                                onClick={(e: React.MouseEvent) => {
                                                    e.preventDefault()
                                                    if (!props.isAuthenticated) {
                                                        history.push(AUTH_PATH)
                                                        return
                                                    }
                                                    setConfirmModalVisible(true)
                                                }}
                                            >
                                                {t('common:buttons.buy_a_painting')}
                                            </a>
                                        : null
                                    }
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-6 picture-card__right">
                                {
                                    !paintingUnderAttribution
                                    ?
                                        <div className="picture-price">
                                            <PriceItem
                                                icon="share"
                                                title={`1 ${t('common:token_small')} (${t('common:share')}): <span>${item.artToken?.currentPrice.toLocaleString()} $</span>`}
                                                visible={!!item.artToken}
                                            />
                                            <PriceItem
                                                icon="price"
                                                title={`${t('common:painting_price')}: <span>${
                                                    item.artToken
                                                        ? (item.artToken?.currentPrice * parseFloat(item.artToken?.emission))
                                                            .toLocaleString()
                                                        : 0
                                                } $</span>`}
                                                visible={!!item.artToken}
                                            />
                                            <PriceItem
                                                icon="chart"
                                                title={t('common:price_growth_forecast')}
                                                visible={hasPredictionGraph}
                                            />
                                            {
                                                artToken && hasPredictionGraph
                                                    ?
                                                        <div className="picture-card__chart">
                                                            <a
                                                                href="#show_graph"
                                                                onClick={showGraph}
                                                            >
                                                                <div
                                                                    className="picture-card__chart-img"
                                                                    style={{
                                                                        position: "relative",
                                                                        marginTop: "-20px",
                                                                        left: "-10px"
                                                                    }}
                                                                >
                                                                    <Line
                                                                        width={250}
                                                                        height={150}
                                                                        options={{
                                                                            layout: {
                                                                                padding: {
                                                                                    left: 48,
                                                                                    right: 24,
                                                                                    top: 12,
                                                                                    bottom: 12
                                                                                }
                                                                            },
                                                                            legend: { display: false },
                                                                            tooltips: { enabled: true },
                                                                            responsive: true,
                                                                            maintainAspectRatio: false,
                                                                            scales: {
                                                                                yAxes: [{
                                                                                    gridLines: {
                                                                                        display: false,
                                                                                        drawTicks: false,
                                                                                    },
                                                                                    ticks: {
                                                                                        display: false,
                                                                                    }
                                                                                }],
                                                                                xAxes: [{
                                                                                    gridLines: {
                                                                                        display: false,
                                                                                        drawTicks: true,
                                                                                    },
                                                                                    ticks: {
                                                                                        display: true,
                                                                                    }
                                                                                }]
                                                                            }
                                                                        }}
                                                                        data={{
                                                                            labels: labels,
                                                                            datasets: [{
                                                                                backgroundColor: 'rgba(255,255,255,1)',
                                                                                borderCapStyle: 'round',
                                                                                borderColor: 'rgba(32, 26, 26, 1)',
                                                                                borderWidth: 1,
                                                                                lineTension: 0.5,
                                                                                pointRadius: 3,
                                                                                data: dataPoints
                                                                            }]
                                                                        }}
                                                                    />
                                                                </div>
                                                            </a>
                                                            <TokenGraphModal
                                                                painting={item}
                                                                token={artToken}
                                                                visible={graphModalIsVisible}
                                                                close={closeModalHandler}
                                                            />
                                                        </div>
                                                    : null
                                            }
                                        </div>
                                    : null
                                }
                                {
                                    paintingUnderAttribution
                                        ?
                                            <div className="picture-price">
                                                <PriceItem
                                                    icon={item.attrDescription ? 'share' : 'support'}
                                                    title={
                                                        item.attrDescription
                                                        || t('common:picture_under_attribution_full')
                                                    }
                                                    visible={!item.artToken?.currentPrice}
                                                />
                                            </div>
                                        : null
                                }
                                {
                                    item.fullDescription
                                        ?
                                            <div
                                                className="picture-card__descr"
                                                dangerouslySetInnerHTML={{
                                                    __html: item.fullDescription
                                                }}
                                            />
                                        : null
                                }
                                <div className="picture-card__descr">
                                    <ParamsTable items={paramsTableItems}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SuccessModal
                    visible={successModalVisible}
                    close={() => {
                        setSuccessModalVisible(false)
                    }}
                    content={t('common:modal.thank_you')}
                />
                {
                    saleId
                    ? <ConfirmModal
                            visible={confirmModalVisible}
                            confirm={() => {
                                setConfirmModalVisible(false)
                                buyPaintingHandler(saleId)
                            }}
                            close={() => {
                                setConfirmModalVisible(false)
                            }}
                            content={t('common:modal.confirm_by_painting')}
                        />
                    : null
                }

            </main>
        </MainLayout>
    )
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.authReducer.authToken.isFullyAuthenticated(),
        authorsLoaded: state.galleryReducer.authorsLoaded,
        authors: state.galleryReducer.authors,
    }
}
export default connect(mapStateToProps)(PaintingPage)
