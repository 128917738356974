import {Action} from 'redux'
import {REFERRAL_USER_DATA_LOADED} from '../../../actions/actionTypes'

interface ReferralState {
    email: string | null,
    token: string | null,
    loading: boolean
}

const initialState = {
    email: null,
    token: null,
    loading: true
}

export type ActionType = Action<string> & {payload: {email: string, token: string} | null}

const registrationReferralReducer = (
    state: ReferralState = initialState,
    action: ActionType
): ReferralState => {
    switch (action.type) {
        case REFERRAL_USER_DATA_LOADED:
            return {
                ...state,
                loading: false,
                email: action.payload?.email || null,
                token: action.payload?.token || null,
            }
        default:
            return state
    }
}

export default registrationReferralReducer
