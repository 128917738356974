import React from 'react'
import sprite from '../../../../styles/img/sprite.svg'

const LandingFooterLogoIcon: React.FC = () => (
    <svg className="main-logo__img" width="224" height="29">
        <use xlinkHref={sprite + '#logo'}/>
    </svg>
)

export default LandingFooterLogoIcon
