import React from 'react'
import {MAIN_PATH} from '../../../config/routing/paths'
import LogoIcon from '../Icons/LogoIcon/LogoIcon'
import {Link} from 'react-router-dom'

const Logo: React.FC = () => {
    return (
        <Link className="logo__link" to={MAIN_PATH}>
            <LogoIcon/>
        </Link>
    )
}

export default Logo
