import {IPaintingImage} from './Interfaces'

export type PaintingImageDataObject = {
    uuid: string,
    thumbnail: string,
    thumbnail2x: string,
    small: string,
    small2x: string,
    normal: string,
    normal2x: string,
    large: string,
}

class PaintingImage implements IPaintingImage {
    private _id: string
    private _thumbnail: string
    private _thumbnail2x: string
    private _small: string
    private _small2x: string
    private _normal: string
    private _normal2x: string
    private _large: string

    constructor(data: PaintingImageDataObject) {
        this._id = data.uuid;
        this._thumbnail = data.thumbnail;
        this._thumbnail2x = data.thumbnail2x;
        this._small = data.small;
        this._small2x = data.small2x;
        this._normal = data.normal;
        this._normal2x = data.normal2x;
        this._large = data.large;
    }

    get id(): string {
        return this._id
    }

    get thumbnail(): string {
        return this._thumbnail
    }

    get thumbnail2x(): string {
        return this._thumbnail2x
    }

    get small(): string {
        return this._small
    }

    get small2x(): string {
        return this._small2x
    }

    get normal(): string {
        return this._normal
    }

    get normal2x(): string {
        return this._normal2x
    }

    get large(): string {
        return this._large
    }
}

export default PaintingImage
