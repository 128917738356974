import React, {useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {API_FEEDBACK} from '../../../axios/paths'
import Account from '../../../model/Account'
import ApiClient from '../../../services/ApiClient'
import history from '../../../utils/history'
import SuccessModal from '../../Modals/SuccessModal/SuccessModal'
import Button from '../../UI/Form/Elements/Button/Button'
import Select from '../../UI/Form/Elements/Select/Select'
import Textarea from '../../UI/Form/Elements/Textarea/Textarea'
import PreLoader from '../../UI/PreLoader/PreLoader'
import messages from '../../../utils/ValidatorMessages/ValidatorMessages'
import validator from 'validator'

type FormData = {
    // problemType: string,
    text: string,
    // uploadedFile: string,
}

interface IProps {
    account: Account,
}

type Props = IProps

const RegisteredSupportForm: React.FC<Props> = (props: Props) => {
    const {handleSubmit, errors, control, reset} = useForm<FormData>({})
    const [modalVisible, setModalVisible] = useState<boolean>(false)
    const {t} = useTranslation()

    if (!props.account) {
        return <PreLoader/>
    }

    const onSubmitHandler = (event) => {
        event.preventDefault()
    }

    const sendFormHandler = async (data: FormData) => {
        const apiClient = ApiClient()
        try {
            await apiClient.post(API_FEEDBACK, {
                email: props.account.email,
                message: data.text
            })
            reset()
            setModalVisible(true)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            <form
                className="form help-page__form help-page__form--mw-400"
                onSubmit={onSubmitHandler}
                autoComplete="chrome-off"
            >
                {/*<div className="form__group">*/}
                {/*    <Controller*/}
                {/*        name="problemType"*/}
                {/*        defaultValue=""*/}
                {/*        as={Select}*/}
                {/*        options={{*/}
                {/*            1: {name: 'a'},*/}
                {/*            2: {name: 'b'},*/}
                {/*            3: {name: 'c'},*/}
                {/*        }}*/}
                {/*        control={control}*/}
                {/*        rules={{*/}
                {/*            required: t(messages.required).toString(),*/}
                {/*            validate: value =>*/}
                {/*                validator.isEmail(value)*/}
                {/*                || t(messages.isEmail).toString()*/}
                {/*        }}*/}
                {/*    />*/}
                {/*    <label className="form__placeholder is-active">Тип проблемы</label>*/}
                {/*</div>*/}

                <Controller
                    name="text"
                    label={t('common:describe_you_problem')}
                    defaultValue=""
                    error={errors.text}
                    as={Textarea}
                    control={control}
                    rules={{
                        required: t(messages.required).toString()
                    }}
                />

                <div className="form__buttons form__buttons--center">
                    <Button
                        onClick={handleSubmit(sendFormHandler)}
                        className="button form__button"
                        disabled={false}
                    >
                        {t('common:buttons.send')}
                    </Button>
                </div>
            </form>


            {/*<div className="form__group">*/}
            {/*    <div className="upload-file">*/}
            {/*        <div className="upload-file-place">*/}
            {/*            <div className="upload-file-place__circle">*/}
            {/*                <svg className="upload-file-place__icon" xmlns="http://www.w3.org/2000/svg"*/}
            {/*                     fill="none" viewBox="0 0 24 24" stroke="currentColor">*/}
            {/*                    <path*/}
            {/*                        strokeLinecap="round"*/}
            {/*                        strokeLinejoin="round"*/}
            {/*                        strokeWidth="1"*/}
            {/*                        d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"*/}
            {/*                    />*/}
            {/*                    <path*/}
            {/*                        strokeLinecap="round"*/}
            {/*                        strokeLinejoin="round"*/}
            {/*                        strokeWidth="1"*/}
            {/*                        d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"*/}
            {/*                    />*/}
            {/*                </svg>*/}
            {/*            </div>*/}
            {/*            <div className="upload-file-place__descr">*/}
            {/*                Перетащите картинку сюда <br/>или <a href="#">выберите файл</a>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <SuccessModal
                visible={modalVisible}
                close={() => {
                    setModalVisible(false)
                    history.goBack()
                }}
                content={t('common:modal.thank_you')}
            />
        </>
    )
}

function mapStateToProps(state) {
    return {
        account: state.authReducer.account,
    }
}

export default connect(mapStateToProps)(RegisteredSupportForm)
