import 'moment/locale/ru'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import BasketItem from '../../../model/BasketItem'
import UsdDarkIcon from '../../UI/Icons/UsdDarkIcon/UsdDarkIcon'
import BasketToken from '../BasketToken/BasketToken'
import {PaymentMethod} from '../../../model/Enums'
import {IToken} from '../../../model/Interfaces'

interface IProps {
    mappedTokens: {[key: string]: IToken},
    setBasketItems: (items: BasketItem[]) => void,
    setPaymentMethod: (paymentMethod: PaymentMethod) => void,
    basketItems: BasketItem[],
}

const Basket: React.FC<IProps> = (props: IProps) => {
    const {t, i18n} = useTranslation()

    const selectedTokens: string[] = []
    let sum = 0
    props.basketItems.map((item: BasketItem) => {
        selectedTokens.push(item.id)
        sum += item.count * props.mappedTokens[item.id].currentPrice
    })

    const addNewToken = () => {
        const items = [...props.basketItems]
        let found = false
        Object.keys(props.mappedTokens).map((key: string) => {
            const item: IToken = props.mappedTokens[key]
            if (!found && !selectedTokens.includes(item.id)) {
                items.push(new BasketItem(item.id))
                props.setBasketItems(items)
                found = true
            }
        })
    }

    const updateItem = (i: number, item: BasketItem) => {
        const items = [...props.basketItems]
        items[i] = item
        props.setBasketItems(items)
    }

    const removeItem = (i: number) => {
        const items = [...props.basketItems]
        items.splice(i, 1)
        props.setBasketItems(items)
    }

    return (
        <div className="operations-page__inner">
            <h1 className="operations-page__title" style={{textAlign: 'center'}}>
                {t('common:tokens_buying')}
            </h1>
            <form className="form operations-page__form">
                <div className="form__thead">
                    <div className="row row--sm-m-10">
                        <div className="col-4 col-sm-4 col--sm-p-10">
                            <label className="form__label">
                                {t('common:token')}
                            </label>
                        </div>
                        <div className="col-4 col-sm-5 col--sm-p-10">
                            <label className="form__label form__label--center">
                                {t('common:quantity')}
                            </label>
                        </div>
                        <div className="col-4 col-sm-3 col--sm-p-10">
                            <label className="form__label form__label--right">
                                {t('common:amount')}
                            </label>
                        </div>
                    </div>
                </div>
                {
                    props.basketItems
                        .map((item: BasketItem, i: number) => {
                            return (
                                <BasketToken
                                    key={'basket-' + i}
                                    tokens={props.mappedTokens}
                                    unavailable={selectedTokens}
                                    item={item}
                                    onChange={(item: BasketItem) => {updateItem(i, item)}}
                                    onRemove={() => {removeItem(i)}}
                                />
                            )
                        })
                }
                {
                    Object.keys(props.mappedTokens).length > props.basketItems.length
                    ?
                        <div className="form__group">
                            <button
                                className="button button--bordered form__button"
                                type="button"
                                onClick={addNewToken}
                            >
                                {t('common:add_token')} +
                            </button>
                        </div>
                    : null
                }
                <div className="form__group">
                    <div className="form__tfoot">
                        <label className="form__label form__label--fz-16">
                            {t('common:purchase_amount')}
                        </label>
                        <label className="form__label form__label--fz-24">$ {sum.toLocaleString()}</label>
                    </div>
                </div>

                <div style={{
                    border: '1px solid #222',
                    padding: '20px 40px',
                    margin: '50px 0',
                    textAlign: 'center'
                }}>
                    {t('i_am_not_american')}
                </div>

                <div className="form__group">
                    <label className="form__label form__label--fz-16 form__label--mb-20">
                        {t('common:select_payment_method')}
                    </label>
                    {/*<button*/}
                    {/*    className="radio-button radio-button--big"*/}
                    {/*    type="button"*/}
                    {/*    disabled={true}*/}
                    {/*>*/}
                    {/*    <div className="radio-button__picture">*/}
                    {/*        <CreditCardIcon/>*/}
                    {/*    </div>*/}
                    {/*    <div className="radio-button__text">*/}
                    {/*        <div className="radio-button__title">*/}
                    {/*            {t('common:by_credit_card')}*/}
                    {/*        </div>*/}
                    {/*        <div className="radio-button__descr">Visa, MasterCard</div>*/}
                    {/*    </div>*/}
                    {/*</button>*/}
                    <button
                        className="radio-button radio-button--big"
                        // is-active
                        type="button"
                        onClick={() => {
                            props.setPaymentMethod(PaymentMethod.PAY_CRYPTO)
                        }}
                    >
                        <div className="radio-button__picture">
                            <UsdDarkIcon/>
                        </div>
                        <div className="radio-button__text">
                            <div className="radio-button__title">
                                {t('common:with_cryptocurrency')}
                            </div>
                            <div className="radio-button__descr">
                                {t('common:pay_with_cryptocurrency')}
                            </div>
                        </div>
                    </button>
                    {/*<button className="radio-button radio-button--big" type="button">*/}
                    {/*    <div className="radio-button__picture">*/}
                    {/*        <UsdDarkIcon/>*/}
                    {/*    </div>*/}
                    {/*    <div className="radio-button__text">*/}
                    {/*        <div className="radio-button__title">*/}
                    {/*            {t('common:bank_transfer_in')} USD*/}
                    {/*        </div>*/}
                    {/*        <div className="radio-button__descr">*/}
                    {/*            {t('common:by_requisites')}*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</button>*/}
                    {/*<button className="radio-button radio-button--big" type="button">*/}
                    {/*    <div className="radio-button__picture">*/}
                    {/*        <EuroDarkIcon/>*/}
                    {/*    </div>*/}
                    {/*    <div className="radio-button__text">*/}
                    {/*        <div className="radio-button__title">*/}
                    {/*            {t('common:bank_transfer_in')} EUR*/}
                    {/*        </div>*/}
                    {/*        <div className="radio-button__descr">*/}
                    {/*            {t('common:by_requisites')}*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</button>*/}
                </div>
            </form>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.authReducer.authToken.isFullyAuthenticated(),
        tokensLoaded: state.tokenReducer.tokensLoaded,
        tokens: state.tokenReducer.tokens
    }
}

export default connect(mapStateToProps)(Basket)
