import React from 'react'
import ClosePopupIcon from '../../components/UI/Icons/ClosePopupIcon/ClosePopupIcon'
import Modal from 'react-modal'

Modal.setAppElement('#root')

interface IProps {
    children: React.ReactNode,
    visible: boolean,
    close: () => void,
    classes?: string,
    footer?: React.ReactNode,
}

type Props = IProps

const ModalWindow: React.FC<Props> = (props: Props) => {
    const {children, visible, close, footer} = props

    const classes = props.classes ? `popup is-visible ${props.classes}` : `popup is-visible`

    return (
        <Modal
            isOpen={visible}
            // onAfterOpen={afterOpenModal}
            onRequestClose={close}
            closeTimeoutMS={300}
            overlayClassName="popup-container__inner"
            className={classes}
            parentSelector={() => document.querySelector('.popup-container__inner')}
        >
            <>
                <div className="popup__close" onClick={close}>
                    <ClosePopupIcon/>
                </div>
                {children}
            </>
        </Modal>
    )
}


export default ModalWindow
