import React from 'react'
import {useTranslation} from 'react-i18next'
import MobileOperationsListItem from '../../components/Operations/MobileOperationsListItem/MobileOperationsListItem'
import OperationsListEmpty from '../../components/Operations/OperationsListEmpty/OperationsListEmpty'
import OperationsListItem from '../../components/Operations/OperationsListItem/OperationsListItem'
import PreLoader from '../../components/UI/PreLoader/PreLoader'
import {connect} from 'react-redux'
import {IOperation} from '../../model/Interfaces'
import MainLayout from '../../hoc/MainLayout/MainLayout'

interface IProps {
    operationsLoaded: boolean,
    operations: [],
}

const OperationsPage: React.FC<IProps> = (props: IProps) => {
    const {t, i18n} = useTranslation()

    if (!props.operationsLoaded) {
        return (
            <MainLayout>
                <PreLoader/>
            </MainLayout>
        )
    }

    if (!props.operations.length) {
        return (
            <MainLayout>
                <OperationsListEmpty/>
            </MainLayout>
        )
    }

    return (
        <MainLayout>
            <main className="transactions-page">
                <div className="transactions-page__inner">
                    <table className="table table--grey transactions-page__table transactions-page__table--mobile">
                        <thead>
                            <tr>
                                <th>{t('common:col_date')}/{t('common:col_type')}</th>
                                <th>{t('common:col_amount_in')}</th>
                                <th>{t('common:col_status')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.operations.map((operation: IOperation, i: number) => {
                                    return (
                                        <MobileOperationsListItem
                                            key={'mob-operation-item-' + i + Math.random()}
                                            operation={operation}
                                        />
                                    )
                                })
                            }
                        </tbody>
                    </table>

                    <table className="table table--grey transactions-page__table transactions-page__table--desktop">
                        <thead>
                            <tr>
                                <th>{t('common:col_date')}</th>
                                <th>{t('common:col_type')}</th>
                                <th>{t('common:col_quantity')}</th>
                                <th>{t('common:col_amount_in')}</th>
                                <th>{t('common:col_status')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.operations.map((operation: IOperation, i: number) => {
                                    return (
                                        <OperationsListItem
                                            key={'operation-item-' + i + Math.random()}
                                            operation={operation}
                                        />
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </main>
        </MainLayout>
    )
}

function mapStateToProps(state) {
    return {
        operationsLoaded: state.operationReducer.operationsLoaded,
        operations: state.operationReducer.operations
    }
}

export default connect(mapStateToProps)(OperationsPage)
