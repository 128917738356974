import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import {LANGUAGE_STORAGE_NAME} from './store/actions/Security/Auth/auth'
import translationCommonEn from './translations/en/common.json'
import translationCommonRu from './translations/ru/common.json'
import translationLandingEn from './translations/en/landing.json'
import translationLandingRu from './translations/ru/landing.json'
import translationPicturesEn from './translations/en/pictures.json'
import translationPicturesRu from './translations/ru/pictures.json'
import translationSettingsEn from './translations/en/settings.json'
import translationSettingsRu from './translations/ru/settings.json'
import translationSecurityEn from './translations/en/security.json'
import translationSecurityRu from './translations/ru/security.json'

const resources = {
    en: {
        landing: translationLandingEn,
        common: translationCommonEn,
        pictures: translationPicturesEn,
        settings: translationSettingsEn,
        security: translationSecurityEn,
    },
    ru: {
        landing: translationLandingRu,
        common: translationCommonRu,
        pictures: translationPicturesRu,
        settings: translationSettingsRu,
        security: translationSecurityRu,
    }
};

let lang = localStorage.getItem(LANGUAGE_STORAGE_NAME)
if (!lang || !Object.keys(resources).includes(lang)) {
    lang = 'en'
    localStorage.setItem(LANGUAGE_STORAGE_NAME, lang)
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: lang,
        defaultNS: 'common',
        keySeparator: '.', // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
        }
    })

i18n.changeLanguage(lang)

export default i18n;
