import React from 'react'
import {FeatureFlagName} from '../../model/Enums'

export enum AccessLevel {
    All,
    Unauthorized,
    Authorized
}

type RouteType = {
    component: React.FC,
    path: string,
    access: AccessLevel,
    exact?: boolean,
    featureFlag?: FeatureFlagName
}

export default RouteType
