import AccountNotification, {AccountNotificationDataObject} from './AccountNotification'

export type AccountDataObject = {
    id: string,
    email: string,
    avatar: string,
    phone: string,
    username: string,
    language: string,
    has_password: boolean,
    referral: string,
    notifications: AccountNotificationDataObject,
    google2_fa_enabled: boolean,
    google_connected: boolean,
}

class Account {
    private _id: string
    private _email: string
    private _avatar: string
    private _phone: string
    private _username: string
    private _language: string
    private _hasPassword: boolean
    private _referral: string
    private _notifications: AccountNotification
    private _google2FaEnabled: boolean
    private _googleConnected: boolean

    constructor(data: AccountDataObject) {
        this._id = data.id
        this._email = data.email
        this._avatar = data.avatar
        this._phone = data.phone
        this._username = data.username
        this._language = data.language || 'en'
        this._hasPassword = data.has_password
        this._referral = data.referral
        this._notifications = new AccountNotification(data.notifications)
        this._google2FaEnabled = data.google2_fa_enabled
        this._googleConnected = data.google_connected
    }


    get id(): string {
        return this._id
    }

    get email(): string {
        return this._email
    }

    get avatar(): string {
        return this._avatar
    }

    set avatar(value: string) {
        this._avatar = value
    }

    get phone(): string {
        return this._phone
    }

    set phone(value: string) {
        this._phone = value
    }

    get username(): string {
        return this._username
    }

    set username(value: string) {
        this._username = value
    }

    get language(): string {
        return this._language
    }

    set language(value: string) {
        this._language = value
    }

    get hasPassword(): boolean {
        return this._hasPassword
    }

    get referral(): string {
        return this._referral
    }

    get notifications(): AccountNotification {
        return this._notifications
    }

    get google2FaEnabled(): boolean {
        return this._google2FaEnabled
    }

    get googleConnected(): boolean {
        return this._googleConnected
    }

    toDataObject = (): AccountDataObject => {
        return {
            id: this._id,
            email: this._email,
            avatar: this._avatar,
            phone: this._phone,
            username: this._username,
            language: this._language,
            has_password: this._hasPassword,
            referral: this._referral,
            google2_fa_enabled: this._google2FaEnabled,
            google_connected: this._googleConnected,
            notifications: this._notifications.toDataObject()
        }
    }
}

export default Account
