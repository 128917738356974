import {EnumObjectListType} from '../Types'
import {Technic} from '../Enums'

const TechnicsList:EnumObjectListType = [{
    label: 'oil_on_canvas',
    value: Technic.OIL_ON_CANVAS,
}, {
    label: 'oil_on_cartboard',
    value: Technic.OIL_ON_CARTBOARD,
}, {
    label: 'drip_technique',
    value: Technic.DRIP_TECHNIQUE,
}]

export default TechnicsList
