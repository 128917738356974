import jwt_decode from 'jwt-decode'

type JWTTokenData = {
    exp: number,
    two_fa_required: boolean,
}

class ConfirmationToken {
    private _token: string
    private _expiresAt: Date
    private _isTwoFaRequired: boolean

    constructor(token: string) {
        this._token = token
        const payload = jwt_decode(token) as JWTTokenData
        this._expiresAt = new Date(payload.exp * 1000)
        this._isTwoFaRequired = payload.two_fa_required
    }

    isExpired = (): boolean => {
        return this._expiresAt <= new Date()
    }

    getExpiresIn = (): number => {
        return this._expiresAt.getTime() - new Date().getTime()
    }

    get token(): string {
        return this._token
    }

    get isTwoFaRequired(): boolean {
        return this._isTwoFaRequired;
    }
}

export default ConfirmationToken
