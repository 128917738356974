import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {BUY_TOKENS_PATH, GALLERY_PATH} from '../../../config/routing/paths'

const OperationsListEmpty: React.FC = () => {
    const {t} = useTranslation()

    return (
        <main className="portfolio-page">
            <div className="portfolio-page__inner">
                <div className="portfolio-empty">
                    <svg
                        className="portfolio-empty__icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <div className="portfolio-empty__text">
                        <p>
                            {t('common:you_do_not_have_operations')}
                        </p>
                    </div>
                    <div className="portfolio-empty__buttons">
                        <Link
                            className="button portfolio-empty__button"
                            to={GALLERY_PATH}
                        >
                            {t('common:buttons.go_to_gallery')}
                        </Link>
                        <Link
                            className="button button--bordered portfolio-empty__button"
                            to={BUY_TOKENS_PATH}
                        >
                            {t('common:buttons.buy_tokens')}
                        </Link>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default OperationsListEmpty
