import React from 'react'
import {connect} from 'react-redux'
import PartnersEmpty from '../../components/Partners/PartnersEmpty/PartnersEmpty'
import PartnersList from '../../components/Partners/PartnersList/PartnersList'
import Account from '../../model/Account'
import MainLayout from '../../hoc/MainLayout/MainLayout'

export interface Partner {
    email: string,
    created_at: string,
    level: number,
}

interface IProps {
    partnersLoaded: boolean,
    partners: Partner[],
    account: Account | null,
}

const PartnerPage: React.FC<IProps> = (props: IProps) => {
    const {partnersLoaded} = props

    if (!partnersLoaded || !props.account) {
        return null
    }

    if (!props.partners.length) {
        return (
            <MainLayout>
                <PartnersEmpty referral={props.account.referral}/>
            </MainLayout>
        )
    }

    return (
        <MainLayout>
            <PartnersList
                partners={props.partners}
                referral={props.account.referral}
            />
        </MainLayout>
    )
}

function mapStateToProps(state) {
    return {
        account: state.authReducer.account,
        partnersLoaded: state.partnerReducer.partnersLoaded,
        partners: state.partnerReducer.partners,
    }
}

export default connect(mapStateToProps)(PartnerPage)
