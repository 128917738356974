import React from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useParams} from 'react-router'
import * as cs from 'classnames'
import {SETTINGS_PATH} from '../../../config/routing/paths'
import {TabsList} from '../../../containers/SettingsPage/SettingsPage'
import history from '../../../utils/history'

const Tabs: React.FC = () => {
    const {t} = useTranslation();
    const {tab} = useParams()

    const tabs = [{
        path: TabsList.PROFILE_TAB,
        label: 'settings:titles.tab_first'
    }, {
        path: TabsList.NOTICES_TAB,
        label: 'settings:titles.tab_second'
    }, {
        path: TabsList.SECURITY_TAB,
        label: 'settings:titles.tab_third'
    }]

    return (
        <ul className="tabs-toggle settings-page__tabs-toggle">
            {
                tabs.map((tabItem, i: number) => (
                    <li key={'tab' + i}
                        className={cs('tabs-toggle__item', {'on': tab === tabItem.path})}
                        onClick={() => {
                            history.push(generatePath(SETTINGS_PATH, {tab: tabItem.path}))
                        }}
                    >
                        {t(tabItem.label)}
                    </li>
                ))
            }
        </ul>
    )
}

export default Tabs
