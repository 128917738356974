import React, {useState} from 'react'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import ChangePassword from '../../components/Settings/ChangePassword/ChangePassword'
import Notifications from '../../components/Settings/Notifications/Notifications'
import Profile from '../../components/Settings/Profile/Profile'
import Security from '../../components/Settings/Security/Security'
import Tabs from '../../components/Settings/Tabs/Tabs'
import {logout} from '../../store/actions/Security/Auth/auth'
import MainLayout from '../../hoc/MainLayout/MainLayout'

interface IProps {
    logout: () => void
}

export enum TabsList {
    PROFILE_TAB = 'profile',
    NOTICES_TAB = 'notices',
    SECURITY_TAB = 'security',
}

const SettingsPage: React.FC<IProps> = (props: IProps) => {
    const {tab} = useParams()
    const {t} = useTranslation();

    const [changePass, setChangePass] = useState<boolean>(false)

    if (changePass) {
        return (
            <MainLayout>
                <main className="settings-page">
                    <div className="settings-page__inner">
                        <ChangePassword hideChangePassHandler={() => {setChangePass(false)}}/>
                    </div>
                </main>
            </MainLayout>
        )
    }

    return (
        <MainLayout>
            <main className="settings-page">
                <div className="settings-page__inner">
                    <div className="tabs settings-page__tabs">
                        <Tabs />
                        <div className="tabs__panes settings-page__tabs-panes">
                            { tab === TabsList.PROFILE_TAB ? <Profile /> : null }
                            { tab === TabsList.NOTICES_TAB ? <Notifications /> : null }
                            { tab === TabsList.SECURITY_TAB ? <Security showChangePassHandler={() => {setChangePass(true)}} /> : null }
                        </div>
                    </div>
                </div>
            </main>
        </MainLayout>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout())
    }
}

export default connect(null, mapDispatchToProps)(SettingsPage)
