import {API_PARTNERS} from '../../axios/paths'
import {Partner} from '../../containers/PartnerPage/PartnerPage'
import ApiClient from '../../services/ApiClient'
import {PARTNERS_LOADED} from './partnerTypes'

export const loadPartners = () => {
    return async (dispatch): Promise<void> => {
        const apiClient = ApiClient()

        const response = await apiClient.get(API_PARTNERS)
        const partnersList = response.data

        dispatch(partnersLoaded(partnersList))
    }
}

export const partnersLoaded = (data: Partner[]) => {
    return {
        type: PARTNERS_LOADED,
        payload: data
    }
}
