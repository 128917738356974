import React, {} from 'react'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import Account from '../../../model/Account'
import PreLoader from '../../UI/PreLoader/PreLoader'
import TwoFactorDisabled from '../TwoFactorDisabled/TwoFactorDisabled'
import TwoFactorEnabled from '../TwoFactorEnabled/TwoFactorEnabled'

interface StateProps {
    account: Account | null,
}

interface DispatchProps {
    destroyTwoFactorSecrets: () => void,
}

interface Props {
    showChangePassHandler: () => void
}

type IProps = Props & StateProps & DispatchProps

const TwoFactor: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation();
    const {account} = props

    if (!account) {
        return <PreLoader/>
    }

    const google2FaEnabled = account.google2FaEnabled

    return (
        <div className="form__group">
            <label
                className="form__label form__label--mb-20 form__label--grey"
            >
                {t('settings:titles.tfa')}
            </label>
            { google2FaEnabled ? <TwoFactorEnabled/> : <TwoFactorDisabled/> }
        </div>
    )
}

const mapStateToProps = (state): StateProps => {
    return {
        account: state.authReducer.account,
    }
}

export default connect(mapStateToProps)(TwoFactor)
