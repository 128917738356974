import React, {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {connect} from 'react-redux'
import validator from 'validator'
import {useTranslation} from 'react-i18next'
import Account from '../../../model/Account'
import {changePassword, changePasswordDone} from '../../../store/actions/Security/ChangePassword/changePassword'
import messages from '../../../utils/ValidatorMessages/ValidatorMessages'
import PassDiff, {PassDiffOptions} from '../../../utils/Validators/PassDiff'
import InputPassword from '../../UI/Form/Elements/InputPassword/InputPassword'
import PreLoader from '../../UI/PreLoader/PreLoader'
import SubmitButton from '../../UI/Form/Elements/SubmitButton/SubmitButton'
import TwoFactorCode from '../../UI/Form/Elements/TwoFactorCode/TwoFactorCode'
import {GALLERY_PATH} from '../../../config/routing/paths'
import BackButton from '../../UI/BackButton/BackButton'
import {CodeType} from '../../../model/Types'

interface DispatchProps {
    changePassword: (
        newPassword: string,
        repeatNewPassword: string,
        password: string | null,
        code: string
    ) => void,
    changePasswordDone: () => void
}

type FormData = {
    password: string,
    new_password: string,
    repeat_new_password: string,
}

interface StateProps {
    submitted: boolean,
    error: boolean,
    errors: [],
    account: Account | null,
}

interface Props {
    hideChangePassHandler: () => void
}

type IProps = Props & StateProps & DispatchProps

const ChangePassword: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation();
    const {getValues, handleSubmit, errors, control, reset, setError} = useForm<FormData>()

    const [code, setCode] = useState<CodeType>((new Array(6)).fill(null))
    const [hasError, setHasError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const changeHandler = (code: CodeType) => {
        setCode(code)
        setHasError(false)
        setErrorMessage('')
    }

    useEffect(() => {
        if (props.submitted && !props.error) {
            reset()
            setCode((new Array(6)).fill(null))
            props.changePasswordDone()
            props.hideChangePassHandler()
        }

        if (props.errors.length) {
            props.errors.map((errorItem) => {
                if (!('path' in errorItem) || !('message' in errorItem)) {
                    return
                }
                if (errorItem['path'] === 'code') {
                    setHasError(errorItem['message'])
                    return;
                }
                if (errorItem['path'] === 'password') {
                    setError('password', errorItem['message'])
                }
            })
        }
    }, [props.submitted, props.error, props.errors])

    if (!props.account) {
        return <PreLoader/>
    }

    // if (!props.submitted && props.error) {
    //     debugger
    //     return <div>
    //         {t('security:errors.your_email')}
    //     </div>
    // }
    //
    // if (props.submitted && props.error) {
    //     debugger
    //     return <div>
    //         {t('security:errors.error')}
    //     </div>
    // }
    //
    const google2FaEnabled = props.account.google2FaEnabled
    const onSubmitHandler = (data: FormData) => {
        if (google2FaEnabled && !code.join('')) {
            setHasError(true)
            setErrorMessage(messages.required)
            return
        }

        props.changePassword(
            data.new_password,
            data.repeat_new_password,
            props.account?.hasPassword ? data.password : null,
            code.join('')
        )
    }

    const account = props.account

    return (
        <div className="settings-page__center">
            <div className="prev-step">
                <BackButton url={GALLERY_PATH} callback={props.hideChangePassHandler}/>
            </div>
            <h1 className="settings-page__title settings-page__title--center">
                {t('common:titles.change_password')}
            </h1>
            <div className="settings-page__descr">
                <p>
                    {t('common:create_new_password_for_your_account')}
                </p>
            </div>
            <form className="form" onSubmit={handleSubmit(onSubmitHandler)} autoComplete="chrome-off">
                {
                    account.hasPassword
                        ? (
                            <Controller
                                name="password"
                                label={t('security:form.old_password')}
                                defaultValue=""
                                error={errors.password}
                                as={InputPassword}
                                control={control}
                                rules={{
                                    required: t(messages.required).toString(),
                                }}
                            />

                        )
                        : null
                }
                <Controller
                    name="new_password"
                    label={t('security:form.pick_password')}
                    defaultValue=""
                    error={errors.new_password}
                    blockClasses={['form__group--mb-10']}
                    as={InputPassword}
                    control={control}
                    rules={{
                        required: t(messages.required).toString(),
                        minLength: {value: 8, message: t(messages.minLength, {count: 8}).toString()},
                        validate: value =>
                            PassDiff(value, [
                                PassDiffOptions.lowerCase,
                                PassDiffOptions.upperCase,
                                PassDiffOptions.specChars,
                                PassDiffOptions.digits,
                            ])
                            || t(messages.passwordDifficulty).toString()
                    }}
                />
                <div className="form__group">
                    <ul className="form__list">
                        <li>— {t('security:form.at_least_characters', {count: 8})}</li>
                        <li>— {t('security:form.upper_lower_letters')}</li>
                        <li>— {t('security:form.numbers')}</li>
                        <li>— {t('security:form.special_characters')}</li>
                    </ul>
                </div>
                <Controller
                    name="repeat_new_password"
                    label={t('security:form.repeat_new_password')}
                    defaultValue=""
                    error={errors.repeat_new_password}
                    as={InputPassword}
                    control={control}
                    rules={{
                        required: t('security:errors.required_field').toString(),
                        validate: value =>
                            validator.equals(value, getValues('new_password'))
                            || t(messages.strictEqual, {fields: t('security:form.passwords')}).toString()
                    }}
                />

                {
                    google2FaEnabled
                        ?
                        <>
                            <div>
                                <p>
                                    {t('settings:texts.enter_tfa_6_digit_code')}
                                </p>
                            </div>
                            <TwoFactorCode
                                code={code}
                                onChange={changeHandler}
                                error={hasError}
                                errorMessage={errorMessage}
                                bindFocus={false}
                            />
                        </>
                    : null
                }

                <div className="form__buttons form__buttons--center">
                    <SubmitButton className="button form__button">
                        {t('common:buttons.confirm')}
                    </SubmitButton>
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = (state): StateProps => {
    return {
        submitted: state.changePasswordReducer.submitted,
        error: state.changePasswordReducer.error,
        errors: state.changePasswordReducer.errors,
        account: state.authReducer.account,
    }
}

const mapDispatchToProps = (dispatch): DispatchProps => {
    return {
        changePassword: (
            newPassword: string,
            repeatNewPassword: string,
            password: string | null,
            code: string
        ) => dispatch(changePassword(newPassword, repeatNewPassword, password, code)),
        changePasswordDone: () => dispatch(changePasswordDone())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
