import React from 'react'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import 'moment/locale/ru'
import {Link} from 'react-router-dom'
import {BUY_TOKENS_PATH, DEPOSIT_PATH, SWAP_PATH, WITHDRAW_PATH} from '../../../config/routing/paths'
import tokensImg from '../../../styles/img/token-img-1.png'
import tokensImg_2x from '../../../styles/img/token-img-1@2x.png'
import {IToken} from '../../../model/Interfaces'
import {FeatureFlagListType} from '../../../model/Types'
import {FeatureFlagName} from '../../../model/Enums'

interface IProps {
    token: IToken,
    quantity: number,
    featureFlagsLoaded: boolean,
    featureFlags: FeatureFlagListType,
}

const WalletListItem: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()
    const {token, quantity} = props

    return (
        <>
            <tr>
                <td>
                    <div className="token-item token-item--middle">
                        <div className="token-item__picture">
                            <img
                                className="token-item__img"
                                src={token.icon || tokensImg}
                                srcSet={`${token.icon2x || tokensImg_2x || tokensImg} 2x`}
                                alt="alt"
                            />
                        </div>
                        <div className="token-item__descr">
                            <div className="token-item__value">
                                {token.name}
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    {quantity}
                </td>
                <td>
                    $ {(quantity * token.currentPrice).toLocaleString()}
                </td>
                <td>
                    {(parseFloat(token.available)).toLocaleString()}
                </td>
                <td>
                    $ {token.currentPrice.toLocaleString()}
                </td>
                <td>
                    <div className="table__buttons">
                        {
                            parseFloat(token.available) > 0
                                ?
                                <Link
                                    className="button table__button"
                                    to={BUY_TOKENS_PATH}
                                >
                                    {t('common:buttons.buy')}
                                </Link>
                                : null
                        }
                        {
                            props.featureFlagsLoaded
                            && props.featureFlags[FeatureFlagName.SWAP]
                                ?
                                <Link to={SWAP_PATH} className="button button--bordered table__button">
                                    {t('common:buttons.swap')}
                                </Link>
                                : null
                        }
                    </div>
                </td>
            </tr>
        </>
    )
}


function mapStateToProps(state) {
    return {
        featureFlagsLoaded: state.featureFlagReducer.featureFlagsLoaded,
        featureFlags: state.featureFlagReducer.featureFlags,
    }
}

export default connect(mapStateToProps)(WalletListItem)
