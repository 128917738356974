export const DEFAULT_COUNT = 1

class BasketItem {
    private _id: string
    private _count: number

    constructor(id: string, count: number = DEFAULT_COUNT) {
        this._id = id
        this._count = count
    }

    get id(): string {
        return this._id
    }

    get count(): number {
        return this._count
    }

    set count(value: number) {
        this._count = value
    }
}

export default BasketItem
