import {IAuthToken} from './Interfaces'

class NullToken implements IAuthToken {
    isFullyAuthenticated = (): boolean => false
    get token(): string {
        return ''
    }
}

export default NullToken
