import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {API_PAINTING_SUBSCRIBE} from '../../axios/paths'
import GalleryFilterSidebar from '../../components/Gallery/GalleryFilterSidebar/GalleryFilterSidebar'
import SuccessModal from '../../components/Modals/SuccessModal/SuccessModal'
import ApiClient from '../../services/ApiClient'
import MainLayout from '../../hoc/MainLayout/MainLayout'
import GalleryItem from '../../components/Gallery/GalleryItem/GalleryItem'
import {connect} from 'react-redux'
import {IFilterState, IPainting} from '../../model/Interfaces'
import {FilterSort} from '../../model/Enums'
import AuthorListType from '../../model/Types/AuthorListType'

interface IProps {
    isAuthenticated: boolean,
    authorsLoaded: boolean,
    paintingsLoaded: boolean,
    authors: AuthorListType,
    paintings: IPainting[],
    filter: IFilterState
}

const sortByPrice = (painting1: IPainting, painting2: IPainting, descOrder: boolean): number => {
    const currentPrice1: number = painting1.artToken?.currentPrice || 0
    const currentPrice2: number = painting2.artToken?.currentPrice || 0

    return (currentPrice1 - currentPrice2) * (descOrder ? -1 : 1)
}

const sortByRecommended = (painting1: IPainting, painting2: IPainting, descOrder: boolean): number => {
    const ordering1: number = painting1.ordering || 0
    const ordering2: number = painting2.ordering || 0

    return (ordering2 - ordering1) * (descOrder ? -1 : 1)
}

const sortAndFilter = (
    paintings: IPainting[],
    authors: AuthorListType,
    filter: IFilterState
): IPainting[] => {
    let items = paintings.filter((painting: IPainting): boolean => {
        const searchString = filter.search_query.trim()
        const authorId = painting.author?.id || null
        const author = authorId ? authors[authorId] : null

        if (searchString !== ''
            && painting.name.toLowerCase().indexOf(searchString) === -1
            && (author === null || author.name.toLowerCase().indexOf(searchString) === -1)
        ) {
            return false
        }

        if (filter.authors.length
            && (!painting.author || !filter.authors.includes(painting.author.id))
        ) {
            return false
        }
        if (filter.genres.length
            && (!painting.parameters.genre || !filter.genres.includes(painting.parameters.genre))
        ) {
            return false
        }
        if (filter.technics.length
            && (!painting.parameters.technic || !filter.technics.includes(painting.parameters.technic))
        ) {
            return false
        }

        if (filter.eras.length
            && (!painting.parameters.eras_and_styles.length
                || !painting.parameters.eras_and_styles.filter((value) => {
                    return filter.eras.includes(value)
                }).length
            )
        ) {
            return false
        }

        if (filter.buy_a_whole && painting.saleId === null) {
            return false
        }

        return true
    })

    items = items.sort((painting1: IPainting, painting2: IPainting) => {
        switch (filter.sort) {
            case FilterSort.SORT_RECOMMENDED_ASC:
                return sortByRecommended(painting1, painting2, true)
            case FilterSort.SORT_PRICE_DESC:
                return sortByPrice(painting1, painting2, true)
            case FilterSort.SORT_PRICE_ASC:
                return sortByPrice(painting1, painting2, false)
            default:
                return sortByPrice(painting1, painting2, true)
        }
    })

    return items
}

const GalleryPage: React.FC<IProps> = (props: IProps) => {
    const {authorsLoaded, paintingsLoaded} = props
    const {t} = useTranslation()
    const [modalVisible, setModalVisible] = useState<boolean>(false)

    // useEffect(() => {
    //     history.location.hash.split('#').map((hash: string) => {
    //         const [key, value] = hash.split(':')
    //         if (key in state) {
    //             state[key] = value
    //         }
    //     })
    // }, [history.location.hash])

    const subscribeHandler = async (paintingId: string) => {
        const apiClient = ApiClient()
        try {
            await apiClient.post(API_PAINTING_SUBSCRIBE(paintingId))
            setModalVisible(true)
        } catch (e) {
            console.log(e)
        }
    }

    if (!authorsLoaded || !paintingsLoaded) {
        return null
    }

    const paintings: IPainting[] = sortAndFilter(props.paintings, props.authors, props.filter)

    return (
        <MainLayout>
            <>
                <main className="gallery-page">
                    <div className="gallery-page__inner">
                        <div className="row">
                            <div className="col-lg-3 col-md-4">
                                <GalleryFilterSidebar />
                            </div>
                            <div className="col-lg-9 col-md-8">
                                <div className="row">
                                    {
                                        paintings.map((painting: IPainting, i: number) => {
                                            const authorId = painting.author?.id || null
                                            const author = authorId ? props.authors[authorId] : null
                                            return (
                                                <GalleryItem
                                                    key={`gallery-item-${i}`}
                                                    item={painting}
                                                    author={author}
                                                    subscribe={subscribeHandler}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

                <SuccessModal
                    visible={modalVisible}
                    close={() => {
                        setModalVisible(false)
                    }}
                    content={t('common:modal.thank_you')}
                />
            </>
        </MainLayout>
    )
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.authReducer.authToken.isFullyAuthenticated(),
        authorsLoaded: state.galleryReducer.authorsLoaded,
        paintingsLoaded: state.galleryReducer.paintingsLoaded,
        authors: state.galleryReducer.authors,
        paintings: state.galleryReducer.paintings,
        filter: state.galleryReducer.filter
    }
}

export default connect(mapStateToProps)(GalleryPage)
