import {EnumObjectListType} from '../Types'
import {Genre} from '../Enums'

const GenresList:EnumObjectListType = [{
    label: 'portrait',
    value: Genre.PORTRAIT,
}, {
    label: 'abstract',
    value: Genre.ABSTRACT,
}, {
    label: 'figurative',
    value: Genre.FIGURATIVE,
}, {
    label: 'landscape',
    value: Genre.LANDSCAPE,
}]

export default GenresList
