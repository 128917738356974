import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import 'moment/locale/ru'
import {generatePath} from 'react-router'
import {OPERATION_PATH} from '../../../config/routing/paths'
import history from '../../../utils/history'
import {ChargeStatus} from '../../../model/Enums'
import {IOperation, IOperationItem} from '../../../model/Interfaces'

interface IProps {
    operation: IOperation,
}

const circumference = 9 * 2 * Math.PI;

const OperationsListItem: React.FC<IProps> = (props: IProps) => {
    const {t, i18n} = useTranslation()
    const {operation} = props

    const expiresAt = moment(operation.charge.expires_at)
    const isStatusCreated = operation.charge.status === ChargeStatus.STATUS_CREATED
    const defaultDuration = isStatusCreated ? moment.duration(expiresAt.diff(moment())) : null
    const [duration, setDuration] = useState<moment.Duration|null>(defaultDuration)

    useEffect(() => {
        if (!isStatusCreated) {
            return
        }

        const t = setTimeout(() => {
            const newDuration = moment.duration(expiresAt.diff(moment()))
            setDuration(newDuration)
        }, 1000)

        return () => {
            clearTimeout(t)
        }
    }, [duration, expiresAt])

    const createdAt = moment(operation.created_at).locale(i18n.language)

    let strokeDasharray = circumference;
    let strokeDashoffset = 0;
    if (isStatusCreated && (!duration || duration.asSeconds() > 0)) {
        const sec = !duration ? 1 : (duration.asSeconds() > 0 ? duration.asSeconds() : 0)
        strokeDasharray = circumference;
        strokeDashoffset = -(circumference - circumference * sec / 3600);
    }

    return (
        <>
            <tr onClick={() => {history.push(generatePath(OPERATION_PATH, {uuid: operation.id}))}}>
                <td>
                    {createdAt.format('D MMM h:mm a')}
                </td>
                <td>
                    {t('common:col_val_buying')}
                </td>
                {/*<td>*/}
                    {/*<div className="token-item token-item--middle">*/}
                    {/*    <div className="token-item__picture">*/}
                    {/*        <img*/}
                    {/*            className="token-item__img"*/}
                    {/*            src="img/token-img-1.png"*/}
                    {/*            srcSet="img/token-img-1@2x.png 2x"*/}
                    {/*            alt="alt"*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*    <div className="token-item__descr">*/}
                    {/*        <div className="token-item__value">INDX</div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                {/*</td>*/}
                <td>
                    {operation.items.reduce((quantity: number, item: IOperationItem) => {
                        return quantity + item.quantity
                    }, 0)}
                </td>
                <td>
                    $ {operation.amount.toLocaleString()}
                </td>
                <td>
                    {
                        isStatusCreated
                        && (!duration || duration.asSeconds() > 0)
                        ?
                            <>
                                <span className="orange-color">
                                    {t('common:col_val_status_awaiting_payment')}
                                </span>
                                { duration && duration.asHours() < 1
                                    ?
                                        <div className="small-timer">
                                            <div className="progress-ring" data-percent="60">
                                                <svg className="progress-ring__svg">
                                                    <circle
                                                        className="progress-ring__circle"
                                                        cx="13"
                                                        cy="13"
                                                        r="9"
                                                    />
                                                    <circle
                                                        className="progress-ring__circle progress-ring__circle-fill"
                                                        cx="13"
                                                        cy="13"
                                                        r="9"
                                                        style={{
                                                            strokeDasharray: strokeDasharray,
                                                            strokeDashoffset: strokeDashoffset
                                                        }}
                                                    />
                                                </svg>
                                            </div>
                                            <div className="small-timer__time">
                                                {moment.utc(duration.asMilliseconds()).format('mm:ss')}
                                            </div>
                                        </div>

                                    : null
                                }
                            </>
                        : null
                    }

                    {
                        operation.charge.status === ChargeStatus.STATUS_EXPIRED
                        || operation.charge.status === ChargeStatus.STATUS_CANCELED
                        || (
                            isStatusCreated
                            && (duration && duration.asSeconds() < 0)
                        )
                            ?
                                <span className="red-color">
                                    {t('common:col_val_status_canceled')}
                                </span>
                            : null
                    }

                    {
                        operation.charge.status === ChargeStatus.STATUS_PENDING
                        || operation.charge.status === ChargeStatus.STATUS_PROCESSING
                            ?
                                <span className="orange-color">
                                    {t('common:col_val_status_processing')}...
                                </span>
                            : null
                    }

                    {
                        operation.charge.status === ChargeStatus.STATUS_SUCCESS
                            ?
                                <span className="green-color">
                                    {t('common:col_val_status_completed')}
                                </span>
                            : null
                    }

                    {
                        operation.charge.status === ChargeStatus.STATUS_REFUND_PENDING
                            ?
                                <span className="orange-color">
                                    {t('common:col_val_status_refund_pending')}
                                </span>
                            : null
                    }

                    {
                        operation.charge.status === ChargeStatus.STATUS_REFUNDED
                            ?
                                <span className="red-color">
                                    {t('common:col_val_status_refund_pending')}
                                </span>
                            : null
                    }
                </td>
                {/*<td>*/}
                {/*    <div className="table-dropdown">*/}
                {/*        <svg className="table-dropdown__icon">*/}
                {/*            <use xlinkHref="img/sprite.svg#arrow-bottom"/>*/}
                {/*        </svg>*/}
                {/*    </div>*/}
                {/*</td>*/}
            </tr>
            {/*<tr className="hidden-tr">*/}
            {/*    <td colSpan={7}>*/}
            {/*        <div className="hidden-tr__inner">*/}
            {/*            <div className="hidden-tr-info">*/}
            {/*                {*/}
            {/*                    operation.charge.status === ChargeStatus.STATUS_SUCCESS*/}
            {/*                        ?*/}
            {/*                            <>*/}
            {/*                                <svg*/}
            {/*                                    className="hidden-tr-info__icon"*/}
            {/*                                    xmlns="http://www.w3.org/2000/svg"*/}
            {/*                                    fill="none"*/}
            {/*                                    viewBox="0 0 24 24"*/}
            {/*                                    stroke="currentColor"*/}
            {/*                                >*/}
            {/*                                    <path*/}
            {/*                                        strokeLinecap="round"*/}
            {/*                                        strokeLinejoin="round"*/}
            {/*                                        strokeWidth="1"*/}
            {/*                                        d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"*/}
            {/*                                    />*/}
            {/*                                </svg>*/}
            {/*                                <p>*/}
            {/*                                    <span className="small-text green-color">*/}
            {/*                                        {t('common:col_val_status_completed')}*/}
            {/*                                    </span>*/}
            {/*                                </p>*/}
            {/*                            </>*/}
            {/*                        :*/}
            {/*                            null*/}
            {/*                }*/}

            {/*                {*/}
            {/*                    operation.charge.status !== ChargeStatus.STATUS_SUCCESS*/}
            {/*                        ?*/}
            {/*                            <svg*/}
            {/*                                className="hidden-tr-info__icon"*/}
            {/*                                xmlns="http://www.w3.org/2000/svg"*/}
            {/*                                fill="none"*/}
            {/*                                viewBox="0 0 24 24"*/}
            {/*                                stroke="currentColor"*/}
            {/*                            >*/}
            {/*                                <path*/}
            {/*                                    strokeLinecap="round"*/}
            {/*                                    strokeLinejoin="round"*/}
            {/*                                    strokeWidth="1"*/}
            {/*                                    d="M20.618 5.984A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016zM12 9v2m0 4h.01"*/}
            {/*                                />*/}
            {/*                            </svg>*/}
            {/*                        :*/}
            {/*                            null*/}
            {/*                }*/}

            {/*                {*/}
            {/*                    operation.charge.status === ChargeStatus.STATUS_CREATED*/}
            {/*                    || operation.charge.status === ChargeStatus.STATUS_PENDING*/}
            {/*                        ?*/}
            {/*                            <p>*/}
            {/*                                {t('common:operation_awaiting')}*/}
            {/*                            </p>*/}
            {/*                        :*/}
            {/*                            null*/}
            {/*                }*/}

            {/*                {*/}
            {/*                    operation.charge.status === ChargeStatus.STATUS_CANCELED*/}
            {/*                        ?*/}
            {/*                            <p>Операция отменена, вышло время для оплаты</p>*/}
            {/*                        :*/}
            {/*                            null*/}
            {/*                }*/}


            {/*                {*/}
            {/*                    operation.charge.status === ChargeStatus.STATUS_CANCELED*/}
            {/*                        ?*/}
            {/*                            <p>Операция отменена</p>*/}
            {/*                        :*/}
            {/*                            null*/}
            {/*                }*/}
            {/*            </div>*/}

            {/*            {*/}
            {/*                operation.charge.status === ChargeStatus.STATUS_CREATED*/}
            {/*                || operation.charge.status === ChargeStatus.STATUS_PENDING*/}
            {/*                    ?*/}
            {/*                        <a*/}
            {/*                            className="button button--bordered button--bordered-red hidden-tr__button"*/}
            {/*                            href="#"*/}
            {/*                        >*/}
            {/*                            {t('common:buttons.cancel_payment')}*/}
            {/*                        </a>*/}
            {/*                    :*/}
            {/*                        null*/}
            {/*            }*/}


            {/*            {*/}
            {/*                operation.charge.status === ChargeStatus.STATUS_SUCCESS*/}
            {/*                    ?*/}
            {/*                        <a className="button button--bordered hidden-tr__button" href="#">*/}
            {/*                            <svg*/}
            {/*                                className="button__icon"*/}
            {/*                                xmlns="http://www.w3.org/2000/svg"*/}
            {/*                                fill="none"*/}
            {/*                                viewBox="0 0 24 24"*/}
            {/*                                stroke="currentColor"*/}
            {/*                            >*/}
            {/*                                <path*/}
            {/*                                    strokeLinecap="round"*/}
            {/*                                    strokeLinejoin="round"*/}
            {/*                                    strokeWidth="1"*/}
            {/*                                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"*/}
            {/*                                />*/}
            {/*                            </svg>*/}
            {/*                            Скачать счёт-фактуру*/}
            {/*                        </a>*/}
            {/*                    :*/}
            {/*                        null*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*    </td>*/}
            {/*</tr>*/}
        </>
    )
}

export default OperationsListItem
