import React from 'react'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {AUTH_PATH, BUY_TOKENS_PATH, DEPOSIT_PATH} from '../../../config/routing/paths'
import tokensImg from '../../../styles/img/token-img-1.png'
import tokensImg_2x from '../../../styles/img/token-img-1@2x.png'
import {IPainting, IToken} from '../../../model/Interfaces'
import TokenChart from '../TokenChart/TokenChart'
import history from '../../../utils/history'
import {FeatureFlagListType} from '../../../model/Types'
import {FeatureFlagName} from '../../../model/Enums'

interface IProps {
    token: IToken,
    painting?: IPainting,
    isAuthenticated: boolean,
    featureFlagsLoaded: boolean,
    featureFlags: FeatureFlagListType,
    subscribe: (id: string) => void,
}

const TokensListItemTablet: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()
    const {token, painting} = props

    return (
        <>
            <tr>
                <td>
                    <div className="token-item token-item--middle">
                        <div className="token-item__picture">
                            <img
                                className="token-item__img"
                                src={token.icon || tokensImg}
                                srcSet={`${token.icon2x ? token.icon2x : tokensImg_2x} 2x'`}
                                alt={token.name}
                            />
                        </div>
                        <div className="token-item__descr">
                            <div className="token-item__value">
                                {token.name}
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    $ {(token.currentPrice * parseFloat(token.emission)).toLocaleString()}
                </td>
                <td>
                    {(parseFloat(token.emission) - parseFloat(token.available)).toLocaleString()}
                </td>
                <td>
                    {(parseFloat(token.available)).toLocaleString()}
                </td>
                <td>
                    $ {token.currentPrice}
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <TokenChart token={token} painting={painting} withModal={true}/>
                </td>
                <td colSpan={3}>
                    {
                        parseFloat(token.available) > 0
                            ? <Link
                                className="button button--stretch"
                                to={BUY_TOKENS_PATH}
                            >
                                {t('common:buttons.buy')}
                            </Link>
                            : <a
                                className="button button--stretch"
                                href="#"
                                onClick={(e: React.MouseEvent) => {
                                    e.preventDefault()
                                    if (props.isAuthenticated) {
                                        props.subscribe(token.paintingId)
                                        return
                                    }
                                    history.push(AUTH_PATH)
                                }}
                            >
                                {t('common:buttons.report_on_arrival')}
                            </a>
                    }
                    {
                        props.featureFlagsLoaded
                        && props.featureFlags[FeatureFlagName.DEPOSIT]
                            ?
                            <Link
                                to={DEPOSIT_PATH}
                                className="button button--bordered table__button"
                            >
                                {t('common:buttons.deposit')}
                            </Link>
                            : null
                    }
                </td>
            </tr>

        </>
    )
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.authReducer.authToken.isFullyAuthenticated(),
        featureFlagsLoaded: state.featureFlagReducer.featureFlagsLoaded,
        featureFlags: state.featureFlagReducer.featureFlags,
    }
}

export default connect(mapStateToProps)(TokensListItemTablet)
