import React, {useEffect, useRef, useState} from 'react'
import {connect} from 'react-redux'
import Tokens from '../../model/Tokens'
import MainLayout from '../../hoc/MainLayout/MainLayout'
import solanaLogo from '../../styles/img/crypto-icon-solana.png'
import solanaLogo_2x from '../../styles/img/crypto-icon-solana@2x.png'
import * as cs from 'classnames'
import ApiClient from '../../services/ApiClient'
import {API_ACCOUNT_GET_WALLET} from '../../axios/paths'
import WalletType from '../../model/Enums/WalletType'
import WalletAddressType from '../../model/Types/WalletAddressType'
import {useTranslation} from 'react-i18next'

interface IProps {
    tokensLoaded: boolean,
    tokens: Tokens | null,
}
const wallets: {[key: string]: string} = {}

const DepositPage: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()
    const [copied, setCopied] = useState<boolean>(false)
    const [currentWallet, setCurrentWallet] = useState<string|null>(null)
    const copyBtnRef = useRef<HTMLButtonElement>(null)

    const loadWalletAddress = async (walletType: WalletType) => {
        const address: string|null = wallets[walletType] || null
        if (address) {
            setCurrentWallet(address)
        }
        const apiClient = ApiClient()
        try {
            const response = await apiClient.get<WalletAddressType>(API_ACCOUNT_GET_WALLET(walletType))
            wallets[walletType] = response.data.address

            setCurrentWallet(wallets[walletType])
        } catch (e) {
            console.log(e)
            throw e
        }
    }

    useEffect(() => {
        if (!copied) {
            return
        }

        const timeout = setTimeout(() => {
            setCopied(false)
        }, 1000)

        return () => {
            clearTimeout(timeout)
        }
    }, [copyBtnRef, copied])

    const copyClickHandler = (event: React.MouseEvent) => {
        event.preventDefault()
        if (copied || currentWallet === null) {
            return
        }
        navigator.clipboard.writeText(currentWallet)
        setCopied(true)
    }

    return (
        <MainLayout>
            <main className="operations-page">
                <div className="operations-page__inner">
                    <h1 className="operations-page__title">
                        {t('common:titles.tokens_deposit')}
                    </h1>
                    <div className="withdrawal-info__header">
                        <h2 className="withdrawal-info__title">
                            {t('common:deposit.sub_title')}
                        </h2>
                    </div>
                    <form className="form operations-page__form">
                        <div className="form__group form__group--mb-10">
                            <div className="row row--10">
                                <div className="col-12 col--10">
                                    <button
                                        className="radio-button radio-button--crypto"
                                        type="button"
                                        onClick={() => {
                                            loadWalletAddress(WalletType.SOLANA)
                                        }}
                                    >
                                        <img
                                            className="radio-button__img"
                                            src={solanaLogo}
                                            srcSet={`${solanaLogo_2x} 2x`}
                                            alt="alt"
                                        />
                                        <div className="radio-button__text">
                                            <div className="radio-button__title">Solana</div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="form__group">
                            <p className="text-justify">
                                {t('common:deposit.description')}
                            </p>
                        </div>
                        {
                            currentWallet
                            ?
                                <div className="form qr-copy-form">
                                    <div className="form__group">
                                        <input
                                            className="form__input form__input--bordered"
                                            type="text"
                                            placeholder=""
                                            value={currentWallet || ''}
                                            readOnly
                                        />
                                    </div>
                                    <div className="form__buttons">
                                        <button
                                            className={cs('button form__button', {'button--bordered': copied})}
                                            type="button"
                                            onClick={copyClickHandler}
                                            ref={copyBtnRef}
                                        >
                                            {t(copied ? 'copied' : 'copy')}
                                        </button>
                                    </div>
                                </div>
                            : null
                        }
                    </form>
                </div>
            </main>
        </MainLayout>
    )
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.authReducer.authToken.isFullyAuthenticated(),
        tokensLoaded: state.tokenReducer.tokensLoaded,
        tokens: state.tokenReducer.tokens
    }
}

export default connect(mapStateToProps)(DepositPage)
