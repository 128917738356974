import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import TwoFactorSecrets from '../../../model/TwoFactorSecrets'
import {
    activateTwoFactorCode,
    destroyTwoFactorSecrets,
    loadSecrets
} from '../../../store/actions/Security/TwoFactorSetup/twoFactorSetup'
import googlePlayBtnImg from '../../../styles/img/google-play-button.svg'
import appStoreBtnImg from '../../../styles/img/app-store-button.svg'
import qrCodeImg from '../../../styles/img/qr-button.svg'
import {connect} from 'react-redux'
import TwoFactorCode from '../../UI/Form/Elements/TwoFactorCode/TwoFactorCode'
import ModalWindow from '../../../hoc/ModalWindow/ModalWindow'
import InfoSuccessIcon from '../../UI/Icons/InfoSuccessIcon/InfoIcon'
import InfoWarningIcon from '../../UI/Icons/InfoWarningIcon/InfoWarningIcon'
import InfoErrorIcon from '../../UI/Icons/InfoErrorIcon/InfoErrorIcon'
import PreLoader from '../../UI/PreLoader/PreLoader'
import {CodeType} from '../../../model/Types'
import Account from '../../../model/Account'
import * as cs from 'classnames'

interface IProps {
    visible: boolean,
    close: () => void,
}

interface IStateProps {
    twoFactorSecrets: TwoFactorSecrets | null,
    account: Account | null,
}

interface IDispatchProps {
    activateTwoFactorCode: (
        code: string,
        onSuccess: () => void,
        onError: () => void
    ) => void,
    loadSecrets: () => void,
    destroyTwoFactorSecrets: () => void,
}

type Props = IProps & IStateProps & IDispatchProps

const TwoFactorSetupModal: React.FC<Props> = (props: Props) => {
    const {t} = useTranslation()

    const [code, setCode] = useState<CodeType>((new Array(6)).fill(null))
    const [hasError, setHasError] = useState<boolean>(false)
    const [inProgress, setInProgress] = useState<boolean>(false)
    const [copied, setCopied] = useState<boolean>(false)
    const copyBtnRef = useRef<HTMLButtonElement>(null)

    const {twoFactorSecrets, loadSecrets, destroyTwoFactorSecrets, account} = props
    const needToLoadSecrets = !twoFactorSecrets && props.visible && !account?.google2FaEnabled && !inProgress
    useEffect(() => {
        if (!needToLoadSecrets) {
            return
        }
        loadSecrets()
    }, [needToLoadSecrets])

    useEffect(() => {
        if (!copied) {
            return
        }

        const timeout = setTimeout(() => {
            setCopied(false)
        }, 1000)

        return () => {
            clearTimeout(timeout)
        }
    }, [copyBtnRef, copied])

    if (!props.account || !!account?.google2FaEnabled) {
        return null
    }

    inProgress && hasError && setInProgress(false)

    const changeHandler = (code: CodeType) => {
        setCode(code)
    }

    const reset = () => {
        setCode((new Array(6)).fill(null))
        setHasError(false)
        setInProgress(false)
    }

    const onSuccess = () => {
        reset()
        props.close()
    }

    const onError = () => {
        setHasError(true)
        setInProgress(false)
    }

    const submitCodeHandler = (event: React.FormEvent) => {
        event.preventDefault()
        if (inProgress) {
            return
        }

        setInProgress(true)
        props.activateTwoFactorCode(code.join(''), onSuccess, onError)
    }

    const onCloseHandler = () => {
        reset()
        destroyTwoFactorSecrets()
        props.close()
    }

    if (twoFactorSecrets === null) {
        return props.visible ? <PreLoader/> : null
    }

    const copyClickHandler = (event: React.MouseEvent) => {
        event.preventDefault()
        if (copied) {
            return
        }
        navigator.clipboard.writeText(twoFactorSecrets.secret)
        setCopied(true)
    }

    return (
        <ModalWindow
            visible={props.visible}
            close={onCloseHandler}
        >
            <div className="popup__body">
                <div className="popup__text popup__text--left">
                    <h2>{t('settings:titles.tfa')}</h2>
                </div>
                <div className="popup__auth-steps">
                    <div className="auth-step">
                        <div className="auth-step__number">1</div>
                        <div className="auth-step__descr">
                            <p>{t('settings:texts.install_tfa_app')}</p>
                        </div>
                        <div className="auth-step__buttons">
                            <a
                                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                                target="_blank"
                                rel="noreferrer"
                                className="auth-step__button"
                            >
                                <img
                                    className="auth-step__button-img"
                                    src={googlePlayBtnImg}
                                    alt="Google Play"
                                />
                            </a>
                            <a
                                href="https://apps.apple.com/ru/app/google-authenticator/id388497605"
                                target="_blank"
                                rel="noreferrer"
                                className="auth-step__button"
                            >
                                <img
                                    className="auth-step__button-img"
                                    src={appStoreBtnImg}
                                    alt="AppStore"
                                />
                            </a>
                        </div>
                    </div>
                    <div className="auth-step">
                        <div className="auth-step__number">2</div>
                        <div className="auth-step__descr">
                            <p>
                                {t('settings:texts.scan_qr_code')}
                            </p>
                        </div>
                        <div className="form qr-copy-form">
                            <div className="form__group">
                                <input
                                    className="form__input form__input--bordered"
                                    type="text"
                                    placeholder=""
                                    value={twoFactorSecrets.secret}
                                    readOnly
                                />
                            </div>
                            <div className="form__buttons">
                                <button
                                    className={cs('button form__button', {'button--bordered': copied})}
                                    type="button"
                                    onClick={copyClickHandler}
                                    ref={copyBtnRef}
                                >
                                    {t(copied ? 'copied' : 'copy')}
                                </button>
                            </div>
                        </div>
                        <div className="auth-step__info">
                            <InfoErrorIcon/>
                            <p>
                                {t('common:backup_this_code')}
                            </p>
                        </div>
                        <picture className="auth-step__qr-picture">
                            <img
                                className="auth-step__qr-img"
                                src={`data:image/png;base64,${twoFactorSecrets.qrCode}`}
                                alt={twoFactorSecrets.secret}
                            />
                        </picture>
                    </div>
                    <div className="auth-step">
                        <div className="auth-step__number">3</div>
                        <div className="auth-step__descr">
                            <p>
                                {t('settings:texts.enter_tfa_code')}
                            </p>
                        </div>
                        <form className="form popup__form">
                            <TwoFactorCode
                                code={code}
                                onChange={changeHandler}
                                error={hasError}
                            />
                            <div className="form__buttons form__buttons--mb-0">
                                <button
                                    className="button button--small button--bordered form__button"
                                    type="button"
                                    onClick={submitCodeHandler}
                                >
                                    {t('settings:buttons.confirm')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </ModalWindow>
    )
}

const mapStateToProps = (state): IStateProps => {
    return {
        twoFactorSecrets: state.twoFactorSetupReducer.twoFactorSecrets,
        account: state.authReducer.account,
    }
}

const mapDispatchToProps = (dispatch): IDispatchProps => {
    return {
        activateTwoFactorCode: (
            code: string,
            onSuccess: () => void,
            onError: () => void
        ) => dispatch(activateTwoFactorCode(code, onSuccess, onError)),
        loadSecrets: () => dispatch(loadSecrets()),
        destroyTwoFactorSecrets: () => dispatch(destroyTwoFactorSecrets()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorSetupModal)
