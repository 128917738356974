import React from 'react'
import sprite from '../../../../styles/img/sprite.svg'

interface IProps {
    className?: string,
}

const FilterArrow: React.FC<IProps> = (props: IProps) => (
    <svg className={`${props.className || ''}`} width="16" height="9">
        <use xlinkHref={`${sprite}#filter-arrow`}/>
    </svg>
)

export default FilterArrow
