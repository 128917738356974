import React from 'react'
import {useTranslation} from 'react-i18next'
import ModalWindow from '../../../hoc/ModalWindow/ModalWindow'
import {IOperation} from '../../../model/Interfaces'

interface IProps {
    visible: boolean,
    close: () => void,
    operation: IOperation,
}

const QrCodeModal: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()
    const {visible, close, operation} = props

    return (
        <ModalWindow
            visible={visible}
            close={close}
        >
            <div className="popup__body">
                <div className="popup__text">
                    <p className="p-small">{t('common:amount')}</p>
                    <p>
                        {operation.charge.amount} {operation.charge.currency} <span className="green-color">
                            ($ {operation.amount})
                        </span>
                    </p>
                    <p className="p-small">{t('common:address')} {operation.charge.currency}</p>
                    <p className="p-dots p-small">{operation.charge.address}</p>
                </div>
                <picture className="popup__qr-picture">
                    <img
                        className="popup__qr-img"
                        src={`data:image/png;base64,${operation.charge.qr_code}`}
                        srcSet={`data:image/png;base64,${operation.charge.qr_code2x} 2x`}
                        alt="qr_code"
                    />
                </picture>
            </div>
        </ModalWindow>
    )
}

export default QrCodeModal
