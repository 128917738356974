import React, {useState} from 'react'
import {render} from 'react-dom'
import {renderToString} from 'react-dom/server'
import {Trans, useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {GALLERY_PATH, STATIC_COOKIES_POLICY} from '../../../config/routing/paths'

const COOKIE_STORAGE_NAME = 'agreed-with-cookie'

const CookiesAgreement: React.FC = () => {
    const {t} = useTranslation()

    const [agreed, setAgreed] = useState<boolean>(!!localStorage.getItem(COOKIE_STORAGE_NAME))

    if (agreed) {
        return null
    }

    const handleClick = (event: React.MouseEvent): void => {
        event.preventDefault()
        localStorage.setItem(COOKIE_STORAGE_NAME, `1`)
        setAgreed(true)
    }

    return (
        <div className="cookie">
            <div className="cookie__inner">
                <div className="cookie__text">
                    <p>
                        <Trans
                            i18nKey={'common:we_use_cookie'}
                        >
                            ~ <Link to={STATIC_COOKIES_POLICY}> ~ </Link> ~
                        </Trans>
                    </p>
                </div>
                <button
                    className="main-button cookie__button"
                    type="button"
                    onClick={handleClick}
                >OK</button>
            </div>
        </div>
    )
}

export default CookiesAgreement
