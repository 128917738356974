import {API_OPERATIONS} from '../../axios/paths'
import ApiClient from '../../services/ApiClient'
import {RENEW_DATA} from './actionTypes'
import {OPERATIONS_LOADED} from './operationTypes'

export const loadOperations = () => {
    return async (dispatch): Promise<void> => {
        const apiClient = ApiClient()

        const response = await apiClient.get(API_OPERATIONS)
        const operationsList = response.data

        dispatch(operationsLoaded(operationsList))
    }
}

export const operationsLoaded = (data: []) => {
    return {
        type: OPERATIONS_LOADED,
        payload: data
    }
}

export const reloadOperations = () => {
    return {
        type: RENEW_DATA
    }
}
