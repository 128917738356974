import React, {useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import validator from 'validator'
import Button from '../../../../components/UI/Form/Elements/Button/Button'
import Input from '../../../../components/UI/Form/Elements/Input/Input'
import {AUTH_PATH} from '../../../../config/routing/paths'
import SecurityLayout from '../../../../hoc/SecurityLayout/SecurityLayout'
import {
    requestResetPasswordDone,
    resetPasswordRequest
} from '../../../../store/actions/Security/ResetPassword/resetPasswordRequest'
import messages from '../../../../utils/ValidatorMessages/ValidatorMessages'

type FormData = {
    email: string
}

interface DispatchProps {
    requestResetPassword: (email: string) => void,
    requestResetPasswordDone: () => void
}

interface StateProps {
    submitted: boolean,
    error: boolean
}

type PropsType = StateProps & DispatchProps

const ResetPasswordRequest: React.FC<PropsType> = (props: PropsType) => {
    const {handleSubmit, errors, control, reset} = useForm<FormData>()

    const requestResetPasswordHandler = (data: FormData) => {
        props.requestResetPassword(data.email)
    }

    const onSubmitHandler = (event) => {
        event.preventDefault()
    }

    useEffect(() => {
        if (props.submitted && !props.error) {
            reset()
            props.requestResetPasswordDone()
        }
    }, [props.submitted, props.error])

    const {t} = useTranslation()

    return (
        <SecurityLayout>
            <>
                <div className="login-page__body">
                    <h1 className="login-page__title login-page__title--sm-mb-20">
                        {t('security:titles.access_recovery')}
                    </h1>
                    <div className="login-page__descr">
                        <p>
                            {t('security:texts.access_recovery')}
                        </p>
                    </div>
                    <form className="form login-page__form" onSubmit={onSubmitHandler} autoComplete="chrome-off">
                        <Controller
                            name="email"
                            label={t('security:form.your_email')}
                            defaultValue=""
                            error={errors.email}
                            as={Input}
                            control={control}
                            rules={{
                                required: t(messages.required).toString(),
                                validate: value =>
                                    validator.isEmail(value)
                                    || t(messages.isEmail).toString()
                            }}
                        />
                        <div className="form__buttons form__buttons--center">
                            <Button
                                onClick={handleSubmit(requestResetPasswordHandler)}
                                className="button form__button"
                                disabled={false}
                            >
                                {t('security:buttons.proceed')}
                            </Button>
                        </div>
                    </form>
                </div>
                <div className="login-page__bottom">
                    <div className="login-info">
                        <span>
                            {t('security:texts.remembered_password')}
                        </span>
                        &nbsp;
                        <Link
                            className="login-info__link"
                            to={AUTH_PATH}
                        >
                            {t('security:buttons.sign_in')}
                        </Link>
                    </div>
                </div>
            </>
        </SecurityLayout>
    )
}

const mapStateToProps = (state): StateProps => {
    return {
        submitted: state.resetPasswordRequestReducer.submitted,
        error: state.resetPasswordRequestReducer.error
    }
}

const mapDispatchToProps = (dispatch): DispatchProps => {
    return {
        requestResetPassword: (email: string) => dispatch(resetPasswordRequest(email)),
        requestResetPasswordDone: () => dispatch(requestResetPasswordDone())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordRequest)
