import {Action} from 'redux'
import {
    REQUEST_RESET_PASSWORD_DONE,
    REQUEST_RESET_PASSWORD_FAILED,
    REQUEST_RESET_PASSWORD_SUCCESS
} from '../../../actions/Security/ResetPassword/actionTypes'

export type RequestResetPasswordState = {
    submitted: boolean,
    error: boolean
}

const initialState = {
    submitted: false,
    error: false
}

const resetPasswordRequestReducer = (
    state: RequestResetPasswordState = initialState,
    action: Action
): RequestResetPasswordState => {
    switch (action.type) {
        case REQUEST_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                submitted: true,
                error: false
            }
        case REQUEST_RESET_PASSWORD_FAILED:
            return {
                ...state,
                submitted: true,
                error: true
            }
        case REQUEST_RESET_PASSWORD_DONE:
            return {
                ...state,
                submitted: false,
                error: false
            }
        default:
            return state
    }
}

export default resetPasswordRequestReducer
