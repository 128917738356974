import React from 'react'
import history from '../../../utils/history'
import LeftIcon from '../Icons/LeftIcon/LeftIcon'
import {useTranslation} from 'react-i18next'

interface IProps {
    url: string,
    callback?: () => void,
}

const BackButton: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()

    const onClickHandler = (event: React.MouseEvent): void => {
        if (props.callback) {
            event.preventDefault()
            props.callback()
            return
        }

        if (history.length > 2 || history.location.state) {
            event.preventDefault()
            history.goBack()
            return
        }
    }

    return (
        <a
            href={props.url}
            onClick={onClickHandler}
            className="prev-step__link"
        >
            <LeftIcon/>
            <span className="prev-step__title">
                {t('common:buttons.back')}
            </span>
        </a>
    )
}

export default BackButton
