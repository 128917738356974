import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {
    GALLERY_PATH,
    MAIN_PATH,
    STATIC_COOKIES_POLICY,
    STATIC_PRIVACY_POLICY,
    STATIC_TERMS_OF_USE
} from '../../../config/routing/paths'
import {landingMenuSwitchVisibility} from '../../../store/actions/overlay'
import {LANGUAGE_STORAGE_NAME} from '../../../store/actions/Security/Auth/auth'
import LandingFooterLogoIcon from '../../UI/Icons/LandingFooterLogoIcon/LandingFooterLogoIcon'
import {connect} from 'react-redux'

interface IProps {
    hideMenu: () => void,
}

const Footer: React.FC<IProps> = (props: IProps) => {
    const {t, i18n} = useTranslation()

    const handleAnchorClick = (event: React.MouseEvent) => {
        event.preventDefault()
        props.hideMenu()
        const link: HTMLAnchorElement = event.target as HTMLAnchorElement
        const id: string | null = link.getAttribute('href')
        if (!id) {
            return
        }

        const elem = document.getElementById(id.substring(1))
        if (!elem) {
            return
        }

        const yOffset = 94
        const y = elem.getBoundingClientRect().top + window.pageYOffset - yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
    }

    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-12 footer__col">
                        <div className="main-logo footer__logo">
                            <Link className="main-logo__link" to={MAIN_PATH}>
                                <LandingFooterLogoIcon/>
                            </Link>
                        </div>
                        <nav className="footer-nav">
                            <ul className="footer-nav__list">
                                <li className="footer-nav__item">
                                    <Link className="footer-nav__link" to={STATIC_PRIVACY_POLICY}>
                                        {t('landing:buttons.privacy_policy')}
                                    </Link>
                                </li>
                                <li className="footer-nav__item">
                                    <Link className="footer-nav__link" to={STATIC_TERMS_OF_USE}>
                                        {t('landing:buttons.terms_of_use')}
                                    </Link>
                                </li>
                                <li className="footer-nav__item">
                                    <Link className="footer-nav__link" to={STATIC_COOKIES_POLICY}>
                                        {t('landing:buttons.cookie_policy')}
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                        <div className="footer__copyright">&copy; Aloha Art Gallery 2021</div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        hideMenu: () => dispatch(landingMenuSwitchVisibility(false)),
    }
}

export default connect(null, mapDispatchToProps)(Footer)
