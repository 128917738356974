import React, {useEffect, useRef, useState} from 'react'
import * as cs from 'classnames'

export type SelectOption = {
    name: string,
    icon?: string,
    icon2x?: string,
}

interface PropsInterface {
    options: {[key: string]: SelectOption},
    onChange?: (val: string) => void,
    selected?: string | null,
    disabled?: string[]
}

const Select = (props: PropsInterface) => {
    let selected = props.selected || null
    const [active, setActive] = useState<boolean>(false)
    const ulElement = useRef<HTMLUListElement>(null)

    const keys = Object.keys(props.options)
    if (selected === null || !(selected in props.options)) {
        selected = keys[0]
    }

    const onClickHandler = (val: string) => {
        selected = val
        props.onChange && props.onChange(val)
    }

    const name = props.options[selected].name
    const icon = props.options[selected].icon
    const icon2x = props.options[selected].icon2x || props.options[selected].icon

    return (
        <div className={cs('dropdown', {'is-active': active})} onClick={() => {setActive(!active)}}>
            <button className="dropdown-button" type="button">
                {
                    icon
                        ?
                            <div className="select-token">
                                <img className="select-token__img"
                                     src={icon}
                                     srcSet={`${icon2x} 2x`}
                                     alt={name}
                                />
                            </div>
                        : null
                }
                <span>{props.options[selected].name}</span>
            </button>
            <div className={cs('dropdown-menu', {'is-visible': active})}>
                <ul className="dropdown-menu__list" ref={ulElement}>
                    {
                        keys.map((elementId: string) => {
                            const name = props.options[elementId].name
                            const icon = props.options[elementId].icon
                            const icon2x = props.options[elementId].icon2x || props.options[elementId].icon

                            return (
                                <li
                                    className={cs('dropdown-menu__item', {
                                        'is-disabled': props.disabled && props.disabled.includes(elementId)
                                    })}
                                    data-value={elementId}
                                    key={elementId}
                                    onClick={() => {
                                        if (props.disabled && props.disabled.includes(elementId)) {
                                            return
                                        }

                                        onClickHandler(elementId)
                                    }}
                                >
                                    {
                                        icon
                                            ?
                                                <div className="select-token">
                                                    <img className="select-token__img"
                                                         src={icon}
                                                         srcSet={`${icon2x} 2x`}
                                                         alt={name}
                                                    />
                                                </div>
                                            : null
                                    }
                                    <span>{props.options[elementId].name}</span>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}

export default Select
