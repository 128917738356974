import {Action} from 'redux'
import {
    ACCOUNT_RECOVERY_CONFIRM_FAILED,
    ACCOUNT_RECOVERY_CONFIRM_SUCCESS
} from '../../../actions/Security/AccountRecovery/actionTypes'

export type AccountRecoveryConfirmState = {
    error: boolean
}

const initialState = {
    error: false
}

const accountRecoveryConfirmReducer = (
    state: AccountRecoveryConfirmState = initialState,
    action: Action
): AccountRecoveryConfirmState => {
    switch (action.type) {
        case ACCOUNT_RECOVERY_CONFIRM_SUCCESS:
            return {
                ...state,
                error: false
            }
        case ACCOUNT_RECOVERY_CONFIRM_FAILED:
            return {
                ...state,
                error: true
            }
        default:
            return state
    }
}

export default accountRecoveryConfirmReducer
