import React from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, Link} from 'react-router-dom'
import {AUTH_PATH, BUY_TOKENS_PATH, PAINTING_PATH} from '../../../config/routing/paths'
import {connect} from 'react-redux'
import history from '../../../utils/history'
import CartIcon from '../../UI/Icons/CartIcon/CartIcon'
import {IAuthor, IPainting} from '../../../model/Interfaces'
import ArtToken from '../../../model/ArtToken'

interface IProps {
    item: IPainting,
    author: IAuthor | null,
    isAuthenticated: boolean,
    subscribe: (id: string) => void,
}

const GalleryItem: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()

    const item = props.item
    const artToken: ArtToken | null = item.artToken
    const availableAmount = artToken
        ? parseFloat(artToken.available)
        : 0
    const emissionAmount = artToken
        ? parseFloat(artToken.emission)
        : 0

    const currentPrice: number = artToken?.currentPrice || 0

    const paintingUnderAttribution = !(artToken && artToken.currentPrice > 0)
    const artTokenAvailable = artToken && !paintingUnderAttribution && availableAmount > 0

    const currentPriceString = currentPrice ? `$ ${currentPrice.toLocaleString()}` : ''

    return (
        <div className="col-lg-4 col-sm-6 gallery-page__col">
            <div className="gallery-card">
                <Link to={generatePath(PAINTING_PATH, {uuid: item.id})} className="gallery-card__link">
                    <picture className="gallery-card__picture">
                        <img
                            className="gallery-card__img"
                            src={item.mainImage?.small}
                            srcSet={`${item.mainImage?.small2x} 2x`}
                            alt={t(item.name || 'pictures:texts.unknown')}
                        />
                    </picture>
                </Link>
                <div className="gallery-card__descr">
                    <h3 className="gallery-card__title">
                        {
                            item.saleId
                            ? <CartIcon className="gallery-card__title-icon"/>
                            : null
                        }
                        <span>{t(item.name || 'pictures:texts.unknown')}</span>
                    </h3>
                    <div className="gallery-card__author">
                        {t(props.author?.name || 'pictures:texts.author_unknown')}
                    </div>
                    <div className="gallery-card__available">
                    {
                        !paintingUnderAttribution
                        ? availableAmount.toLocaleString()
                            + ' / '
                            + emissionAmount.toLocaleString()
                            + ' '
                            + t('common:available')
                        : null
                    }
                    </div>
                    <div className="gallery-card__price" dangerouslySetInnerHTML={{
                        __html: !paintingUnderAttribution
                            ? currentPriceString
                            : (item.attrDescription || t('common:picture_under_attribution'))
                    }} />
                    {
                        artTokenAvailable
                            ?
                                <Link
                                    className="button gallery-card__button"
                                    to={props.isAuthenticated ? BUY_TOKENS_PATH : AUTH_PATH}
                                >
                                    {t('common:buttons.invest')}
                                </Link>
                            :
                                <a
                                    className="button gallery-card__button"
                                    href="#"
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault()
                                        if (props.isAuthenticated) {
                                            props.subscribe(item.id)
                                            return
                                        }
                                        history.push(AUTH_PATH)
                                    }}
                                >
                                    {t('common:buttons.report_on_arrival')}
                                </a>
                    }
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.authReducer.authToken.isFullyAuthenticated(),
    }
}

export default connect(mapStateToProps)(GalleryItem)
