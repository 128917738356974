import React from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import callbackUrl from '../../../config/routing/callbackUrl'
import {MAIN_PATH} from '../../../config/routing/paths'
import tokenImg1 from '../../../styles/img/token-img-1.png'
import tokenImg1_2x from '../../../styles/img/token-img-1@2x.png'

interface IProps {
    referral: string
}

const PartnersEmpty: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation();
    const referralLink = callbackUrl(MAIN_PATH, {
        referral: props.referral
    })
    const copyLinkClickHandler = (event: React.MouseEvent) => {
        event.preventDefault()
        navigator.clipboard.writeText(referralLink)
    }

    return (
        // Operations Main
        <main className="operations-page">
            <div className="operations-page__inner">
                <div className="get-bonus get-bonus--white">
                    <h2 className="get-bonus__title">
                        {t('common:titles.bonuses_received')}
                    </h2>
                    <div className="token-item token-item--big get-bonus__token get-bonus__token--center">
                        <div className="token-item__picture">
                            <img
                                className="token-item__img"
                                src={tokenImg1}
                                srcSet={tokenImg1_2x + ' 2x'}
                                alt="alt"
                            />
                        </div>
                        <div className="token-item__descr">
                            <div className="token-item__value">
                                0<span className="token-item__unit">INDX</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="referral referral--white">
                    <form className="form referral__form">
                        <div className="form__group">
                            <div className="form__info">
                                <svg
                                    className="form__info-icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path fillRule="evenodd"
                                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                          clipRule="evenodd"
                                    />
                                </svg>
                                <p>
                                    {t('common:you_have_no_partners')}
                                    {t('common:invite_more_people')}
                                </p>
                            </div>
                        </div>
                        <div className="form__group">
                            <input
                                className="form__input form__input--bordered"
                                type="text"
                                name="inputLink"
                                placeholder=""
                                value={referralLink}
                            />
                        </div>
                        <div className="form__buttons form__buttons--center">
                            <button
                                className="button form__button form__button--wide"
                                onClick={copyLinkClickHandler}
                            >
                                {t('common:copy_referral_link')}
                            </button>
                        </div>
                    </form>
                    <div className="referral__descr">
                        <p>
                            {t('common:send_your_personalized_link')}
                        </p>
                        <p>
                            <a href="#referral_program_terms_and_conditions">
                                {t('common:referral_program_terms_and_conditions')}
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </main>
    )
}

function mapStateToProps(state) {
    return {
        partnersLoaded: state.partnerReducer.partnersLoaded,
        partners: state.partnerReducer.partners,
    }
}

export default connect(mapStateToProps)(PartnersEmpty)
