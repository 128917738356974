import React from 'react'
import sprite from '../../../../styles/img/sprite.svg'

const ClosePopupIcon: React.FC = () => (
    <svg className="popup__close-icon">
        <use xlinkHref={sprite + '#close'}/>
    </svg>
)

export default ClosePopupIcon
