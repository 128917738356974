enum CryptoPaymentMethod {
    BITCOIN = 'BTC',
    BITCOIN_CASH = 'BCH',
    ETHEREUM = 'ETH',
    LITECOIN = 'LTC',
    DAI = 'DAI',
    USDCOIN = 'USDC',
}


export default CryptoPaymentMethod
