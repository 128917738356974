import PropTypes from 'prop-types'
import React from 'react'
import Header from "../../components/Layout/Header/Header"
import {connect} from 'react-redux'
import SupportLink from '../../components/UI/SupportLink/SupportLink'
import {matchPath, useLocation} from 'react-router-dom'
import {MAIN_PATH} from '../../config/routing/paths'

interface IProps {
    children: React.ReactNode,
    isAuthenticated: boolean,
}

const MainLayout: React.FC<IProps> = (props: IProps) => {
    const location = useLocation()

    return (
        <>
            <Header isAuthenticated={props.isAuthenticated}/>
            <SupportLink isColored={!!matchPath(location.pathname, {path: MAIN_PATH, exact: true})}/>
            {props.children}
        </>

    )
}

MainLayout.propTypes = {
    children: PropTypes.element.isRequired
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.authReducer.authToken.isFullyAuthenticated(),
    }
}

export default connect(mapStateToProps, null)(MainLayout)
