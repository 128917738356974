import React from 'react'
import {Link} from 'react-router-dom'
import {AUTH_PATH, WALLET_PATH} from '../../../config/routing/paths'
import {useTranslation} from 'react-i18next'

interface IProps {
    isAuthenticated: boolean,
}

const Main: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()

    return (
        <section className="start-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="start-section__descr">
                            <h1 className="start-section__title">
                                {t('landing:titles.h1')}
                            </h1>
                            <div className="start-section__text">
                                <p>{t('landing:texts.we_open_up_the_opportunity')}</p>
                            </div>
                            <Link
                                className="main-button start-section__button"
                                to={props.isAuthenticated ? WALLET_PATH : AUTH_PATH}
                            >
                                {t('landing:buttons.start_investing')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Main
