import * as cs from 'classnames'
import React, {useRef} from 'react'
import {FieldError} from 'react-hook-form'

interface IProps {
    label: string,
    error: FieldError | undefined,
    blockClasses?: [string],
    onChange?: (checked: boolean) => void,
    onBlur?: (event: MouseEvent) => void,
    value?: boolean,
}

const Checkbox = React.forwardRef<HTMLInputElement, IProps>((props: IProps, ref) => {
    const htmlFor = `checkbox-${Math.random()}`
    const inputRef = useRef<HTMLInputElement>(null)

    const blockClasses = cs(props.blockClasses, 'form__group', {'has-error': !!props.error})
    const label: string = props.error?.message || props.label || ''

    const onBlurHandler = event => props.onBlur && props.onBlur(event)
    const onChangeHandler = ({target}) => props.onChange && props.onChange(target.checked)

    return (
        <div className={blockClasses} ref={ref}>
            <div className="checkbox">
                <input
                    className="checkbox__input"
                    type="checkbox"
                    id={htmlFor}
                    value="1"
                    checked={props.value}
                    ref={inputRef}
                    onChange={onChangeHandler}
                    onBlur={onBlurHandler}
                />
                <label
                    htmlFor={htmlFor}
                    className="checkbox__label"
                >{label}</label>
            </div>
        </div>
    )
})

export default Checkbox
