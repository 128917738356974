enum ChargeStatus {
    STATUS_CREATED = 'created',
    STATUS_PENDING = 'pending',
    STATUS_PROCESSING = 'processing',
    STATUS_EXPIRED = 'expired',
    STATUS_SUCCESS = 'success',
    STATUS_CANCELED = 'canceled',
    STATUS_REFUND_PENDING = 'refund_pending',
    STATUS_REFUNDED = 'refunded',
}

export default ChargeStatus
