import React from 'react'
import * as cs from 'classnames'
import {connect} from 'react-redux'
import {hideOverlay} from '../../../store/actions/overlay'

interface IProps {
    isActive: boolean,
    hideOverlay: () => void,
}

const DarkOverlay: React.FC<IProps> = (props: IProps) => {
    return (
        <div className={cs('main-dark-bg', {'is-visible': props.isActive})} onClick={props.hideOverlay}/>
    )
}

const mapStateToProps = (state) => {
    return {
        isActive: state.overlayReducer.overlayVisible
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hideOverlay: () => dispatch(hideOverlay()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DarkOverlay)

