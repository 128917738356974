import {EnumObjectListType} from '../Types'
import {Era} from '../Enums'

const ErasList:EnumObjectListType = [{
    label: 'renaissance',
    value: Era.RENAISSANCE,
}, {
    label: 'baroque',
    value: Era.BAROQUE,
}, {
    label: 'expressionism',
    value: Era.EXPRESSIONISM,
}, {
    label: 'abstract_expressionism',
    value: Era.ABSTRACT_EXPRESSIONISM,
}, {
    label: 'abstract_art',
    value: Era.ABSTRACT_ART,
}, {
    label: 'color_field_painting',
    value: Era.COLOR_FIELD_PAINTING,
}, {
    label: 'unknown',
    value: Era.UNKNOWN,
}]

export default ErasList
