import {API_SYSTEM_FEATURE_FLAGS_STATE} from '../../axios/paths'
import ApiClient from '../../services/ApiClient'
import {FEATURE_FLAGS_LOADED} from './featureFlagsTypes'
import {IFeatureFlag} from '../../model/Interfaces'
import {FeatureFlagListType} from '../../model/Types'

export const loadFeatureFlags = () => {
    return async (dispatch): Promise<void> => {
        const apiClient = ApiClient()

        const response = await apiClient.get(API_SYSTEM_FEATURE_FLAGS_STATE)
        const featureFlagsList = {}
        const jsonData = response.data
        jsonData.map((featureFlag: IFeatureFlag) => {
            featureFlagsList[featureFlag.name] = featureFlag.enabled
        })

        dispatch(featureFlagsLoaded(featureFlagsList))
    }
}

export const featureFlagsLoaded = (data: FeatureFlagListType) => {
    return {
        type: FEATURE_FLAGS_LOADED,
        payload: data
    }
}
