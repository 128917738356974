import React from 'react'

const InfoSuccessIcon: React.FC = () => (
    <svg
        className="form__info-icon"
        width="16"
        height="12"
        fill="none"
        version="1.1"
        viewBox="0 0 16 12"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="m14.98-0.0097656a1.0001 1.0001 0 0 0-0.6875 0.30273l-9.293 9.293-3.293-3.293a1.0001 1.0001 0 1 0-1.4141 1.4141l4 4a1.0001 1.0001 0 0 0 1.4141 0l10-10a1.0001 1.0001 0 0 0-0.72656-1.7168z"
            color="#000000"
            colorRendering="auto"
            dominantBaseline="auto"
            fill="#56ce62"
            imageRendering="auto"
            shapeRendering="auto"
        />
    </svg>
)

export default InfoSuccessIcon
