import {combineReducers} from 'redux'

import accountRecoveryConfirmReducer from './Security/AccountRecovery/accountRecoveryConfirmReducer'
import accountRecoveryRequestReducer from './Security/AccountRecovery/accountRecoveryRequestReducer'
import authReducer from './Security/Auth/auth'
import changePasswordReducer from './Security/ChangePassword/changePasswordReducer'
import registerUserReducer from './Security/Registration/registerUserReducer'
import registrationConfirmReducer from './Security/Registration/registrationConfirmReducer'
import registrationReferralReducer from './Security/Registration/registrationReferralReducer'
import removeAccountReducer from './Security/RemoveAccount/removeAccount'
import resetPasswordConfirmReducer from './Security/ResetPassword/resetPasswordConfirmReducer'
import resetPasswordRequestReducer from './Security/ResetPassword/resetPasswordRequestReducer'
import twoFactorSetupReducer from './Security/TwoFactorSetup/twoFactorSetupReducer'
import balanceReducer from './balanceReducer'
import featureFlagReducer from './featureFlagReducer'
import galleryReducer from './galleryReducer'
import operationReducer from './operationReducer'
import overlayReducer from './overlayReducer'
import partnerReducer from './partnerReducer'
import tokenReducer from './tokenReducer'

export default combineReducers({
    accountRecoveryRequestReducer,
    accountRecoveryConfirmReducer,
    authReducer,
    changePasswordReducer,
    registerUserReducer,
    registrationConfirmReducer,
    registrationReferralReducer,
    removeAccountReducer,
    resetPasswordConfirmReducer,
    resetPasswordRequestReducer,
    twoFactorSetupReducer,
    balanceReducer,
    featureFlagReducer,
    galleryReducer,
    operationReducer,
    overlayReducer,
    partnerReducer,
    tokenReducer
})
