import ArtToken, {ArtTokenDataObject} from './ArtToken'
import IndexToken, {IndexTokenDataObject} from './IndexToken'
import {IToken} from './Interfaces'

export type TokensDataObject = {
    index_token: IndexTokenDataObject,
    art_tokens: ArtTokenDataObject[],
}

class Tokens {
    private _indexToken: IndexToken
    private _artTokens: ArtToken[]

    constructor(data: TokensDataObject) {
        this._indexToken = new IndexToken(data.index_token)
        this._artTokens = data.art_tokens.map((artTokenDataObject: ArtTokenDataObject) => {
            return new ArtToken(artTokenDataObject)
        })
    }

    get indexToken(): IndexToken {
        return this._indexToken
    }

    get artTokens(): ArtToken[] {
        return this._artTokens
    }

    mappedArtTokens = () => {
        const mappedArtTokens: {[key: string]: ArtToken} = {}
        this._artTokens.map((artToken: ArtToken) => {
            mappedArtTokens[artToken.id] = artToken
        })

        return mappedArtTokens
    }

    mappedTokens = () => {
        const mappedTokens: {[key: string]: IToken} = this.mappedArtTokens()
        mappedTokens[this.indexToken.id] = this.indexToken

        return mappedTokens
    }

    toDataObject = (): TokensDataObject => {
        return {
            index_token: this._indexToken.toDataObject(),
            art_tokens: this._artTokens.map((artToken: ArtToken) => {
                return artToken.toDataObject()
            }),
        }
    }
}

export default Tokens
