import {Dispatch} from 'redux'
import axios from '../../../../axios/axios-backend'
import {API_ACCOUNT_RECOVERY_REQUEST} from '../../../../axios/paths'
import callbackUrl from '../../../../config/routing/callbackUrl'
import {ACCOUNT_RECOVERY_CONFIRM_PATH, ACCOUNT_RECOVERY_DONE_PATH} from '../../../../config/routing/paths'
import history from '../../../../utils/history'
import {REQUEST_ACCOUNT_RECOVERY_FAILED, REQUEST_ACCOUNT_RECOVERY_SUCCESS} from './actionTypes'
import {LANGUAGE_STORAGE_NAME} from '../Auth/auth'

export type AccountRecoveryResponse = {
    resend_available_at: number
}

export const ACCOUNT_RECOVERY_RESEND_NAME = 'accRecoveryResend'


export const accountRecoverySendRequest = () => async (dispatch: Dispatch): Promise<void> => {
    try {
        const locale = localStorage.getItem(LANGUAGE_STORAGE_NAME)
        const {data}: {data: AccountRecoveryResponse} = await axios.post(
            API_ACCOUNT_RECOVERY_REQUEST + '?_locale=' + locale,
            {
                callback_url: callbackUrl(ACCOUNT_RECOVERY_CONFIRM_PATH, {
                    token: '%s'
                })
            }
        )
        localStorage.setItem(ACCOUNT_RECOVERY_RESEND_NAME, JSON.stringify(data))
        dispatch(accountRecoveryRequestSuccess())
    } catch (e) {
        console.log(e)
        dispatch(accountRecoveryRequestFailed())
    }
}

const accountRecoveryRequestSuccess = () => {
    history.push(ACCOUNT_RECOVERY_DONE_PATH)

    return {
        type: REQUEST_ACCOUNT_RECOVERY_SUCCESS
    }
}

const accountRecoveryRequestFailed = () => {
    return {
        type: REQUEST_ACCOUNT_RECOVERY_FAILED
    }
}
