import axios, {AxiosInstance} from 'axios'
import axiosWrapper from '../axios/axios-backend'
import Config from '../config/config'
import {logout} from '../store/actions/Security/Auth/auth'
import store from '../store/store'

const ApiClient = (errorFallback?: (error) => boolean): AxiosInstance => {

    const processError = (error) => {
        console.log(error)
    }

    const createClient = (): AxiosInstance => {
        const client = axios.create({
            baseURL: Config.apiBaseUrl,
        })

        client.defaults.headers.common['Authorization'] = axiosWrapper.defaults.headers.common['Authorization']

        return client
    }

    const client = createClient()
    client.interceptors.response.use(response => response, (error) => {
        if (error.response.status === 401) {
            store.dispatch(logout())
        }

        if (errorFallback && !errorFallback(error)) {

            return Promise.reject(error)
        }

        processError(error)
        return Promise.reject(error)
    })

    return client
}

export default ApiClient
