import Logout from '../../components/Logout/Logout'
import BasketPage from '../../containers/BasketPage/BasketPage'
import DashboardPage from '../../containers/DashboardPage/DashboardPage'
import GalleryPage from '../../containers/GalleryPage/GalleryPage'
import LandingPage from '../../containers/LandingPage/LandingPage'
import OperationPage from '../../containers/OperationPage/OperationPage'
import OperationsPage from '../../containers/OperationsPage/OperationsPage'
import PaintingPage from '../../containers/PaintingPage/PaintingPage'
import PartnerPage from '../../containers/PartnerPage/PartnerPage'
import WalletPage from '../../containers/WalletPage/WalletPage'
import CookiesPolicyPage from '../../containers/StaticPages/CookiesPolicyPage/CookiesPolicyPage'
import PrivacyPolicyPage from '../../containers/StaticPages/PrivacyPolicyPage/PrivacyPolicyPage'
import RemoveAccountPage from '../../containers/RemoveAccountPage/RemoveAccountPage'
import AccountRecoveryConfirm
    from '../../containers/Security/AccountRecovery/AccountRecoveryConfirm/AccountRecoveryConfirm'
import AccountRecoveryDone from '../../containers/Security/AccountRecovery/AccountRecoveryDone/AccountRecoveryDone'
import AccountRecoveryRequest
    from '../../containers/Security/AccountRecovery/AccountRecoveryRequest/AccountRecoveryRequest'
import Auth from '../../containers/Security/Auth/Auth'
import Registration from '../../containers/Security/Registration/Registration/Registration'
import RegistrationConfirm from '../../containers/Security/Registration/RegistrationConfirm/RegistrationConfirm'
import RegistrationDone from '../../containers/Security/Registration/RegistrationDone/RegistrationDone'
import RegistrationStart from '../../containers/Security/Registration/RegistrationStart/RegistrationStart'
import ResetPasswordConfirm from '../../containers/Security/ResetPassword/ResetPasswordConfirm/ResetPasswordConfirm'
import ResetPasswordRequest from '../../containers/Security/ResetPassword/ResetPasswordRequest/ResetPasswordRequest'
import ResetPasswordRequestDone
    from '../../containers/Security/ResetPassword/ResetPasswordRequestDone/ResetPasswordRequestDone'
import SettingsPage from '../../containers/SettingsPage/SettingsPage'
import TermsPage from '../../containers/StaticPages/TermsPage/TermsPage'
import SupportPage from '../../containers/SupportPage/SupportPage'
import TokenListPage from '../../containers/TokensPage/TokensPage'
import {
    ACCOUNT_RECOVERY_CONFIRM_PATH,
    ACCOUNT_RECOVERY_DONE_PATH,
    ACCOUNT_RECOVERY_REQUEST_PATH,
    AUTH_PATH,
    TOKENS_PATH, BUY_TOKENS_PATH,
    GALLERY_PATH,
    LOGOUT_PATH,
    MAIN_PATH, OPERATIONS_PATH,
    PAINTING_PATH, PARTNERS_PATH, WALLET_PATH,
    REGISTRATION_CONFIRM_PATH,
    REGISTRATION_DONE_PATH,
    REGISTRATION_PATH,
    REGISTRATION_START_PATH,
    REMOVE_ACCOUNT_PATH,
    RESET_PASSWORD_CONFIRM_PATH,
    RESET_PASSWORD_REQUEST_DONE_PATH,
    RESET_PASSWORD_REQUEST_PATH,
    SETTINGS_PATH,
    STATIC_PRIVACY_POLICY, STATIC_TERMS_OF_USE, STATIC_COOKIES_POLICY,
    SUPPORT_CONTACT_US, OPERATION_PATH, SUPPORT_FAQ, DEPOSIT_PATH, WITHDRAW_PATH, SWAP_PATH,
} from './paths'
import RouteType, {AccessLevel} from './routeType'
import FaqPage from '../../containers/FaqPage/FaqPage'
import DepositPage from '../../containers/DepositPage/DepositPage'
import WithdrawalPage from '../../containers/WithdrawalPage/WithdrawalPage'
import SwapPage from '../../containers/SwapPage/SwapPage'
import {FeatureFlagName} from '../../model/Enums'
import BetaPage from "../../containers/BetaPage/BetaPage"

const Routes: RouteType[] =[{
    component: PrivacyPolicyPage,
    path: STATIC_PRIVACY_POLICY,
    access: AccessLevel.All,
},{
    component: TermsPage,
    path: STATIC_TERMS_OF_USE,
    access: AccessLevel.All
},{
    component: CookiesPolicyPage,
    path: STATIC_COOKIES_POLICY,
    access: AccessLevel.All
},{
    component: SupportPage,
    path: SUPPORT_CONTACT_US,
    access: AccessLevel.All
},{
    component: FaqPage,
    path: SUPPORT_FAQ,
    access: AccessLevel.All
}, {
    component: BetaPage,
    path: AUTH_PATH,
    access: AccessLevel.Unauthorized
}, {
    component: RegistrationStart,
    path: REGISTRATION_START_PATH,
    access: AccessLevel.Unauthorized,
    exact: true
}, {
    component: RegistrationDone,
    path: REGISTRATION_DONE_PATH,
    access: AccessLevel.Unauthorized,
    exact: true
}, {
    component: RegistrationConfirm,
    path: REGISTRATION_CONFIRM_PATH,
    access: AccessLevel.Unauthorized,
    exact: true
}, {
    component: Registration,
    path: REGISTRATION_PATH,
    access: AccessLevel.Unauthorized,
    exact: true
}, {
    component: ResetPasswordRequest,
    path: RESET_PASSWORD_REQUEST_PATH,
    access: AccessLevel.Unauthorized,
    exact: true
}, {
    component: ResetPasswordRequestDone,
    path: RESET_PASSWORD_REQUEST_DONE_PATH,
    access: AccessLevel.Unauthorized,
    exact: true
}, {
    component: ResetPasswordConfirm,
    path: RESET_PASSWORD_CONFIRM_PATH,
    access: AccessLevel.Unauthorized,
    exact: true
}, {
    component: LandingPage,
    path: MAIN_PATH,
    access: AccessLevel.All,
    exact: true
}, {
    component: WalletPage,
    path: WALLET_PATH,
    access: AccessLevel.Authorized,
    exact: true
}, {
    component: GalleryPage,
    path: GALLERY_PATH,
    access: AccessLevel.All,
    exact: true
}, {
    component: PaintingPage,
    path: PAINTING_PATH,
    access: AccessLevel.All,
    exact: true
}, {
    component: TokenListPage,
    path: TOKENS_PATH,
    access: AccessLevel.Authorized,
    exact: true
}, {
    component: OperationsPage,
    path: OPERATIONS_PATH,
    access: AccessLevel.Authorized,
    exact: true
}, {
    component: OperationPage,
    path: OPERATION_PATH,
    access: AccessLevel.Authorized,
    exact: true
}, {
    component: BasketPage,
    path: BUY_TOKENS_PATH,
    access: AccessLevel.Authorized,
    exact: true
}, {
    component: DepositPage,
    path: DEPOSIT_PATH,
    access: AccessLevel.Authorized,
    featureFlag: FeatureFlagName.DEPOSIT,
    exact: true,
}, {
    component: WithdrawalPage,
    path: WITHDRAW_PATH,
    access: AccessLevel.Authorized,
    featureFlag: FeatureFlagName.WITHDRAW,
    exact: true,
}, {
    component: SwapPage,
    path: SWAP_PATH,
    access: AccessLevel.Authorized,
    featureFlag: FeatureFlagName.SWAP,
    exact: true
}, {
    component: PartnerPage,
    path: PARTNERS_PATH,
    access: AccessLevel.Authorized,
    exact: true
}, {
    component: AccountRecoveryRequest,
    path: ACCOUNT_RECOVERY_REQUEST_PATH,
    access: AccessLevel.Authorized,
    exact: true
}, {
    component: AccountRecoveryConfirm,
    path: ACCOUNT_RECOVERY_CONFIRM_PATH,
    access: AccessLevel.Authorized,
    exact: true
}, {
    component: AccountRecoveryDone,
    path: ACCOUNT_RECOVERY_DONE_PATH,
    access: AccessLevel.Authorized,
    exact: true
}, {
    component: RemoveAccountPage,
    path: REMOVE_ACCOUNT_PATH,
    access: AccessLevel.Authorized
}, {
    component: Logout,
    path: LOGOUT_PATH,
    access: AccessLevel.Authorized
}, {
    component: SettingsPage,
    path: SETTINGS_PATH,
    access: AccessLevel.Authorized
}]

export default Routes
