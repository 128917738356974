import moment from 'moment'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {API_PAINTING_SUBSCRIBE} from '../../axios/paths'
import SuccessModal from '../../components/Modals/SuccessModal/SuccessModal'
import PreLoader from '../../components/UI/PreLoader/PreLoader'
import {connect} from 'react-redux'
import Tokens from '../../model/Tokens'
import ApiClient from '../../services/ApiClient'
import tokensAboutImg from '../../styles/img/tokens-about-img.png'
import tokensAboutImg_2x from '../../styles/img/tokens-about-img@2x.png'
import 'moment/locale/ru'
import {IPainting} from '../../model/Interfaces'
import MainLayout from '../../hoc/MainLayout/MainLayout'
import TokensListMobile from '../../components/Tokens/TokensListMobile/TokensListMobile'
import TokensListTablet from '../../components/Tokens/TokensListTablet/TokensListTablet'
import TokensListDesktop from '../../components/Tokens/TokensListDesktop/TokensListDesktop'

interface IProps {
    isAuthenticated: boolean,
    tokensLoaded: boolean,
    tokens: Tokens | null,
    paintingsLoaded: boolean,
    paintings: IPainting[],
}

const TokensPage: React.FC<IProps> = (props: IProps) => {
    const {t, i18n} = useTranslation()
    const [modalVisible, setModalVisible] = useState<boolean>(false)

    const subscribeHandler = async (paintingId: string) => {
        const apiClient = ApiClient()
        try {
            await apiClient.post(API_PAINTING_SUBSCRIBE(paintingId))
            setModalVisible(true)
        } catch (e) {
            console.log(e)
        }
    }

    if (!props.tokensLoaded || props.tokens === null) {
        return <PreLoader/>
    }
    if (!props.paintingsLoaded) {
        return <PreLoader/>
    }

    const indexToken = props.tokens.indexToken
    const artTokens = props.tokens.artTokens
    const mappedPaintings = {}
    props.paintings.map((painting: IPainting) => {
        mappedPaintings[painting.id] = painting
    })

    return (
        <MainLayout>
            <main className="tokens-page">
                <div className="tokens-page__inner">
                    <div className="tokens-about">
                        <div className="row tokens-about__row">
                            <div className="col-lg-2 col-sm-4 order-sm-1 tokens-about__col">
                                <picture className="tokens-about__picture">
                                    <img className="tokens-about__img"
                                        src={tokensAboutImg}
                                        srcSet={tokensAboutImg_2x + ' 2x'}
                                        alt="alt"
                                    />
                                </picture>
                            </div>
                            <div className="col-lg-3 col-sm-8 order-sm-2 tokens-about__col">
                                <h2 className="tokens-about__title">
                                    {t('common:titles.index_token_is_branded_token')}
                                </h2>
                            </div>
                            <div className="col-lg-3 col-sm-5 order-sm-4 tokens-about__col">
                                <div className="tokens-about__buy">
                                    <div className="tokens-about__price">
                                        1 INDX = $ {indexToken.currentPrice}
                                    </div>
                                    {/*<Link*/}
                                    {/*    className="button tokens-about__button"*/}
                                    {/*    to={BUY_TOKENS_PATH}>*/}
                                    {/*    {t('common:buttons.buy')} Index Token*/}
                                    {/*</Link>*/}
                                    <div className="tokens-about__date">
                                        {t('common:current_rate_on_date')}&nbsp;
                                        <span className="green-color">
                                            {moment().locale(i18n.language).format('DD MMMM')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-7 order-sm-3 tokens-about__col">
                                <div className="tokens-about__descr">
                                    <p>
                                        {t('common:titles.index_token_is_branded_token')} {t('common:by_buying_it')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TokensListMobile
                        isAuthenticated={props.isAuthenticated}
                        indexToken={indexToken}
                        artTokens={artTokens}
                        paintings={mappedPaintings}
                        subscribe={subscribeHandler}
                    />
                    <TokensListTablet
                        isAuthenticated={props.isAuthenticated}
                        indexToken={indexToken}
                        artTokens={artTokens}
                        paintings={mappedPaintings}
                        subscribe={subscribeHandler}
                    />
                    <TokensListDesktop
                        isAuthenticated={props.isAuthenticated}
                        indexToken={indexToken}
                        artTokens={artTokens}
                        paintings={mappedPaintings}
                        subscribe={subscribeHandler}
                    />
                </div>
                <SuccessModal
                    visible={modalVisible}
                    close={() => {
                        setModalVisible(false)
                    }}
                    content={t('common:modal.thank_you')}
                />
            </main>
        </MainLayout>
    )
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.authReducer.authToken.isFullyAuthenticated(),
        tokensLoaded: state.tokenReducer.tokensLoaded,
        tokens: state.tokenReducer.tokens,
        paintingsLoaded: state.galleryReducer.paintingsLoaded,
        paintings: state.galleryReducer.paintings,
    }
}

export default connect(mapStateToProps)(TokensPage)
