import AccountBalance from '../../model/AccountBalance'
import {DESTROY_DATA, RENEW_DATA} from '../actions/actionTypes'
import {BALANCE_LOADED, RELOAD_BALANCE} from '../actions/balanceTypes'

interface IState {
    balance: AccountBalance | null,
    needRenew: boolean,
}

const initialState: IState = {
    balance: null,
    needRenew: false,
}

const balanceReducer = (
    state: IState = initialState,
    action: {type: string, payload?: AccountBalance}
): IState => {
    switch (action.type) {
        case BALANCE_LOADED:
            return {
                ...state,
                balance: action.payload || null,
                needRenew: false,
            }
        case RELOAD_BALANCE:
            return {
                ...state,
                balance: null,
            }
        case RENEW_DATA:
            return {
                ...state,
                needRenew: true,
            }
        case DESTROY_DATA:
            return {
                ...initialState,
            }
        default:
            return state
    }
}

export default balanceReducer
