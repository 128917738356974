import {DESTROY_DATA} from '../actions/actionTypes'
import {AUTHORS_LOADED, FILTER_CHANGED, PAINTINGS_LOADED} from '../actions/galleryTypes'
import {FilterSort} from '../../model/Enums'
import {IAuthor, IFilterState, IPainting} from '../../model/Interfaces'

interface IOverlayState {
    authorsLoaded: boolean,
    paintingsLoaded: boolean,
    authors: IAuthor[],
    paintings: IPainting[],
    filter: IFilterState
}

const defaultFilterState: IFilterState = {
    search_query: '',
    buy_a_whole: false,
    buy_a_part: false,
    authors: [],
    genres: [],
    technics: [],
    eras: [],
    sort: FilterSort.SORT_RECOMMENDED_ASC,
}

const initialState: IOverlayState = {
    authorsLoaded: false,
    paintingsLoaded: false,
    authors: [],
    paintings: [],
    filter: defaultFilterState
}

const galleryReducer = (
    state: IOverlayState = initialState,
    action: {type: string, payload?: any}
): IOverlayState => {
    switch (action.type) {
        case AUTHORS_LOADED:
            return {
                ...state,
                authorsLoaded: true,
                authors: action.payload || {},
            }
        case PAINTINGS_LOADED:
            return {
                ...state,
                paintingsLoaded: true,
                paintings: action.payload || {},
            }
        case FILTER_CHANGED:
            return {
                ...state,
                filter: action.payload || defaultFilterState,
            }
        case DESTROY_DATA:
            return {
                ...initialState,
            }
        default:
            return state
    }
}

export default galleryReducer
