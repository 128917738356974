import React from 'react'
import {connect} from 'react-redux'
import howItWorksImg1 from '../../../styles/img/main/how-1.svg'
import howItWorksImg2 from '../../../styles/img/main/how-2.svg'
import howItWorksImg3 from '../../../styles/img/main/how-3.svg'
import {useTranslation} from 'react-i18next'

const HowItWork: React.FC = () => {
    const {t} = useTranslation()

    return (
        <section className="how-section" id="how_it_works">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="section-title how-section__title">
                            {t('landing:titles.how_it_works')}
                        </h2>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-6 how-section__col">
                        <div className="how-item">
                            <div className="how-item__pic">
                                <img
                                    className="how-item__icon"
                                    src={howItWorksImg1}
                                />
                                <div className="how-item__number">1</div>
                            </div>
                            <div className="how-item__text">
                                <p dangerouslySetInnerHTML={{__html: t('landing:texts.how_it_works_1')}}/>
                            </div>
                        </div>
                        <div className="how-item">
                            <div className="how-item__pic">
                                <img
                                    className="how-item__icon"
                                    src={howItWorksImg2}
                                />
                                <div className="how-item__number">2</div>
                            </div>
                            <div className="how-item__text">
                                <p dangerouslySetInnerHTML={{__html: t('landing:texts.how_it_works_2')}}/>
                            </div>
                        </div>
                        <div className="how-item">
                            <div className="how-item__pic">
                                <img
                                    className="how-item__icon"
                                    src={howItWorksImg3}
                                />
                                <div className="how-item__number">3</div>
                            </div>
                            <div className="how-item__text">
                                <p dangerouslySetInnerHTML={{__html: t('landing:texts.how_it_works_3')}}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 how-section__col">
                        <div className="how-section__descr">
                            <h3>{t('landing:texts.how_it_works')}</h3>
                            <ul>
                                <li>{t('landing:texts.how_it_works_list_1')}</li>
                                <li>{t('landing:texts.how_it_works_list_2')}</li>
                                <li>{t('landing:texts.how_it_works_list_3')}</li>
                                <li>{t('landing:texts.how_it_works_list_4')}</li>
                                <li>{t('landing:texts.how_it_works_list_5')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.authReducer.authToken.isFullyAuthenticated(),
    }
}
export default connect(mapStateToProps, null)(HowItWork)
