import React, {useState} from 'react'
import FilterArrow from '../../../UI/Icons/FilterArrow/FilterArrow'
import ChoiceItem from '../ChoiceItem/ChoiceItem'
import ChoiceValueType from '../../../../model/Types/ChoiceValueType'
import {IChoiceItem} from '../../../../model/Interfaces'
import * as cs from 'classnames'

interface IProps {
    title: string,
    id: string,
    onChange: (value: ChoiceValueType) => void,
    items: IChoiceItem[],
}

const SingleChoice: React.FC<IProps> = (props: IProps) => {
    const [visible, setVisible] = useState<boolean>(false)

    const onSwitchVisibility = () => {
        setVisible(!visible)
    }

    const onChoiceChanged = (itemKey: ChoiceValueType, checked: boolean) => {
        props.items.map((item: IChoiceItem) => {
            if (!item.isChecked) {
                return
            }
            if (item.value === itemKey || !checked) {
                return
            }
            props.onChange(itemKey)
        })
    }

    return (
        <div className="filter-dropdown">
            <div
                className={cs('filter-dropdown__toggle', {'is-active': visible})}
                onClick={onSwitchVisibility}
            >
                <FilterArrow className="filter-dropdown__toggle-arrow"/>
                <div className="filter-dropdown__toggle-title">
                    {props.title}
                </div>
            </div>
            <div className={cs('filter-dropdown__body', {'is-visible': visible})}>
                <div className="filter-dropdown__items">
                    {props.items.map((item: IChoiceItem, _i: number) => {
                        return <ChoiceItem
                            label={item.label}
                            id={`${props.id}-${_i + 1}`}
                            key={`${props.id}-${_i + 1}`}
                            value={item.value.toString()}
                            isChecked={item.isChecked}
                            onChange={(value: boolean) => {
                                onChoiceChanged(item.value.toString(), value)
                            }}
                        />
                    })}
                </div>
            </div>
        </div>
    )
}

export default SingleChoice
