import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import Button from '../../components/UI/Form/Elements/Button/Button'
import {createControl} from '../../form/formFramework'
import {removeAccount} from '../../store/actions/Security/RemoveAccount/removeAccount'

function createFormControls() {
    return {
        password: createControl({
            label: 'Password',
            type: 'password',
            errorMessage: 'Password incorrect'
        }, {
            required: true
        }),
    }
}

interface IProps {
    removeAccount: (password: string) => void,
    submitted: boolean,
    error: boolean,
}

interface IFormState {
    isFormValid: boolean,
    formControls: any
}

const RemoveAccountPage: React.FC<IProps> = (props: IProps) => {
    const {removeAccount} = props

    const [formState, setFormState] = useState<IFormState>({
        isFormValid: false,
        formControls: createFormControls()
    })

    const {t} = useTranslation()

    function submitHandler(event) {
        event.preventDefault()
    }

    function validateControl(value, validation) {
        if (!validation) {
            return true
        }

        let isValid = true

        if (validation.required) {
            isValid = value.trim() !== '' && isValid
        }

        // if (validation.email) {
        //     isValid = is.email(value) && isValid
        // }

        if (validation.minLength) {
            isValid = value.length >= validation.minLength && isValid
        }

        return isValid
    }

    function onChangeHandler(event, controlName) {
        const formControls = {...formState.formControls}
        const control = {...formControls[controlName]}

        control.value = event.target.value
        control.touched = true
        control.valid = validateControl(control.value, control.validation)

        formControls[controlName] = control

        let isFormValid = true

        Object.keys(formControls).forEach(name => {
            isFormValid = formControls[name].valid && isFormValid
        })

        setFormState({
            isFormValid,
            formControls
        })
    }

    function renderInputs() {
        return Object.keys(formState.formControls).map((controlName, index) => {
            const control = formState.formControls[controlName]
            return null
            // <Input
            //     key={ controlName + index }
            //     type={ control.type }
            //     value={ control.value }
            //     valid={ control.valid }
            //     touched={ control.touched }
            //     label={ control.label }
            //     shouldValidate={ !!control.validation }
            //     errorMessage={ control.errorMessage }
            //     onChange={ event => onChangeHandler(event, controlName) }
            // />
        })
    }

    function removeAccountHandler(): void {
        removeAccount(formState.formControls.password.value)
    }

    return (
        <div>
            <div>
                <form onSubmit={submitHandler}>
                    {renderInputs()}
                    <Button
                        className=""
                        onClick={removeAccountHandler}
                        disabled={!formState.isFormValid}
                    >
                        {t('security:buttons.remove_account')}
                    </Button>
                </form>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        submitted: state.authReducer.submitted,
        error: state.authReducer.error
    }
}

function mapDispatchToProps(dispatch) {
    return {
        removeAccount: (password: string) => dispatch(removeAccount(password)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveAccountPage)
