import React from 'react'
import {Line} from 'react-chartjs-2'
import {IToken} from '../../../model/Interfaces'

interface IProps {
    token: IToken,
}

const Chart: React.FC<IProps> = (props: IProps) => {
    const {token} = props
    const labels: string[] = []
    const dataPoints: string[] = []
    const hasPredictionGraph = token !== null && Object.keys(token.predictionGraph).length > 0
    if (token !== null && hasPredictionGraph) {
        Object.keys(token.predictionGraph).map((key: string) => {
            const [year, quarter] = key.split('_')
            const dateStr = (quarter ? (' ' + 'Q' + quarter) : '') + ' \'' + year
            labels.push(dateStr)
            dataPoints.push(token.predictionGraph[key])
        })
    }

    return (
        <div
            className="table__chart-img"
            style={{position: "relative", border: '1px solid rgba(125,125,125,1)'}}
        >
            <Line
                width={140}
                height={40}
                options={{
                    layout: {
                        padding: {
                            left: 0,
                            right: 2,
                            top: 0,
                            bottom: 0
                        }
                    },
                    legend: { display: false },
                    tooltips: { enabled: true },
                    responsive: false,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            gridLines: {
                                display: false,
                                drawTicks: false,
                            },
                            ticks: { display: false }
                        }],
                        xAxes: [{
                            gridLines: {
                                display: false,
                                drawTicks: false,
                            },
                            ticks: { display: false }
                        }]
                    }
                }}
                data={{
                    labels: labels,
                    datasets: [{
                        backgroundColor: 'rgba(255,255,255,1)',
                        borderCapStyle: 'round',
                        borderColor: 'rgba(32, 26, 26, 1)',
                        borderWidth: 1,
                        lineTension: 0.5,
                        pointRadius: 0.1,
                        data: dataPoints
                    }]
                }}
            />
        </div>
    )
}

export default Chart
