import  moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {API_OPERATION_CANCEL} from '../../../axios/paths'
import {OPERATIONS_PATH} from '../../../config/routing/paths'
import ApiClient from '../../../services/ApiClient'
import tokensImg from '../../../styles/img/token-img-1.png'
import tokensImg_2x from '../../../styles/img/token-img-1@2x.png'
import history from '../../../utils/history'
import QrCodeModal from '../../Modals/QrCodeModal/QrCodeModal'
import BackButton from '../../UI/BackButton/BackButton'
import {ChargeStatus} from '../../../model/Enums'
import {IOperation, IOperationItem, IToken} from '../../../model/Interfaces'

interface IProps {
    operation: IOperation,
    mappedTokens: {[key: string]: IToken},
}

const PayWithCrypto: React.FC<IProps> = (props: IProps) => {
    const {t, i18n} = useTranslation()
    const {operation} = props
    const [modalVisible, setModalVisible] = useState<boolean>(false)
    const [addressCopied, setAddressCopied] = useState<boolean>(false)
    const [amountCopied, setAmountCopied] = useState<boolean>(false)

    const [duration, setDuration] = useState<moment.Duration|null>(null)
    useEffect(() => {
        const expiresAt = moment(operation.charge.expires_at)
        const t = setTimeout(() => {
            const duration = moment.duration(expiresAt.diff(moment()))
            setDuration(duration)
        }, 1000)

        return () => {
            clearTimeout(t)
        }
    }, [operation, duration])

    useEffect(() => {
        if (!addressCopied) {
            return
        }

        const t = setTimeout(() => {
            setAddressCopied(false)
        }, 2000)

        return () => {
            clearTimeout(t)
        }
    }, [addressCopied])

    useEffect(() => {
        if (!amountCopied) {
            return
        }

        const t = setTimeout(() => {
            setAmountCopied(false)
        }, 2000)

        return () => {
            clearTimeout(t)
        }
    }, [amountCopied])

    const copyAmountHandler = (event: React.MouseEvent) => {
        event.preventDefault()
        navigator.clipboard.writeText(operation.charge.amount)
        setAmountCopied(true)
    }

    const copyAddressHandler = (event: React.MouseEvent) => {
        event.preventDefault()
        navigator.clipboard.writeText(operation.charge.address)
        setAddressCopied(true)
    }

    const cancelPaymentHandler = () => {
        const apiClient = ApiClient()
        apiClient
            .post(API_OPERATION_CANCEL(operation.id))
            .then(() => {
                history.push(OPERATIONS_PATH)
            })
    }

    const createdAt = moment(operation.created_at).locale(i18n.language)


    return (
        <>
            <div className="operations-page__inner">
                <div className="prev-step">
                    <BackButton url={OPERATIONS_PATH}/>
                </div>

                <div className="operations-page__descr operations-page__descr--small">
                    <p>{t('common:request_for_payment')} — #{operation.charge.code}</p>
                </div>
                <div style={{textAlign: 'center', marginBottom: '10px'}}>
                    {createdAt.format('D MMM h:mm a')}
                </div>
                <div className="timer">
                    {
                        operation.charge.status === ChargeStatus.STATUS_CREATED
                        && (duration && duration.asHours() < 1 && duration.seconds() > 0)
                            ?
                                <div className="timer__circle">
                                    <div className="timer__time">
                                        {moment.utc(duration.asMilliseconds()).format('mm:ss')}
                                    </div>
                                </div>
                            : null
                    }
                    <div className="timer__descr">
                        {
                            operation.charge.status === ChargeStatus.STATUS_CREATED
                            && (!duration || duration.seconds() > 0)
                                ?
                                    <span className="orange-color">
                                        {t('common:col_val_status_awaiting_payment')}
                                    </span>
                                : null
                        }

                        {
                            operation.charge.status === ChargeStatus.STATUS_EXPIRED
                            || operation.charge.status === ChargeStatus.STATUS_CANCELED
                            || (
                                operation.charge.status === ChargeStatus.STATUS_CREATED
                                && (duration && duration.seconds() < 0)
                            )
                                ?
                                    <span className="red-color">
                                        {t('common:col_val_status_canceled')}
                                    </span>
                                : null
                        }

                        {
                            operation.charge.status === ChargeStatus.STATUS_PENDING
                            || operation.charge.status === ChargeStatus.STATUS_PROCESSING
                                ? <span className="orange-color">{t('common:col_val_status_processing')}...</span>
                                : null
                        }

                        {
                            operation.charge.status === ChargeStatus.STATUS_SUCCESS
                                ? <span className="green-color">{t('common:col_val_status_completed')}</span>
                                : null
                        }

                        {
                            operation.charge.status === ChargeStatus.STATUS_REFUND_PENDING
                                ? <span className="orange-color">{t('common:col_val_status_refund_pending')}</span>
                                : null
                        }

                        {
                            operation.charge.status === ChargeStatus.STATUS_REFUNDED
                                ? <span className="red-color">{t('common:col_val_status_refund_pending')}</span>
                                : null
                        }
                    </div>
                </div>

                {
                    (
                        operation.charge.status === ChargeStatus.STATUS_CREATED
                        && (!duration || duration.seconds() > 0)
                    )
                    || operation.charge.status === ChargeStatus.STATUS_PENDING
                    || operation.charge.status === ChargeStatus.STATUS_PROCESSING
                        ?
                            <>
                                <div className="operations-page__descr operations-page__descr--small">
                                    <p dangerouslySetInnerHTML={{__html: t(
                                            'common:to_pay_for_order_send_money',
                                            {
                                                crypto_currency: operation.charge.currency
                                            }
                                        ) }} />
                                </div>
                                <div className="form operations-page__form">
                                    <div className="form__group">
                                        <label className="form__label">
                                            {t('common:amount')}
                                        </label>
                                        <input
                                            className="form__input"
                                            type="text"
                                            name="inputTotal"
                                            placeholder=""
                                            value={
                                                operation.charge.amount.toLocaleString() + ' '
                                                + operation.charge.currency
                                                + ' ($ ' + operation.amount.toLocaleString() + ')'
                                            }
                                            readOnly
                                        />

                                        <div className="form__copy" onClick={copyAmountHandler}>
                                            {
                                                amountCopied
                                                ?
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                            d="M5 13L9 17L19 7"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                        />
                                                    </svg>
                                                :
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="1"
                                                            d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                                                        />
                                                    </svg>
                                            }
                                        </div>
                                    </div>
                                    <div className="form__group form__group--mb-20">
                                        <label className="form__label">
                                            {t('common:address')} {operation.charge.currency}
                                        </label>
                                        <input
                                            className="form__input"
                                            type="text"
                                            name="inputTotal"
                                            placeholder=""
                                            value={operation.charge.address}
                                            readOnly
                                        />
                                        <div className="form__copy" onClick={copyAddressHandler}>
                                            {
                                                addressCopied
                                                    ?
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                        >
                                                            <path
                                                                d="M5 13L9 17L19 7"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="2"
                                                            />
                                                        </svg>
                                                        :
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="1"
                                                                d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                                                            />
                                                        </svg>
                                            }
                                        </div>
                                    </div>
                                    {
                                        operation.charge.status === ChargeStatus.STATUS_CREATED
                                        && (!duration || duration.seconds() > 0)
                                            ?
                                                <div className="form__group">
                                                    <div className="form__info">
                                                        <svg
                                                            className="form__info-icon"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                        <p dangerouslySetInnerHTML={{
                                                            __html: t(
                                                                'common:send_only_such_tokens',
                                                                {
                                                                    crypto_currency: operation.charge.currency
                                                                }
                                                            )
                                                        }}/>
                                                    </div>
                                                </div>
                                            : null
                                    }

                                    <div className="form__group form__group--mb-20">
                                        <div className="form__buttons form__buttons--center">
                                            <button
                                                className="button button--small button--bordered form__button form__button--auto"
                                                type="button"
                                                onClick={() => {
                                                    setModalVisible(true)
                                                }}
                                            >
                                                <svg
                                                    className="button__icon"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"
                                                    />
                                                </svg>
                                                {t('common:show_qr_code')}
                                            </button>
                                        </div>
                                    </div>
                                    {
                                        operation.charge.status === ChargeStatus.STATUS_CREATED
                                        && (!duration || duration.seconds() > 0)
                                            ?
                                                <div className="form__buttons form__buttons--center">
                                                    <a
                                                        href={operation.charge.url}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="button button--small button--bordered
                                                            form__button form__button--auto"
                                                    >
                                                        {t('common:pay_with_coinbase')}
                                                    </a>
                                                </div>
                                            : null
                                    }
                                </div>
                            </>
                        : null
                }

                <div className="check-card">
                    <div className="check-card__body">
                        {
                            operation.items.map((item: IOperationItem, i: number) => {
                                const token = props.mappedTokens[item.token_id]
                                return (
                                    <div className="check-card__header" key={'ordered-item-' + i}>
                                        <div className="token-item token-item--small check-card__token">
                                            <div className="token-item__picture">
                                                <img
                                                    className="token-item__img"
                                                    src={token.icon || tokensImg}
                                                    srcSet={`${token.icon2x || tokensImg_2x} 2x`}
                                                    alt="alt"
                                                />
                                            </div>
                                            <div className="token-item__descr">
                                                <div className="token-item__value">
                                                    {props.mappedTokens[item.token_id].name} x {item.quantity}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="check-card__price">$ {item.total_amount.toLocaleString()}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="check-card__total">
                        <div className="check-card__total-title">
                            {t('common:amount_to_pay')}
                        </div>
                        <div className="check-card__total-price">
                            $ {operation.amount.toLocaleString()}
                            <div className="check-card__total-crypto-price">
                                {operation.charge.amount.toLocaleString() + ' '
                                + operation.charge.currency}
                            </div>
                        </div>
                    </div>
                </div>
                {
                    operation.charge.status === ChargeStatus.STATUS_CREATED
                    && (!duration || duration.seconds() > 0)
                        ?
                            <div className="operations-page__cancel">
                                <button
                                    className="button-link button-link--small button-link--red"
                                    type="button"
                                    onClick={cancelPaymentHandler}
                                >
                                    {t('common:buttons.cancel_payment')}
                                </button>
                            </div>
                        : null
                }
            </div>
            <QrCodeModal
                visible={modalVisible}
                close={() => {setModalVisible(false)}}
                operation={operation}
            />
        </>
    )
}

export default PayWithCrypto
