import React from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import RegisteredSupportForm from '../../components/Support/RegisteredSupportForm/RegisteredSupportForm'
import UnregisteredSupportForm from '../../components/Support/UnregisteredSupportForm/UnregisteredSupportForm'
import PreLoader from '../../components/UI/PreLoader/PreLoader'
import MainLayout from '../../hoc/MainLayout/MainLayout'
import Account from '../../model/Account'

interface IProps {
    isAuthenticated: boolean,
    account: Account | null,
}

interface StateProps {
    submitted: boolean,
    error: boolean
}

type Props = IProps & StateProps

const BetaPage: React.FC<Props> = (props: Props) => {
    const {t} = useTranslation()

    if (props.isAuthenticated && !props.account) {
        return <PreLoader/>
    }

    return (
        <MainLayout>
            <main className="faq-page">
                <div className="faq-page__inner">
                    <h1 className="faq-page__title">
                        {t('common:titles.beta')}
                    </h1>
                    <UnregisteredSupportForm
                        emailLabel={t('common:forms.users.email')}
                        textLabel={t('common:forms.users.intro')}
                    />
                </div>
            </main>
        </MainLayout>
    )
}

function mapStateToProps(state) {
    return {
        account: state.authReducer.account,
        isAuthenticated: state.authReducer.authToken.isFullyAuthenticated(),
    }
}

export default connect(mapStateToProps)(BetaPage)
