import React from 'react'
import {useTranslation} from 'react-i18next'
import {IPainting, IToken} from '../../../model/Interfaces'
import TokensListItemTablet from '../TokensListItemTablet/TokensListItemTablet'

interface IProps {
    isAuthenticated: boolean,
    indexToken: IToken,
    artTokens: IToken[],
    paintings: {[key: string]: IPainting},
    subscribe: (paintingId: string) => void
}

const TokensListTablet: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()

    return (
        <table className="table table--grey tokens-page__table tokens-page__table--tablet">
            <thead>
                <tr>
                    <th>
                        {t('common:col_name')}
                    </th>
                    <th>
                        {t('common:col_capitalization')}
                    </th>
                    <th>
                        {t('common:col_unavailable')}
                    </th>
                    <th>
                        {t('common:col_available')}
                    </th>
                    <th>
                        {t('common:col_price')}
                    </th>
                </tr>
            </thead>
            <tbody>
                <TokensListItemTablet
                    token={props.indexToken}
                    subscribe={props.subscribe}
                />
                {
                    props.artTokens.map((artToken: IToken, i: number) => {

                        return (
                            <TokensListItemTablet
                                key={'art-token-tablet-' + i}
                                token={artToken}
                                painting={props.paintings[artToken.paintingId]}
                                subscribe={props.subscribe}
                            />
                        )
                    })
                }
            </tbody>
        </table>
    )
}

export default TokensListTablet
