import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Redirect, Route, RouteComponentProps, Switch, withRouter} from 'react-router-dom'
import CookiesAgreement from './components/Modals/CookiesAgreement/CookiesAgreement'
import PreLoader from './components/UI/PreLoader/PreLoader'
import {DEFAULT_NOT_PROTECTED_PATH, DEFAULT_PROTECTED_PATH} from './config/routing/paths'
import Routes from './config/routing/routes'
import RouteType, {AccessLevel} from './config/routing/routeType'
import AccountBalance from './model/AccountBalance'
import {RENEW_DATA} from './store/actions/actionTypes'
import {loadBalance} from './store/actions/balance'
import {loadAuthors, loadPaintings} from './store/actions/gallery'
import {loadOperations} from './store/actions/operation'
import {loadPartners} from './store/actions/partner'
import {autoLogin} from './store/actions/Security/Auth/auth'
import {checkReferralUserData} from './store/actions/Security/Registration/referral'
import SimpleReactLightbox from 'simple-react-lightbox'
import "./styles/sass/bootstrap.sass"
import "./styles/sass/main.sass"
import {loadTokens} from './store/actions/token'
import Config from "./config/config"
import {loadFeatureFlags} from './store/actions/featureFlags'
import {FeatureFlagListType} from './model/Types'


interface IProps {
    renewData: () => void,
    autoLogin: () => void,
    checkReferralUserData: () => void,
    isAuthenticated: boolean,
    balance: AccountBalance | null,
    balanceNeedRenew: boolean,
    loadBalance: () => void,
    authorsLoaded: boolean,
    paintingsLoaded: boolean,
    loadAuthors: () => void,
    loadPaintings: ()=> void,
    operationsLoaded: boolean,
    loadOperations: () => void,
    operationsNeedRenew: boolean,
    partnersLoaded: boolean,
    loadPartners: () => void,
    tokensLoaded: boolean,
    loadTokens: () => void,
    tokensNeedRenew: boolean,
    featureFlagsLoaded: boolean,
    featureFlags: FeatureFlagListType,
    loadFeatureFlags: () => void,
}

type Props = IProps & RouteComponentProps

const App: React.FC<Props> = (props: Props) => {
    const {
        isAuthenticated, autoLogin,
        balance, loadBalance, balanceNeedRenew,
        authorsLoaded, paintingsLoaded, loadAuthors, loadPaintings,
        operationsLoaded, loadOperations, operationsNeedRenew,
        partnersLoaded, loadPartners,
        tokensLoaded, loadTokens, tokensNeedRenew,
        featureFlagsLoaded, featureFlags, loadFeatureFlags,
} = props
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
        }

        autoLogin()
        if (!isAuthenticated) {
            props.checkReferralUserData()
        }
    }, [isAuthenticated, loaded])

    useEffect(() => {
        if (isAuthenticated && (!balance || balanceNeedRenew)) {
            loadBalance()
        }

    }, [isAuthenticated, balance, balanceNeedRenew])

    useEffect(() => {
        if (!authorsLoaded) {
            loadAuthors()
        }

        if (!paintingsLoaded) {
            loadPaintings()
        }

    }, [authorsLoaded, paintingsLoaded])

    useEffect(() => {
        if (isAuthenticated && (!operationsLoaded || operationsNeedRenew)) {
            loadOperations()
        }

    }, [isAuthenticated, operationsLoaded, operationsNeedRenew])

    useEffect(() => {
        if (isAuthenticated && !partnersLoaded) {
            loadPartners()
        }

    }, [isAuthenticated, partnersLoaded])

    useEffect(() => {
        if (!tokensLoaded || tokensNeedRenew) {
            loadTokens()
        }

    }, [tokensLoaded, tokensNeedRenew])

    useEffect(() => {
        if (!featureFlagsLoaded) {
            loadFeatureFlags()
        }

    }, [featureFlagsLoaded])

    useEffect(() => {
        if (!isAuthenticated) {
            return
        }

        const interval = setInterval(() => {
            props.renewData()
        }, Config.reloadDataTimeout)

        return () => {
            clearInterval(interval)
        }
    }, [isAuthenticated])

    if (!loaded) {
        return (
            <PreLoader />
        )
    }

    const routes = Routes
        .filter((route: RouteType) => {
            const routeFeatureFlag = route.featureFlag || null
            if (routeFeatureFlag !== null && !featureFlagsLoaded) {
                return false
            }

            const featureFlagEnabled = routeFeatureFlag && featureFlags[routeFeatureFlag]
                ? featureFlags[routeFeatureFlag]
                : false
            if (routeFeatureFlag !== null && !featureFlagEnabled) {
                return false
            }
            if (route.access === AccessLevel.All) {
                return true
            }
            if (isAuthenticated && route.access === AccessLevel.Authorized) {
                return true
            }

            return !isAuthenticated && route.access === AccessLevel.Unauthorized;
        })
        .map((route: RouteType) => {
            return (
                <Route
                    key={route.path}
                    path={route.path}
                    exact={route.exact === true}
                    component={route.component}
                />
            )
        })

    return (
        <div>
            {/*<Loader />*/}
            <SimpleReactLightbox>
                <Switch>
                    {routes}
                    <Redirect
                        to={isAuthenticated ? DEFAULT_PROTECTED_PATH : DEFAULT_NOT_PROTECTED_PATH}
                        push
                    />
                </Switch>
            </SimpleReactLightbox>
            <CookiesAgreement/>
            <div className="popup-container">
                <div className="popup-container__inner"/>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.authReducer.authToken.isFullyAuthenticated(),
        balance: state.balanceReducer.balance,
        balanceNeedRenew: state.balanceReducer.needRenew,
        authorsLoaded: state.galleryReducer.authorsLoaded,
        paintingsLoaded: state.galleryReducer.paintingsLoaded,
        operationsLoaded: state.operationReducer.operationsLoaded,
        operationsNeedRenew: state.operationReducer.needRenew,
        partnersLoaded: state.partnerReducer.partnersLoaded,
        tokensLoaded: state.tokenReducer.tokensLoaded,
        tokensNeedRenew: state.tokenReducer.needRenew,
        featureFlagsLoaded: state.featureFlagReducer.featureFlagsLoaded,
        featureFlags: state.featureFlagReducer.featureFlags,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        autoLogin: (): void => dispatch(autoLogin()),
        checkReferralUserData: (): void => dispatch(checkReferralUserData()),
        loadBalance: (): void => dispatch(loadBalance()),
        loadAuthors: (): void => dispatch(loadAuthors()),
        loadPaintings: (): void => dispatch(loadPaintings()),
        loadOperations: (): void => dispatch(loadOperations()),
        loadPartners: (): void => dispatch(loadPartners()),
        loadTokens: (): void => dispatch(loadTokens()),
        loadFeatureFlags: (): void => dispatch(loadFeatureFlags()),
        renewData: (): void => dispatch({type: RENEW_DATA}),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
