import * as cs from 'classnames'
import React, {MutableRefObject, useRef, useState} from 'react'
import {FieldError} from 'react-hook-form'

interface IProps {
    label: string,
    error: FieldError | undefined,
    blockClasses?: [string],
    onChange?: (event: MouseEvent) => void,
    onBlur?: (event: MouseEvent) => void,
    value?: string,
}

const clearPhoneNumber = function (value: string): string {
    let clearValue = value.trim().replace(/[^0-9+]/g, '')
    if (clearValue && clearValue.indexOf('+') !== 0) {
        clearValue = '+' + clearValue
    }

    return clearValue
}

type Ref = ((instance: HTMLInputElement | null) => void) | MutableRefObject<HTMLInputElement | null> | null

const InputMobile = React.forwardRef<HTMLInputElement, IProps>((
    props: IProps, ref: Ref
) => {
    const [active, setActive] = useState<boolean>(false)

    const htmlFor = `text-${Math.random()}`
    const inputRef = useRef<HTMLInputElement>(null)
    const labelRef = useRef<HTMLLabelElement>(null)

    const handleAutoFill = e => e.animationName === 'onAutoFillStart' && setActive(true)

    function labelClickHandler() {
        inputRef.current!.focus()
    }

    function focusInHandler() {
        setActive(true)
    }

    function focusOutHandler(event) {
        const target = event.target as HTMLInputElement
        setActive(!!target.value)
    }

    const placeHolderClasses = cs('form__placeholder', {'is-active': active})
    const blockClasses = cs(props.blockClasses, 'form__group', {'has-error': !!props.error})

    const label: string = props.error?.message || props.label || ''

    const onBlurHandler = event => {
        focusOutHandler(event)
        props.onBlur && props.onBlur(event)
    }
    const onChangeHandler = event => {
        event.target.value = clearPhoneNumber(event.target.value)
        props.onChange && props.onChange(event)
    }

    return (
        <div className={blockClasses} ref={ref}>
            <input
                className="form__input"
                type="text"
                id={htmlFor}
                onFocus={focusInHandler}
                onBlur={onBlurHandler}
                onChange={onChangeHandler}
                value={props.value}
                ref={inputRef}
                onAnimationStart={handleAutoFill}
                autoComplete="chrome-off"
            />
            <label
                htmlFor={htmlFor}
                className={placeHolderClasses}
                ref={labelRef}
                onClick={labelClickHandler}
            >{label}</label>
        </div>
    )
})

export default InputMobile
