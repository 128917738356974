import React from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import PreLoader from '../UI/PreLoader/PreLoader'

interface IProps {
    loading: boolean,
    referralEmail: string | null
}

const RegistrationReferral: React.FC<IProps> | null = (props: IProps) => {
    const {t} = useTranslation()
    if (props.loading) {
        return <PreLoader/>
    }

    if (!props.referralEmail) {
        return null
    }

    return (
        <div className="login-page__descr">
            <p>{t('referral_registration')}</p>
            <p>{t('your_partner')}: <br/>{props.referralEmail}</p>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.registrationReferralReducer.loading,
        referralEmail: state.registrationReferralReducer.email,
    }
}

export default connect(mapStateToProps)(RegistrationReferral)
