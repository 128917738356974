import React from 'react'
import FilterArrow from "../../../UI/Icons/FilterArrow/FilterArrow";

interface IProps {
    label: string,
    id: string,
    value: string,
    isChecked: boolean,
    onChange: (value: boolean) => void,
}

const ChoiceItem: React.FC<IProps> = (props: IProps) => {
    const onChangeHandler = () => {
        props.onChange(!props.isChecked)
    }

    return (
        <div className="filter-dropdown__item">
            <div className="checkbox">
                <input
                    className="checkbox__input"
                    id={props.id}
                    type="checkbox"
                    name="checkbox"
                    value={props.value}
                    checked={props.isChecked}
                    onChange={onChangeHandler}
                />
                <label className="checkbox__label" htmlFor={props.id}>
                    {props.label}
                </label>
            </div>
        </div>
    )
}

export default ChoiceItem
