import React, {useState} from 'react'
import SearchIcon from "../../../UI/Icons/SearchIcon/SearchIcon";

interface IProps {
    placeholder: string,
    value: string,
    onChange: (value: string) => void,
}

const Search: React.FC<IProps> = (props: IProps) => {
    const [value, setValue] = useState<string>(props.value)

    const onSubmit = (event: React.MouseEvent): void => {
        event.preventDefault()
        props.onChange(value)
    }
    const onKeyPressHandler = (event: React.KeyboardEvent): void => {
        if (event.key === 'Enter') {
            props.onChange(value)
        }
    }
    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setValue(event.target.value || '')
        props.onChange(event.target.value || '')
    }

    return (
        <div className="search filter-form__search">
            <input
                className="search__input"
                type="text"
                name="search"
                placeholder={props.placeholder}
                value={value}
                onKeyPress={onKeyPressHandler}
                onChange={onChangeHandler}
            />
            <button className="search__button" onClick={onSubmit}>
                <SearchIcon/>
            </button>
        </div>
    )
}

export default Search
