import React, {useState} from 'react'
import {connect} from 'react-redux'
import Basket from '../../components/Basket/Basket/Basket'
import ChooseCrypto from '../../components/Basket/ChooseCrypto/ChooseCrypto'
import PreLoader from '../../components/UI/PreLoader/PreLoader'
import ArtToken from '../../model/ArtToken'
import BasketItem from '../../model/BasketItem'
import Tokens from '../../model/Tokens'
import {PaymentMethod} from '../../model/Enums'
import {IToken} from '../../model/Interfaces'
import MainLayout from '../../hoc/MainLayout/MainLayout'

interface IProps {
    tokensLoaded: boolean,
    tokens: Tokens | null,
}

const BasketPage: React.FC<IProps> = (props: IProps) => {
    const [basketItems, setBasketItems] = useState<BasketItem[]>([])
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod|null>(null)

    if (!props.tokensLoaded || !props.tokens) {
        return <PreLoader/>
    }

    const {indexToken, artTokens} = props.tokens

    const artTokensList = artTokens.filter((artToken: ArtToken) => {
        return parseFloat(artToken.available) > 0
    })
    const isIndexTokenAvailable = parseFloat(indexToken.available) > 0

    if (!basketItems.length) {
        const defaultTokenId = isIndexTokenAvailable ? indexToken.id : artTokensList[0].id
        setBasketItems([new BasketItem(defaultTokenId)])
    }

    const mappedTokens: {[key: string]: IToken} = {}
    if (isIndexTokenAvailable) {
        mappedTokens[indexToken.id] = indexToken
    }
    artTokensList.map((item: ArtToken) => {
        mappedTokens[item.id] = item
    })

    const resetPaymentMethodHandler = () => {
        setPaymentMethod(null)
    }

    return (
        <MainLayout>
            <main className="operations-page">
                {
                    !paymentMethod
                        ? <Basket
                            mappedTokens={mappedTokens}
                            setBasketItems={setBasketItems}
                            setPaymentMethod={setPaymentMethod}
                            basketItems={basketItems}
                        />
                        : null
                }
                {
                    paymentMethod === PaymentMethod.PAY_CRYPTO
                        ? <ChooseCrypto
                            items={basketItems}
                            onCancel={resetPaymentMethodHandler}
                        />
                        : null
                }
            </main>
        </MainLayout>
    )
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.authReducer.authToken.isFullyAuthenticated(),
        tokensLoaded: state.tokenReducer.tokensLoaded,
        tokens: state.tokenReducer.tokens
    }
}

export default connect(mapStateToProps)(BasketPage)
