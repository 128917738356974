import React from 'react'
import {Link} from 'react-router-dom'
import {AUTH_PATH} from '../../../config/routing/paths'
import {useTranslation} from 'react-i18next'
import {LANGUAGE_STORAGE_NAME} from '../../../store/actions/Security/Auth/auth'
import Menu, {MenuType} from '../../Header/Menu/Menu'
import MenuToggle from '../../Header/MenuToggle/MenuToggle'
import UserMenu from '../../Header/UserMenu/UserMenu'
import MenuSidebar from '../../Header/MenuSidebar/MenuSidebar'
import Logo from '../../UI/Logo/Logo'


interface IProps {
    isAuthenticated: boolean,
}

const Header: React.FC<IProps> = (props: IProps) => {
    const {t, i18n} = useTranslation()

    return (
        <>
            <header className="header">
                <div className="header__inner">
                    <MenuToggle/>
                    <div className="logo header__logo">
                        <Logo/>
                    </div>

                    <Menu type={MenuType.DESKTOP}/>
                    {/*<div className="header-lang">*/}
                    {/*    <a*/}
                    {/*        className="header-lang__link call-popup"*/}
                    {/*        href="#change_language"*/}
                    {/*        onClick={(event) => {*/}
                    {/*            event.preventDefault()*/}
                    {/*            const lang = i18n.language === 'ru' ? 'en' : 'ru'*/}
                    {/*            i18n.changeLanguage(lang)*/}
                    {/*            localStorage.setItem(LANGUAGE_STORAGE_NAME, lang)*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        {i18n.language === 'ru' ? 'ru' : 'en'}*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                    <div className="header-user">
                        {
                            props.isAuthenticated
                                ?
                                <UserMenu/>
                                :
                                <Link className="button header-user__button" to={AUTH_PATH}>
                                    {t('landing:buttons.sign_in')}
                                </Link>

                        }
                    </div>
                </div>
            </header>
            <MenuSidebar/>
        </>
    )
}

export default Header
