import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {ControllerOptions} from 'swiper/types/components/controller'
import {AUTH_PATH, GALLERY_PATH, TOKENS_PATH, WALLET_PATH} from '../../../config/routing/paths'
import workSlideImg1 from '../../../styles/img/main/wallet-1.jpg'
import workSlideImg1_2x from '../../../styles/img/main/wallet-1@2x.jpg'
import workSlideImg2 from '../../../styles/img/main/wallet-2.jpg'
import workSlideImg2_2x from '../../../styles/img/main/wallet-2@2x.jpg'
import workSlideImg3 from '../../../styles/img/main/wallet-3.jpg'
import workSlideImg3_2x from '../../../styles/img/main/wallet-3@2x.jpg'
import SlideLeftBigIcon from '../../UI/Icons/SlideLeftBigIcon/SlideLeftBigIcon'
import SlideRightBigIcon from '../../UI/Icons/SlideRightBigIcon/SlideRightBigIcon'
import AboutItem from '../AboutItem/AboutItem'
import {Trans, useTranslation} from 'react-i18next'
import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {Navigation, Controller, Keyboard, Swiper as SwiperType} from 'swiper'
import 'swiper/swiper.scss'
import 'swiper/components/controller/controller.scss'
import 'swiper/components/navigation/navigation.scss'

SwiperCore.use([Navigation, Controller, Keyboard])

interface IProps {
    isAuthenticated: boolean
}

const About: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()

    const [workSwiperRef, setWorkSwiperRef] = useState<SwiperType>()
    const [imageSwiperRef, setImageSwiperRef] = useState<SwiperType>()

    useEffect(() => {
        if (!workSwiperRef || !imageSwiperRef) {
            return
        }

        const workController: ControllerOptions = workSwiperRef.controller
        const imageController: ControllerOptions = imageSwiperRef.controller

        workController.control = imageSwiperRef
        imageController.control = workSwiperRef
    }, [workSwiperRef, imageSwiperRef])

    interface IImage {src: string, src2x: string}
    const renderItems = (items: IImage[]) => items.map((image: IImage, i: number) => {
        return (
            <SwiperSlide key={'slide' + i}>
                <AboutItem
                    image={image.src}
                    image2x={image.src2x}
                />
            </SwiperSlide>
        )
    })

    return (
        <section className="wallet-section this-anchor" id="about_project">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 order-lg-2">
                        <Swiper
                            spaceBetween={0}
                            loop={true}
                            speed={1000}
                            mousewheel={false}
                            className={"main-image-slider"}
                            onSwiper={(swiper) => {
                                setImageSwiperRef(swiper)
                            }}
                            keyboard={{enabled: true}}
                        >
                            {renderItems([{
                                src: workSlideImg1,
                                src2x: workSlideImg1_2x,
                            }, {
                                src: workSlideImg2,
                                src2x: workSlideImg2_2x,
                            }, {
                                src: workSlideImg3,
                                src2x: workSlideImg3_2x,
                            }])}
                        </Swiper>
                    </div>

                    <div className="col-lg-6 order-lg-1">
                        <Swiper
                            spaceBetween={30}
                            loop={true}
                            speed={1000}
                            mousewheel={false}
                            className={"main-work-slider"}
                            onSwiper={(swiper) => {setWorkSwiperRef(swiper)}}
                            navigation={{
                                nextEl: '.main-work-slider-nav__next',
                                prevEl: '.main-work-slider-nav__prev'
                            }}
                            keyboard={{enabled: true}}
                        >
                            <SwiperSlide>
                                <h3 className="main-work-slider__title">
                                    {t('landing:titles.about_slide_1')}
                                </h3>
                                <div className="main-work-slider__text">
                                    <p>
                                        <Trans
                                            i18nKey={'landing:texts.about_slide_1'}
                                        >
                                            ~ <b> ~ </b> ~
                                        </Trans>
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <h3 className="main-work-slider__title">
                                    {t('landing:titles.about_slide_2')}
                                </h3>
                                <div className="main-work-slider__text">
                                    <p>
                                        <Trans
                                            i18nKey={'landing:texts.about_slide_2'}
                                        >
                                            ~ <Link to={GALLERY_PATH}> ~ </Link> ~
                                        </Trans>
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <h3 className="main-work-slider__title">
                                    {t('landing:titles.about_slide_3')}
                                </h3>
                                <div className="main-work-slider__text">
                                    <p>
                                        <Trans
                                            i18nKey={'landing:texts.about_slide_3'}
                                        >
                                            ~ <b> ~ </b> ~
                                        </Trans>
                                    </p>
                                </div>
                            </SwiperSlide>

                            <div className="main-work-slider-nav">
                                <div className="main-work-slider-nav__prev">
                                    <SlideLeftBigIcon/>
                                </div>
                                <div className="main-work-slider-nav__next">
                                    <SlideRightBigIcon/>
                                </div>
                            </div>
                        </Swiper>
                    </div>

                </div>
            </div>
        </section>
    )
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.authReducer.authToken.isFullyAuthenticated(),
    }
}
export default connect(mapStateToProps, null)(About)
