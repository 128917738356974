import React, {useState} from 'react'
import FilterArrow from '../../../UI/Icons/FilterArrow/FilterArrow'
import ChoiceItem from '../ChoiceItem/ChoiceItem'
import {useTranslation} from 'react-i18next'
import {ChoiceItemsListType, ChoiceValuesListType} from '../../../../model/Types'
import {IChoiceItem} from '../../../../model/Interfaces'
import * as cs from 'classnames'

interface IProps {
    title: string,
    id: string,
    onChange: (selected: ChoiceValuesListType) => void,
    items: ChoiceItemsListType,
}

const MultipleChoice: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()
    const [visible, setVisible] = useState<boolean>(false)

    const onSwitchVisibility = () => {
        setVisible(!visible)
    }

    const getSelected = (items: ChoiceItemsListType): ChoiceValuesListType => items.reduce(
        (selected: ChoiceValuesListType, item: IChoiceItem): ChoiceValuesListType => {
            if (item.isChecked) {
                selected.push(item.value)
            }

            return selected
        }, []
    )

    const selectedItemsChange = (items: ChoiceItemsListType) => {
        const selectedValues = getSelected(items)
        const selected = selectedValues.length === props.items.length ? [] : selectedValues

        props.onChange(selected)
    }

    const onChoiceChanged = (changedItem: IChoiceItem) => {
        changedItem.isChecked = !changedItem.isChecked
        const items:ChoiceItemsListType = props.items.slice(0).map((item: IChoiceItem) => {
            return changedItem.value === item.value ? changedItem : item
        })
        selectedItemsChange(items)
    }

    const onCheckAllChanged = (checked: boolean) => {
        const items:ChoiceItemsListType = props.items.slice(0).map((item: IChoiceItem) => {
            item.isChecked = checked

            return item
        })
        selectedItemsChange(items)
    }

    const selected: ChoiceValuesListType = getSelected(props.items)

    return (
        <div className="filter-dropdown">
            <div
                className={cs('filter-dropdown__toggle', {'is-active': visible})}
                onClick={onSwitchVisibility}
            >
                <FilterArrow className="filter-dropdown__toggle-arrow"/>
                <div className="filter-dropdown__toggle-title">
                    {props.title}
                </div>
            </div>
            <div className={cs('filter-dropdown__body', {'is-visible': visible})}>
                <div className="filter-dropdown__items">
                    {
                        props.items.length > 1
                        ? <ChoiceItem
                                label={t('all')}
                                id={`${props.id}-0`}
                                key={`${props.id}-0`}
                                value=""
                                isChecked={selected.length === 0}
                                onChange={onCheckAllChanged}
                            />
                        : null
                    }
                    {props.items.map((item: IChoiceItem, _i: number) => {
                        return <ChoiceItem
                            label={item.label}
                            id={`${props.id}-${_i + 1}`}
                            key={`${props.id}-${_i + 1}`}
                            value={item.value.toString()}
                            isChecked={item.isChecked}
                            onChange={() => onChoiceChanged(item)}
                        />
                    })}
                </div>
            </div>
        </div>
    )
}

export default MultipleChoice
