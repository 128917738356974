import React from 'react'
import {useTranslation} from 'react-i18next'
import DepositIcon from '../../UI/Icons/DepositIcon/DepositIcon'
import ExchangeIcon from '../../UI/Icons/ExchangeIcon/ExchangeIcon'

const WalletHeader: React.FC = () => {
    const {t} = useTranslation()

    return (
        // <div className="tabs portfolio-page__tabs">
        //     <ul className="tabs-toggle portfolio-page__tabs-toggle">
        //         <li className="tabs-toggle__item on" data-toggle="#tab-1">curent year</li>
        //         <li className="tabs-toggle__item" data-toggle="#tab-2">for 1 year</li>
        //         <li className="tabs-toggle__item" data-toggle="#tab-3">for 2 years</li>
        //     </ul>
        //     <div className="tabs__panes portfolio-page__tabs-panes">
        //         <div className="tabs__pane on visible" id="tab-1">
        //             <div className="portfolio-page__chart">
        //                 <img
        //                     className="portfolio-page__chart-img"
        //                     src="img/portfolio-chart.svg" alt="alt"
        //                 />
        //                 <div className="chart-labels">
        //                     <div className="chart-labels__block">
        //                         <div className="chart-labels__title">Current balance</div>
        //                         <div className="chart-labels__val">$ 50</div>
        //                     </div>
        //                     <div className="chart-labels__block chart-labels__block--center">
        //                         <div className="chart-labels__title">Forecast for 20 Dec 2020</div>
        //                         <div className="chart-labels__val">$ 40 474</div>
        //                     </div>
        //                     <div className="chart-labels__block chart-labels__block--right">
        //                         <div className="chart-labels__title">Forecast for 10 June 2021</div>
        //                         <div className="chart-labels__val">$ 80 474</div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className="tabs__pane" id="tab-2">
        //             <div className="portfolio-page__chart">
        //                 <img
        //                     className="portfolio-page__chart-img"
        //                     src="img/portfolio-chart.svg"
        //                     alt="alt"
        //                 />
        //                 <div className="chart-labels">
        //                     <div className="chart-labels__block">
        //                         <div className="chart-labels__title">Current balance</div>
        //                         <div className="chart-labels__val">$ 50</div>
        //                     </div>
        //                     <div className="chart-labels__block chart-labels__block--center">
        //                         <div className="chart-labels__title">Forecast for 20 Dec 2020</div>
        //                         <div className="chart-labels__val">$ 40 474</div>
        //                     </div>
        //                     <div className="chart-labels__block chart-labels__block--right">
        //                         <div className="chart-labels__title">Forecast for 10 June 2021</div>
        //                         <div className="chart-labels__val">$ 80 474</div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className="tabs__pane" id="tab-3">
        //             <div className="portfolio-page__chart">
        //                 <img
        //                     className="portfolio-page__chart-img"
        //                     src="img/portfolio-chart.svg"
        //                     alt="alt"
        //                 />
        //                 <div className="chart-labels">
        //                     <div className="chart-labels__block">
        //                         <div className="chart-labels__title">Current balance</div>
        //                         <div className="chart-labels__val">$ 50</div>
        //                     </div>
        //                     <div className="chart-labels__block chart-labels__block--center">
        //                         <div className="chart-labels__title">Forecast for 20 Dec 2020</div>
        //                         <div className="chart-labels__val">$ 40 474</div>
        //                     </div>
        //                     <div className="chart-labels__block chart-labels__block--right">
        //                         <div className="chart-labels__title">Forecast for 10 June 2021</div>
        //                         <div className="chart-labels__val">$ 80 474</div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        null
    )
}

export default WalletHeader
