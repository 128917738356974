import {connect} from 'react-redux'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {API_ACCOUNT_NOTIFICATION_UPDATE} from '../../../axios/paths'
import Account from '../../../model/Account'
import AccountNotification from '../../../model/AccountNotification'
import ApiClient from '../../../services/ApiClient'
import * as cs from 'classnames'
import {reloadAccountData} from '../../../store/actions/Security/Auth/auth'
import PreLoader from '../../UI/PreLoader/PreLoader'

enum NotificationTypes {
    TYPE_EMAIL_NEW_PAINTINGS = 'email_new_paintings',
    TYPE_EMAIL_NEWS = 'email_news',
    TYPE_PHONE_NEW_PAINTINGS = 'phone_new_paintings',
    TYPE_PHONE_NEWS = 'phone_news',
}

interface StateProps {
    account: Account | null,
}

interface DispatchProps {
    reloadAccountData: () => void,
}

type IProps = StateProps & DispatchProps

const Notifications: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation();

    if (!props.account) {
        return <PreLoader/>
    }

    const account = props.account
    const notifications: AccountNotification = account.notifications

    const notificationChangeHandler = async (target: HTMLInputElement, type: NotificationTypes) => {
        const status = target.checked
        const apiClient = ApiClient()
        await apiClient.post(API_ACCOUNT_NOTIFICATION_UPDATE, {
            type: type,
            enabled: status,
        })

        props.reloadAccountData()
    }

    return (
        <div className="tabs__pane on visible">
            <form className="form settings-page__form">
                <div className="row settings-page__row">
                    <div className="col-lg-6 settings-page__col">
                        <div className="form__group">
                            <label
                                className="form__label form__label--mb-20 form__label--grey"
                            >
                                {t('settings:titles.email_notifications')}
                            </label>
                            <div className="checkbox checkbox--mb-15">
                                <input
                                    className="checkbox__input"
                                    id="checkbox-1"
                                    type="checkbox"
                                    name="checkbox"
                                    value="true"
                                    checked
                                    disabled
                                />
                                <label
                                    className="checkbox__label"
                                    htmlFor="checkbox-1"
                                >
                                    {t('settings:texts.notifications_first')}
                                </label>
                            </div>
                            <div className="checkbox checkbox--mb-15">
                                <input
                                    className="checkbox__input"
                                    id="checkbox-2"
                                    type="checkbox"
                                    name="checkbox"
                                    value="true"
                                    onChange={(event) => {
                                        notificationChangeHandler(
                                            event.target,
                                            NotificationTypes.TYPE_EMAIL_NEW_PAINTINGS
                                        )
                                    }}
                                    checked = {notifications.emailNewPaintings}
                                />
                                <label
                                    className="checkbox__label"
                                    htmlFor="checkbox-2"
                                >
                                    {t('settings:texts.notifications_second')}
                                </label>
                            </div>
                            <div className="checkbox checkbox--mb-15">
                                <input
                                    className="checkbox__input"
                                    id="checkbox-3"
                                    type="checkbox"
                                    name="checkbox"
                                    value="true"
                                    onChange={(event) => {
                                        notificationChangeHandler(
                                            event.target,
                                            NotificationTypes.TYPE_EMAIL_NEWS
                                        )
                                    }}
                                    checked = {notifications.emailNews}
                                />
                                <label
                                    className="checkbox__label"
                                    htmlFor="checkbox-3"
                                >
                                    {t('settings:texts.notifications_third')}
                                </label>
                            </div>
                        </div>
                    </div>
                    {/*<div className="col-lg-6 order-lg-2 settings-page__col">*/}
                    {/*    <div className="form__group">*/}
                    {/*        <label*/}
                    {/*            className="form__label form__label--mb-20 form__label--grey"*/}
                    {/*        >*/}
                    {/*            {t('settings:titles.sms_notifications')}*/}
                    {/*        </label>*/}
                    {/*        <div className="checkbox checkbox--mb-15">*/}
                    {/*            <input*/}
                    {/*                className="checkbox__input"*/}
                    {/*                id="checkbox-4"*/}
                    {/*                type="checkbox"*/}
                    {/*                name="checkbox"*/}
                    {/*                value="true"*/}
                    {/*                onChange={(event) => {*/}
                    {/*                    notificationChangeHandler(*/}
                    {/*                        event.target,*/}
                    {/*                        NotificationTypes.TYPE_PHONE_NEW_PAINTINGS*/}
                    {/*                    )*/}
                    {/*                }}*/}
                    {/*                checked = {notifications.phoneNewPaintings}*/}
                    {/*            />*/}
                    {/*            <label*/}
                    {/*                className="checkbox__label"*/}
                    {/*                htmlFor="checkbox-4"*/}
                    {/*            >*/}
                    {/*                {t('settings:texts.notifications_second')}*/}
                    {/*            </label>*/}
                    {/*        </div>*/}
                    {/*        <div className="checkbox checkbox--mb-15">*/}
                    {/*            <input*/}
                    {/*                className="checkbox__input"*/}
                    {/*                id="checkbox-5"*/}
                    {/*                type="checkbox"*/}
                    {/*                name="checkbox"*/}
                    {/*                value="true"*/}
                    {/*                onChange={(event) => {*/}
                    {/*                    notificationChangeHandler(*/}
                    {/*                        event.target,*/}
                    {/*                        NotificationTypes.TYPE_PHONE_NEWS*/}
                    {/*                    )*/}
                    {/*                }}*/}
                    {/*                checked = {notifications.phoneNews}*/}
                    {/*            />*/}
                    {/*            <label*/}
                    {/*                className="checkbox__label"*/}
                    {/*                htmlFor="checkbox-5"*/}
                    {/*            >*/}
                    {/*                {t('settings:texts.notifications_third')}*/}
                    {/*            </label>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = (state): StateProps => {
    return {
        account: state.authReducer.account,
    }
}

const mapDispatchToProps = (dispatch): DispatchProps => {
    return {
        reloadAccountData: () => dispatch(reloadAccountData())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
