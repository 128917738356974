import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import TwoFactorDeactivateSetupModal from '../../Modals/TwoFactorDeactivateSetupModal/TwoFactorDeactivateSetupModal'
import InfoSuccessIcon from '../../UI/Icons/InfoSuccessIcon/InfoIcon'

const TwoFactorEnabled: React.FC = () => {
    const {t} = useTranslation();
    const [modalVisible, setModalVisible] = useState<boolean>(false)

    const modalCloseHandler = () => {
        setModalVisible(false)
    }

    return (
        <>
            <div className="form__info">
                <InfoSuccessIcon/>
                <p>
                    {t('settings:texts.tfa_third')}
                </p>
                <p>
                    {t('settings:texts.tfa_forth')}
                </p>
                <div className="form__info-links">
                    <a
                        className="red-color call-popup"
                        href="#deactivate"
                        onClick={() => {
                            setModalVisible(true
                        )}}
                    >
                        {t('settings:buttons.deactivate')}
                    </a>
                </div>
            </div>
            <TwoFactorDeactivateSetupModal
                visible={modalVisible}
                close={modalCloseHandler}
            />
        </>
    )
}

export default TwoFactorEnabled
