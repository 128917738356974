import React from 'react'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import LockIcon from '../../../components/UI/Icons/LockIcon/LockIcon'
import Account from '../../../model/Account'
import PreLoader from '../../UI/PreLoader/PreLoader'
import TwoFactor from '../TwoFactor/TwoFactor'
import InfoErrorIcon from '../../UI/Icons/InfoErrorIcon/InfoErrorIcon'

interface StateProps {
    account: Account | null,
}

interface DispatchProps {
    destroyTwoFactorSecrets: () => void,
}

interface Props {
    showChangePassHandler: () => void
}

type IProps = Props & StateProps & DispatchProps

const SettingsPage: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation();
    const {account, showChangePassHandler} = props

    if (!account) {
        return <PreLoader/>
    }

    return (
        <div className="tabs__pane on visible">
            <form className="form settings-page__form">
                <div className="row settings-page__row">
                    {/*<div className="col-lg-6 settings-page__col">*/}
                    {/*    <div className="form__group">*/}
                    {/*        <label*/}
                    {/*            className="form__label form__label--mb-20 form__label--grey"*/}
                    {/*        >*/}
                    {/*            {t('settings:titles.account_activation')}*/}
                    {/*        </label>*/}
                    {/*        <div className="form__info">*/}
                    {/*            <InfoErrorIcon/>*/}
                    {/*            <p>*/}
                    {/*                {t('settings:texts.account_activation_first')}*/}
                    {/*            </p>*/}
                    {/*            <p>*/}
                    {/*                {t('settings:texts.account_activation_second')}*/}
                    {/*            </p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="form__group">*/}
                    {/*        <button*/}
                    {/*            className="button button--small button--bordered*/}
                    {/*                form__button form__button--auto"*/}
                    {/*            type="button"*/}
                    {/*        >*/}
                    {/*            {t('settings:buttons.verify_kyc')}*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="col-lg-6 settings-page__col">
                        <div className="form__group">
                            <label
                                className="form__label form__label--mb-20 form__label--grey"
                            >
                                {t('common:forms.users.password')}
                            </label>
                            <button
                                className="button button--bordered form__button form__button--auto call-popup"
                                type="button"
                                onClick={showChangePassHandler}
                            >
                                <LockIcon/>
                                {t('settings:buttons.change_password')}
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-6 settings-page__col">
                        <TwoFactor/>
                    </div>
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = (state): StateProps => {
    return {
        account: state.authReducer.account,

    }
}

export default connect(mapStateToProps)(SettingsPage)
