import {
    REMOVE_ACCOUNT_DONE,
    REMOVE_ACCOUNT_FAILED,
    REMOVE_ACCOUNT_SUCCESS
} from '../../../actions/Security/RemoveAccount/removeAccountTypes'

const initialState = {
    submitted: false,
    error: false
}

export default function removeAccountReducer(state = initialState, action) {
    switch (action.type) {
        case REMOVE_ACCOUNT_FAILED:
            return {
                ...state,
                submitted: true,
                error: true
            }
        case REMOVE_ACCOUNT_SUCCESS:
            return {
                ...state,
                submitted: true,
                error: false
            }
        case REMOVE_ACCOUNT_DONE:
            return {
                ...state,
                submitted: false,
                error: false
            }
        default:
            return state
    }
}
