import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import InfoWarningIcon from '../../../components/UI/Icons/InfoWarningIcon/InfoWarningIcon'
import TwoFactorSetupModal from '../../Modals/TwoFactorSetupModal/TwoFactorSetupModal'

const TwoFactorDisabled: React.FC = () => {
    const {t} = useTranslation();
    const [modalVisible, setModalVisible] = useState<boolean>(false)

    const modalCloseHandler = () => {
        setModalVisible(false)
    }

    return (
        <>
            <div className="form__info">
                <InfoWarningIcon/>
                <p>
                    {t('settings:texts.tfa_first')}
                </p>
                <p>
                    {t('settings:texts.tfa_second')}
                </p>
            </div>
            <div className="form__group">
                <button
                    className="button button--bordered form__button form__button--auto call-popup"
                    type="button"
                    onClick={() => {setModalVisible(true)}}
                >
                    {t('settings:buttons.protect_account')}
                </button>
            </div>
            <TwoFactorSetupModal
                visible={modalVisible}
                close={modalCloseHandler}
            />
        </>
    )
}

export default TwoFactorDisabled
