import axios from '../../../../axios/axios-backend'
import {API_RESET_PASSWORD_RESET} from '../../../../axios/paths'
import {AUTH_TOKEN_STORAGE_NAME, autoLogin} from '../Auth/auth'
import {
    RESET_PASSWORD_DONE,
    RESET_PASSWORD_FAILED,
    RESET_PASSWORD_SUCCESS
} from './actionTypes'
import {TokenType} from '../../../../model/Types'

export function resetPassword(
    password: string,
    repeatPassword: string,
    token: string,
    code: string | null = null
) {
    return async dispatch => {
        try {
            const response = await axios.post<TokenType>(API_RESET_PASSWORD_RESET, {
                password,
                repeat_password: repeatPassword,
                token,
                code
            })
            const authToken: TokenType = response.data
            localStorage.setItem(AUTH_TOKEN_STORAGE_NAME, authToken.token)
            await dispatch(resetPasswordSuccess())
            dispatch(autoLogin())
        } catch (e) {
            console.log(e)
            dispatch(resetPasswordFailed(e.response.data.errors || null))
        }
    }
}

const resetPasswordSuccess = () => {
    return {
        type: RESET_PASSWORD_SUCCESS
    }
}

const resetPasswordFailed = (errors) => {
    return {
        type: RESET_PASSWORD_FAILED,
        payload: errors
    }
}

export const resetPasswordDone = () => {
    return {
        type: RESET_PASSWORD_DONE
    }
}
