import React from 'react'

interface IProps {
    disabled?: boolean,
    children: ChildNode[],
    className: string
}

const SubmitButton: React.FC<IProps> = (props: IProps) => {
    return (
        <button
            type="submit"
            className={props.className}
            disabled={!!props.disabled}
        >
            {props.children}
        </button>
    )
}

export default SubmitButton
