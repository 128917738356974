import React, {useLayoutEffect} from 'react'
import {Link} from "react-router-dom";
import SupportIcon from '../Icons/SupportIcon/SupportIcon'
import {SUPPORT_CONTACT_US, SUPPORT_FAQ} from '../../../config/routing/paths'
import * as cs from 'classnames'
import {useTranslation} from 'react-i18next'

interface IProps {
    isColored: boolean
}

const SupportLink: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()

    const onClickHandler = () => {
        document.querySelector('.support-fixed__toggle')?.classList.toggle('is-active')
        document.querySelector('.support-fixed__dropdown')?.classList.toggle('is-visible')
    }

    useLayoutEffect(() => {
        const scrollHandler = () => {
            const supportFixedColored: Element | null = document.querySelector('.support-fixed--colored')
            const pageTopOffset = window.pageYOffset
            const startSectionHeight = document.querySelector('.start-section')?.clientHeight || 0
            const footerOffsetTop = (document.querySelector('.footer')?.getBoundingClientRect().top || 0) + pageTopOffset
            const supportOffsetTop = (supportFixedColored?.getBoundingClientRect().top || 0) + pageTopOffset

            if ((supportOffsetTop > startSectionHeight) && (supportOffsetTop < footerOffsetTop)) {
                supportFixedColored?.classList.add('is-black');
            } else {
                supportFixedColored?.classList.remove('is-black');
            }
        }

        window.addEventListener("scroll", scrollHandler);

        return () => {
            window.removeEventListener("scroll", scrollHandler);
        };
    }, [])

    return (
        <div className={cs('support-fixed', {'support-fixed--colored': props.isColored})}>
            <div className="support-fixed__toggle" onClick={onClickHandler}>
                <SupportIcon/>
            </div>
            <div className="support-fixed__dropdown">
                <ul className="support-fixed__list">
                    <li className="support-fixed__item">
                        <Link className="support-fixed__link" to={SUPPORT_FAQ}>
                            {t('common:menu.faq')}
                        </Link>
                    </li>
                    <li className="support-fixed__item">
                        <Link className="support-fixed__link" to={SUPPORT_CONTACT_US}>
                            {t('common:menu.contact_support')}
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default SupportLink
