export const MAIN_PATH = '/'

export const GALLERY_PATH = '/gallery'
export const PAINTING_PATH = '/gallery/:uuid'
export const PARTNERS_PATH = '/partners'
export const TOKENS_PATH = '/tokens'
export const OPERATIONS_PATH = '/operations'
export const OPERATION_PATH = '/operations/:uuid'
export const BUY_TOKENS_PATH = '/tokens/buy'

export const AUTH_PATH = '/beta'
export const AUTH_PATH_2 = '/auth'

export const RESET_PASSWORD_REQUEST_PATH = '/reset-password/request'
export const RESET_PASSWORD_REQUEST_DONE_PATH = '/reset-password/done'
export const RESET_PASSWORD_CONFIRM_PATH = '/reset-password/confirm'

export const REGISTRATION_START_PATH = '/registration/start'
export const REGISTRATION_PATH = '/registration'
export const REGISTRATION_DONE_PATH = '/registration/done'
export const REGISTRATION_CONFIRM_PATH = '/registration/confirm'

export const DASHBOARD_PATH = '/dashboard'
export const WALLET_PATH = '/wallet'

export const DEPOSIT_PATH = '/deposit'
export const WITHDRAW_PATH = '/withdraw'
export const SWAP_PATH = '/swap'

export const ACCOUNT_RECOVERY_REQUEST_PATH = '/account-recovery/request'
export const ACCOUNT_RECOVERY_DONE_PATH = '/account-recovery/done'
export const ACCOUNT_RECOVERY_CONFIRM_PATH = '/account-recovery/confirm'

export const SETTINGS_PATH = '/settings/:tab'

export const REMOVE_ACCOUNT_PATH = '/account/remove'
export const LOGOUT_PATH = '/logout'


export const STATIC_PRIVACY_POLICY = '/privacy-policy'
export const STATIC_TERMS_OF_USE = '/terms-of-use'
export const STATIC_COOKIES_POLICY = '/cookies-policy'

export const SUPPORT_CONTACT_US = '/support/contact-us'
export const SUPPORT_FAQ = '/support/faq'

export const DEFAULT_PROTECTED_PATH = GALLERY_PATH
export const DEFAULT_NOT_PROTECTED_PATH = AUTH_PATH

