import ArtTokenBalance, {ArtTokenBalanceDataObject} from './ArtTokenBalance'

export type AccountBalanceDataObject = {
    balance_amount: number,
    index_token_balance: string,
    art_token_balances: ArtTokenBalanceDataObject[],
}

class AccountBalance {
    private _balanceAmount: number
    private _indexTokenBalance: string
    private _artTokenBalances: ArtTokenBalance[]

    constructor(data: AccountBalanceDataObject) {
        this._balanceAmount = data.balance_amount
        this._indexTokenBalance = data.index_token_balance
        this._artTokenBalances = data.art_token_balances.map((artTokenBalance: ArtTokenBalanceDataObject) => {
            return new ArtTokenBalance(artTokenBalance)
        })
    }

    get balanceAmount(): number {
        return this._balanceAmount
    }

    get indexTokenBalance(): string {
        return this._indexTokenBalance
    }

    get artTokenBalances(): ArtTokenBalance[] {
        return this._artTokenBalances
    }

    getArtTokenById = (id: string): ArtTokenBalance => {
        const artTokenBalance = this._artTokenBalances.filter((artTokenBalance: ArtTokenBalance) => {
            return artTokenBalance.id === id
        })

        return artTokenBalance[0]
    }

    toDataObject = (): AccountBalanceDataObject => {
        return {
            balance_amount: this._balanceAmount,
            index_token_balance: this._indexTokenBalance,
            art_token_balances: this._artTokenBalances.map((artTokenBalance: ArtTokenBalance) => {
                return artTokenBalance.toDataObject()
            }),
        }
    }
}

export default AccountBalance
