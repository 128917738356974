type QuerySearchType = {
    [key: string]: string
}

const callbackUrl: (path: string, search?: QuerySearchType) => string = (path, search) => {
    let url = window.location.protocol + '//' + window.location.host + path
    if (search) {
        url = url + '?' + Object.keys(search).map((key) => key + '=' + search[key]).join('&')
    }

    return url
}

export default callbackUrl
