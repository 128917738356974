import axios from '../../../../axios/axios-backend'
import {API_REMOVE_ACCOUNT} from '../../../../axios/paths'
import {REMOVE_ACCOUNT_DONE, REMOVE_ACCOUNT_FAILED, REMOVE_ACCOUNT_SUCCESS} from './removeAccountTypes'
import {LANGUAGE_STORAGE_NAME, logout} from '../Auth/auth'

export function removeAccount(password: string) {
    return async dispatch => {
        try {

            const locale = localStorage.getItem(LANGUAGE_STORAGE_NAME)
            await axios.post(API_REMOVE_ACCOUNT + '?_locale=' + locale, {
                password
            })

            await dispatch(removeAccountSuccess())
            await dispatch(removeAccountDone())
            await dispatch(logout())
        } catch (e) {
            console.log(e)
            dispatch(removeAccountFailed())
        }
    }
}

export function removeAccountSuccess() {
    return {
        type: REMOVE_ACCOUNT_SUCCESS,
    }
}

export function removeAccountFailed() {
    return {
        type: REMOVE_ACCOUNT_FAILED,
    }
}

export function removeAccountDone() {
    return {
        type: REMOVE_ACCOUNT_DONE,
    }
}
