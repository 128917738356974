import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import PreLoader from '../../../../components/UI/PreLoader/PreLoader'
import SecurityLayout from '../../../../hoc/SecurityLayout/SecurityLayout'
import {checkConfirmationToken} from '../../../../store/actions/Security/Registration/registrationConfirm'

interface IProps {
    error: boolean,
    checkConfirmationToken: () => void,
}

const RegistrationConfirm: React.FC<IProps> = (props: IProps) => {
    useEffect(() => {
        props.checkConfirmationToken()
    }, [])

    const {t} = useTranslation()

    return (
        <SecurityLayout>
            <>
                <div className="login-page__body">
                    <h1 className="login-page__title login-page__title--sm-mb-20">
                        {t('security:titles.email_confirmation')}
                    </h1>
                    <div className="login-page__descr">
                        {
                            !props.error
                                ? <PreLoader/>
                                : Error
                        }
                    </div>
                </div>
                <div className="login-page__bottom"/>
            </>
        </SecurityLayout>
    )
}

function mapStateToProps(state) {
    return {
        error: state.registrationConfirmReducer.error
    }
}

function mapDispatchToProps(dispatch) {
    return {
        checkConfirmationToken: () => dispatch(checkConfirmationToken()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationConfirm)
