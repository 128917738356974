import React from 'react'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import 'moment/locale/ru'
import AccountBalance from '../../../model/AccountBalance'
import ArtTokenBalance from '../../../model/ArtTokenBalance'
import Tokens from '../../../model/Tokens'
import MobileWalletListItem from '../MobileWalletListItem/MobileWalletListItem'
import WalletListItem from '../WalletListItem/WalletListItem'
import TabletWalletListItem from '../TabletWalletListItem/TabletWalletListItem'
import {Link} from 'react-router-dom'
import {DEPOSIT_PATH, WITHDRAW_PATH} from '../../../config/routing/paths'
import SmallInfoIcon from '../../UI/Icons/SmallInfoIcon/SmallInfoIcon'
import {FeatureFlagListType} from '../../../model/Types'
import {FeatureFlagName} from '../../../model/Enums'

interface IProps {
    tokens: Tokens
    balance: AccountBalance,
    featureFlagsLoaded: boolean,
    featureFlags: FeatureFlagListType,
}

const WalletList: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()
    const {tokens, balance} = props

    const mappedArtTokens = tokens.mappedArtTokens()
    const indexToken = tokens.indexToken
    const indexTokenQuantity = parseFloat(balance.indexTokenBalance)

    return (
        <>
            <table className="table table--grey portfolio-page__table portfolio-page__table--mobile">
                <tbody>
                {
                    indexTokenQuantity > 0
                    ? <MobileWalletListItem
                        token={indexToken}
                        quantity={indexTokenQuantity}
                    />
                    : null
                }
                {
                    balance.artTokenBalances.map((artTokenBalance: ArtTokenBalance, i: number) => {
                        const artToken = mappedArtTokens[artTokenBalance.id]
                        const quantity = parseFloat(artTokenBalance.quantity)
                        return (
                            <MobileWalletListItem
                                key={`modile-protfolio-item-${i}`}
                                token={artToken}
                                quantity={quantity}
                            />
                        )
                    })
                }
                </tbody>
            </table>

            <table className="table table--grey portfolio-page__table portfolio-page__table--tablet">
                <thead>
                    <tr>
                        <th>{t('common:col_name')}</th>
                        <th>{t('common:col_your_balance')}</th>
                        <th>{t('common:col_amount_in')}</th>
                        <th>{t('common:col_available')}</th>
                        <th>{t('common:col_price')}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        indexTokenQuantity > 0
                            ? <TabletWalletListItem
                                token={indexToken}
                                quantity={indexTokenQuantity}
                            />
                            : null
                    }
                    {
                        balance.artTokenBalances.map((artTokenBalance: ArtTokenBalance, i: number) => {
                            const artToken = mappedArtTokens[artTokenBalance.id]
                            const quantity = parseFloat(artTokenBalance.quantity)
                            return (
                                <TabletWalletListItem
                                    key={`tablet-protfolio-item-${i}`}
                                    token={artToken}
                                    quantity={quantity}
                                />
                            )
                        })
                    }
                </tbody>
            </table>

            <table className="table table--grey portfolio-page__table portfolio-page__table--desktop">
                <thead>
                    <tr>
                        <th>{t('common:col_name')}</th>
                        <th>{t('common:col_your_balance')}</th>
                        <th>{t('common:col_amount_in')}</th>
                        <th>{t('common:col_available')}</th>
                        <th>{t('common:col_price')}</th>
                        <th>
                            <>
                                <div className="table__info-links">
                                    {
                                        props.featureFlagsLoaded
                                        && props.featureFlags[FeatureFlagName.DEPOSIT]
                                            ?
                                            <div className="info-link">
                                                <Link to={DEPOSIT_PATH} className="info-link__link">
                                                    {t('common:buttons.deposit')}
                                                </Link>
                                                <SmallInfoIcon/>
                                            </div>
                                            : null
                                    }
                                    {
                                        props.featureFlagsLoaded
                                        && props.featureFlags[FeatureFlagName.WITHDRAW]
                                            ?
                                            <div className="info-link">
                                                <Link to={WITHDRAW_PATH} className="info-link__link">
                                                    {t('common:buttons.withdraw')}
                                                </Link>
                                                <SmallInfoIcon/>
                                            </div>
                                            : null
                                    }
                                </div>
                            </>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        indexTokenQuantity > 0
                            ? <WalletListItem
                                token={indexToken}
                                quantity={indexTokenQuantity}
                            />
                            : null
                    }
                    {
                        balance.artTokenBalances.map((artTokenBalance: ArtTokenBalance, i: number) => {
                            const artToken = mappedArtTokens[artTokenBalance.id]
                            const quantity = parseFloat(artTokenBalance.quantity)
                            return (
                                <WalletListItem
                                    key={`protfolio-item-${i}`}
                                    token={artToken}
                                    quantity={quantity}
                                />
                            )
                        })
                    }
                </tbody>
            </table>
        </>
    )
}

function mapStateToProps(state) {
    return {
        featureFlagsLoaded: state.featureFlagReducer.featureFlagsLoaded,
        featureFlags: state.featureFlagReducer.featureFlags,
    }
}

export default connect(mapStateToProps)(WalletList)
