import i18n from 'i18next'
import axiosWrapper from '../../../../axios/axios-backend'
import {API_ACCOUNT_SETTINGS, API_GOOGLE_LOGIN, API_LOGIN, API_TWO_FA_VERIFY} from '../../../../axios/paths'
import Account, {AccountDataObject} from '../../../../model/Account'
import AuthToken from '../../../../model/AuthToken'
import ApiClient from '../../../../services/ApiClient'
import IAuhAction from '../../../reducers/Security/Auth/IAuhAction'
import {DESTROY_DATA} from '../../actionTypes'
import {ACCOUNT_LOADED, ACCOUNT_DESTROY, AUTH_LOGOUT, AUTH_SUCCESS, TWO_FA_REQUIRED, TWO_FA_ACCEPTED} from './authTypes'
import {REFERRAL_STORAGE_NAME} from '../Registration/referral'
import {ErrorsType, TokenType} from '../../../../model/Types'

export const AUTH_TOKEN_STORAGE_NAME = 'authToken'
export const ACCOUNT_STORAGE_NAME = 'account'
export const LANGUAGE_STORAGE_NAME = 'lang'

export const auth = (
    email: string,
    password: string,
    onSuccess: () => void,
    onError: (data: ErrorsType) => void
) => {
    return async dispatch => {
        const apiClient = ApiClient((error) => {
            onError(error.response.data as ErrorsType)

            return false
        })

        const response = await apiClient.post<TokenType>(API_LOGIN, {
            email,
            password
        })
        const authToken: TokenType = response.data

        localStorage.setItem(AUTH_TOKEN_STORAGE_NAME, authToken.token)
        await dispatch(autoLogin())
        onSuccess()
    }
}

export const googleAuth = (code: string) => {
    return async dispatch => {
        const apiClient = ApiClient((error) => {
            //dispatch(authFailed(error))

            return false
        })

        const jsonReferral = localStorage.getItem(REFERRAL_STORAGE_NAME)
        const referral: {email: string, token: string} | null = jsonReferral ? JSON.parse(jsonReferral) : null
        const params = {
            code,
            referral: referral ? referral.token : null,
        }

        const response = await apiClient.get<TokenType>(API_GOOGLE_LOGIN, {params})
        const authToken: TokenType = response.data
        localStorage.setItem(AUTH_TOKEN_STORAGE_NAME, authToken.token)
        dispatch(autoLogin())
    }
}

export const verifyTwoFactorCode = (
    code: string,
    onSuccess: () => void,
    onError: (data: ErrorsType) => void
) => {
    return async dispatch => {
        const apiClient = ApiClient((error) => {
            onError(error.response.data as ErrorsType)

            return false
        })

        const response = await apiClient.post<TokenType>(API_TWO_FA_VERIFY, {code})
        const authToken: TokenType = response.data

        localStorage.setItem(AUTH_TOKEN_STORAGE_NAME, authToken.token)
        await dispatch(autoLogin())
        await dispatch(acceptTwoFactorCode())
        onSuccess()
    }
}

export const logout: any = () => {
    return async dispatch => {
        localStorage.removeItem(AUTH_TOKEN_STORAGE_NAME)
        localStorage.removeItem(ACCOUNT_STORAGE_NAME)
        localStorage.removeItem('user')
        delete axiosWrapper.defaults.headers.common['Authorization']

        await dispatch(doLogout())
        dispatch(doDestroyData())
    }
}

export const autoLogin = () => {
    return async dispatch => {
        const token = localStorage.getItem(AUTH_TOKEN_STORAGE_NAME) || null
        if (!token) {
            dispatch(logout())

            return
        }

        const authToken = new AuthToken(token)
        if (authToken.isExpired()) {
            dispatch(logout())

            return
        }

        await dispatch(authSuccess(authToken))
        await dispatch(autoLogout(authToken.getExpiresIn()))

        if (authToken.isTwoFaRequired) {
            dispatch(twoFaRequired())

            return
        }

        dispatch(loadAccountData())
    }
}

export const autoLogout = (time: number) => {
    return async dispatch => {
        setTimeout(() => { dispatch(logout()) }, time)
    }
}

export const authSuccess = (authToken: AuthToken) => {
    axiosWrapper.defaults.headers.common['Authorization'] = `Bearer ${authToken.token}`;

    return {
        type: AUTH_SUCCESS,
        authToken: authToken,
    }
}

export const twoFaRequired = () => {
    return {
        type: TWO_FA_REQUIRED,
    }
}

export const loadAccountData = () => {
    return async dispatch => {
        const accountData = localStorage.getItem(ACCOUNT_STORAGE_NAME)
        if (accountData) {
            const account = new Account(JSON.parse(accountData) as AccountDataObject)
            dispatch(accountDataLoaded(account))

            return
        }

        const apiClient = ApiClient((error) => {
            //dispatch(authFailed(error.response.data as ErrorsType))

            return false
        })

        const response = await apiClient.get<AccountDataObject>(API_ACCOUNT_SETTINGS)
        const account = new Account(response.data)

        localStorage.setItem(ACCOUNT_STORAGE_NAME, JSON.stringify(account.toDataObject()))
        dispatch(accountDataLoaded(account))
    }
}

export const reloadAccountData = () => {
    return async dispatch => {
        await dispatch(accountDestroy())
        dispatch(loadAccountData())
    }
}

export const accountDataLoaded = (account: Account): IAuhAction => {
    i18n.changeLanguage(account.language)
    localStorage.setItem(LANGUAGE_STORAGE_NAME, account.language)

    return {
        type: ACCOUNT_LOADED,
        account: account,
    }
}

export const accountDestroy = (): IAuhAction => {
    localStorage.removeItem(ACCOUNT_STORAGE_NAME)

    return {
        type: ACCOUNT_DESTROY,
    }
}

const doDestroyData = (): IAuhAction => {
    return {
        type: DESTROY_DATA
    }
}

const doLogout = (): IAuhAction => {
    return {
        type: AUTH_LOGOUT
    }
}

const acceptTwoFactorCode = (): IAuhAction => {
    return {
        type: TWO_FA_ACCEPTED
    }
}
