import {
    CHANGE_PASSWORD_DONE,
    CHANGE_PASSWORD_FAILED,
    CHANGE_PASSWORD_SUCCESS,
} from '../../../actions/Security/ChangePassword/actionTypes'

export type ChangePasswordState = {
    submitted: boolean,
    error: boolean,
    errors: any[],
}

const initialState = {
    submitted: false,
    error: false,
    errors: [],
}

const changePasswordReducer = (
    state: ChangePasswordState = initialState,
    action: {type: string, payload?: any}
): ChangePasswordState => {
    switch (action.type) {
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                submitted: true,
                error: false,
                errors: [],
            }
        case CHANGE_PASSWORD_FAILED:
            return {
                ...state,
                submitted: true,
                error: true,
                errors: action.payload || [],
            }
        case CHANGE_PASSWORD_DONE:
            return {
                ...state,
                submitted: false,
                error: false,
                errors: [],
            }
        default:
            return state
    }
}

export default changePasswordReducer
