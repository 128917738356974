import React from 'react'
import 'react-fancybox/lib/fancybox.css'
import {KeyValueItemType} from '../../../model/Types'

interface IProps {
    items: KeyValueItemType[],
}

const ParamsTable: React.FC<IProps> = (props: IProps) => {

    if (!props.items.length) {
        return null
    }

    return (
        <table className="picture-card__table">
            <tbody>
                {
                    props.items.map((item: KeyValueItemType, i: number) => {
                        return item.value ? (
                            <tr key={`picture-card-param-${i}`}>
                                <td>{item.label}</td>
                                <td>{item.value}</td>
                            </tr>
                        ) : null
                    })
                }
            </tbody>
        </table>
    )
}

export default ParamsTable
