import IAuthor from './Interfaces/IAuthor'
import ArtToken, {ArtTokenDataObject} from './ArtToken'
import IPaintingParams from './Interfaces/IPaintingParams'
import IPaintingImage from './Interfaces/IPaintingImage'
import PaintingImage, {PaintingImageDataObject} from './PaintingImage'
import {IPainting} from './Interfaces'
import {PaintingOrientation} from './Enums'

export type PaintingDataObject = {
    id: string,
    name: string,
    sale_id: string | null,
    attr_description: string,
    short_description: string,
    full_description: string,
    author: IAuthor | null,
    year: number | null,
    ordering: number,
    art_token: ArtTokenDataObject | null,
    parameters: IPaintingParams,
    main_image: PaintingImageDataObject | null,
    images: PaintingImageDataObject[],
    painting_subscriptions: string[],
    attributions: string[],
    histories: string[],
    orientation: PaintingOrientation,
}

class Painting implements IPainting {
    private _id: string
    private _name: string
    private _saleId: string | null
    private _attrDescription: string
    private _shortDescription: string
    private _fullDescription: string
    private _author: IAuthor | null
    private _year: number | null
    private _ordering: number
    private _artToken: ArtToken | null
    private _parameters: IPaintingParams
    private _mainImage: IPaintingImage | null
    private _images: IPaintingImage[]
    private _paintingSubscriptions: string[]
    private _attributions: string[]
    private _histories: string[]
    private _orientation: PaintingOrientation

    constructor(data: PaintingDataObject) {
        this._id = data.id
        this._name = data.name
        this._saleId = data.sale_id
        this._attrDescription = data.attr_description
        this._shortDescription = data.short_description
        this._fullDescription = data.full_description
        this._author = data.author
        this._year = data.year
        this._ordering = data.ordering
        this._artToken = data.art_token ? new ArtToken(data.art_token) : null
        this._parameters = data.parameters
        this._mainImage = data.main_image ? new PaintingImage(data.main_image) : null
        this._images = data.images.map((image: PaintingImageDataObject): PaintingImage => new PaintingImage(image))
        this._paintingSubscriptions = data.painting_subscriptions
        this._attributions = data.attributions
        this._histories = data.histories
        this._orientation = data.orientation
    }

    get id(): string {
        return this._id
    }

    get name(): string {
        return this._name
    }

    get saleId(): string | null {
        return this._saleId
    }

    get attrDescription(): string {
        return this._attrDescription
    }

    get shortDescription(): string {
        return this._shortDescription
    }

    get fullDescription(): string {
        return this._fullDescription
    }

    get author(): IAuthor | null {
        return this._author
    }

    get year(): number | null {
        return this._year
    }

    get ordering(): number {
        return this._ordering
    }

    get artToken(): ArtToken | null {
        return this._artToken
    }

    get parameters(): IPaintingParams {
        return this._parameters
    }

    get mainImage(): IPaintingImage | null {
        return this._mainImage
    }

    get images(): IPaintingImage[] {
        return this._images
    }

    get paintingSubscriptions(): string[] {
        return this._paintingSubscriptions
    }

    get attributions(): string[] {
        return this._attributions
    }

    get histories(): string[] {
        return this._histories
    }

    get orientation(): PaintingOrientation {
        return this._orientation
    }

    nonMainImages(): IPaintingImage[] {
        return this._images
            .filter((image: IPaintingImage): boolean => !this._mainImage || image.id !== this.mainImage?.id)
    }

    allImages(): IPaintingImage[] {
        const images: IPaintingImage[] = this.nonMainImages()
        if (this.mainImage) {
            images.unshift(this.mainImage)
        }

        return images
    }

    isVertical(): boolean {
        return this.orientation === PaintingOrientation.PORTRAIT
    }
}

export default Painting
