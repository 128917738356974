import {API_AUTHORS, API_GALLERY} from '../../axios/paths'
import ApiClient from '../../services/ApiClient'
import {AUTHORS_LOADED, FILTER_CHANGED, PAINTINGS_LOADED} from './galleryTypes'
import {IAuthor, IFilterState, IPainting} from '../../model/Interfaces'
import {AuthorListType} from '../../model/Types'
import {AxiosResponse} from 'axios'
import Painting, {PaintingDataObject} from '../../model/Painting'

export const loadAuthors = () => {
    return async (dispatch): Promise<void> => {
        const apiClient = ApiClient()

        const response = await apiClient.get(API_AUTHORS)
        const authorsList = {}
        const jsonData = response.data
        jsonData.map((author: IAuthor) => {
            authorsList[author.id] = author
        })

        dispatch(authorsLoaded(authorsList))
    }
}

export const loadPaintings = () => {
    return async (dispatch): Promise<void> => {
        const apiClient = ApiClient()

        const response: AxiosResponse<PaintingDataObject[]> = await apiClient.get(API_GALLERY)
        const paintingsList: IPainting[] = response.data.map((paintingData: PaintingDataObject)  => {
            const painting: IPainting = new Painting(paintingData)

            return painting
        })

        dispatch(paintingsLoaded(paintingsList))
    }
}

export const authorsLoaded = (data: AuthorListType) => {
    return {
        type: AUTHORS_LOADED,
        payload: data
    }
}

export const paintingsLoaded = (data: IPainting[]) => {
    return {
        type: PAINTINGS_LOADED,
        payload: data
    }
}

export const updateFilter = (state: IFilterState) => {
    return {
        type: FILTER_CHANGED,
        payload: state
    }
}

export const clearFilter = () => {
    return {
        type: FILTER_CHANGED
    }
}
