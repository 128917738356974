import {Dispatch} from 'redux'
import {API_REGISTER} from '../../../../axios/paths'
import callbackUrl from '../../../../config/routing/callbackUrl'
import {REGISTRATION_CONFIRM_PATH, REGISTRATION_DONE_PATH} from '../../../../config/routing/paths'
import ApiClient from '../../../../services/ApiClient'
import history from '../../../../utils/history'
import {REGISTRATION_DONE, REGISTRATION_FAILED, REGISTRATION_SUCCESS} from './actionTypes'
import {REFERRAL_STORAGE_NAME} from './referral'
import {LANGUAGE_STORAGE_NAME} from '../Auth/auth'

export type RegisterUserResponse = {
    resend_available_at: number,
    resend_token: string
}

export const REGISTRATION_RESEND_NAME = 'regResend'

export const registerUser = (
    email: string,
    username: string,
    phone: string,
    password: string,
    repeatPassword: string,
    subscribe: boolean,
    setError: any
) => (dispatch: Dispatch): void => {
    const jsonReferral = localStorage.getItem(REFERRAL_STORAGE_NAME)
    const referral: {email: string, token: string} | null = jsonReferral ? JSON.parse(jsonReferral) : null

    phone = phone.replace('+', '')
    const apiClient = ApiClient((error) => {
        Object.keys(error.response.data).map(field => {
            setError(field, {message: error.response.data[field]})
        })

        dispatch(registrationFailed(error.response.data))

        return false
    })
    const locale = localStorage.getItem(LANGUAGE_STORAGE_NAME)
    apiClient.post(API_REGISTER + '?_locale=' + locale, {
        email,
        username,
        phone,
        password,
        repeat_password: repeatPassword,
        subscribe,
        referral: referral ? referral.token : null,
        callback_url: callbackUrl(REGISTRATION_CONFIRM_PATH, {
            token: '%s'
        })
    })
    .then(response => {
        const {data}: {data: RegisterUserResponse} = response
        localStorage.setItem(REGISTRATION_RESEND_NAME, JSON.stringify(data))
        dispatch(registrationSuccess())
    })
    .catch(error => {
        console.log(error)
    })
}

const registrationSuccess = () => {
    return {
        type: REGISTRATION_SUCCESS
    }
}

const registrationFailed = (data) => {
    return {
        type: REGISTRATION_FAILED,
        errors: data
    }
}

export const registrationDone = () => {
    history.push(REGISTRATION_DONE_PATH)

    return {
        type: REGISTRATION_DONE
    }
}
