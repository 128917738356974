import React from 'react'
import sprite from '../../../../styles/img/sprite.svg'

const SlidePrevIcon: React.FC = () => (
    <svg className="picture-arrow__icon" width="6" height="10">
        <use xlinkHref={`${sprite}#slider-prev`}/>
    </svg>
)

export default SlidePrevIcon
