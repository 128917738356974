import {DESTROY_DATA, RENEW_DATA} from '../actions/actionTypes'
import {OPERATIONS_LOADED} from '../actions/operationTypes'
import {IOperation} from '../../model/Interfaces'

interface IOverlayState {
    operationsLoaded: boolean,
    operations: IOperation[],
    needRenew: boolean,
}

const initialState: IOverlayState = {
    operationsLoaded: false,
    operations: [],
    needRenew: false,
}

const operationReducer = (
    state: IOverlayState = initialState,
    action: {type: string, payload?: IOperation[]}
): IOverlayState => {
    switch (action.type) {
        case OPERATIONS_LOADED:
            return {
                ...state,
                operationsLoaded: true,
                operations: action.payload || [],
                needRenew: false,
            }
        case RENEW_DATA:
            return {
                ...state,
                needRenew: true,
            }
        case DESTROY_DATA:
            return {
                ...initialState,
            }
        default:
            return state
    }
}

export default operationReducer
